<template>
  <div class="p-2">
    <a-row justify="center">
      <a-col :xs="{span: 24}" :lg="{span: 12}" v-if="postLoading">
        <a-skeleton avatar active :paragraph="{ rows: 4 }" class="border-bottom pb-3 pb-3 mb-3 mt-3" />
        <a-skeleton avatar active :paragraph="{ rows: 4 }" />
      </a-col>
      <a-col :xs="{span: 24}" :lg="{span: 12}" class="pt-4" v-else>
        <div v-if="post.post">
          <PostComponent :post="post.post" />
          <CommentsListView :post_id="post.post.id"/>
        </div>
        <div v-else>
          <NotFoundComponent />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {useQuery} from "@vue/apollo-composable";

import {ViewPostGql} from "@/gql/view-post-gql";
import PostComponent from "@/components/PostComponent.vue";
import CommentsListView from "@/views/CommentsListView.vue";
import NotFoundComponent from "@/components/NotFoundComponent.vue";

export default {
  name: "view-post-page",
  components: {NotFoundComponent, CommentsListView, PostComponent},
  setup() {
    const route = useRoute();
    const {result: post, loading: postLoading} = useQuery(ViewPostGql, {
      id: parseInt(route.params.id)
    });

    return {
      post,
      postLoading
    }
  }
}
</script>

<style scoped>

</style>