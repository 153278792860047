<template>
<div>
  <a-form
      :model="profile"
      name="basic"
      autocomplete="off"
      @finish="onFinish"
      layout="vertical"
  >
    <a-form-item
        :label="$t('fullName')"
        name="name"
        :rules="[{ required: true }, { whitespace: true }]"
    >
      <a-input v-model:value="profile.name" />
    </a-form-item>

    <a-form-item
        :label="$t('email')"
        name="email"
        :rules="[{ required: true }, { whitespace: true }]"
    >
      <a-input v-model:value="profile.email" />
    </a-form-item>

    <a-form-item
        :label="$t('password')"
        name="password"
        :rules="[{ required: true, message: 'Please input your password!' }, { whitespace: true }]"
    >
      <a-input-password v-model:value="profile.password" />
    </a-form-item>

    <a-form-item
        :label="$t('confirmPassword')"
        name="confirmation_password"
        :rules="[{ required: true, validator: validatePassConfirmation, trigger: 'change', }, { whitespace: true }]"
    >
      <a-input-password v-model:value="profile.confirmation_password" />
    </a-form-item>

<!--    <a-form-item-->
<!--        label="Registration Number"-->
<!--        name="registration_number"-->
<!--        :rules="[{ required: true, message: 'Please input your registration number!' }]"-->
<!--    >-->
<!--      <a-input v-model:value="profile.registration_number" />-->
<!--    </a-form-item>-->

<!--    <a-form-item-->
<!--        label="Specialties"-->
<!--        name="specialties"-->
<!--        :rules="[{ required: true, message: 'Please input your specialties!' }]"-->
<!--    >-->
<!--      <a-select-->
<!--          show-search-->
<!--          v-model:value="profile.specialties"-->
<!--          :options="store.state.specialties.map(item => {return {value: item.id, label: item.name}})"-->
<!--          placeholder="Please select your specialties"-->
<!--          mode="multiple"-->
<!--          :filter-option="filterOption"-->
<!--      ></a-select>-->
<!--    </a-form-item>-->

    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="loading" block>{{ $t('next') }} (1 of 2)</a-button>
    </a-form-item>
  </a-form>
</div>
</template>

<script>
import {ref} from "vue";
import {useStore} from "vuex";
import {useMutation} from "@vue/apollo-composable";
import {SignupGql} from "@/gql/signup-user-gql";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

export default {
  name: "SignupMedicalView",
  setup() {
    const router = useRouter();
    const store = useStore();
    const {t} = useI18n()
    const profile = ref({
      name: '',
      email: '',
      password: '',
      confirmation_password: '',
      // registration_number: '',
      // specialties: [],
    });

    const {mutate:createUserMutate, loading} = useMutation(SignupGql, {
      clientId: 'auth'
    });

    const validatePassConfirmation = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Please input your confirmation password!');
      } else if (value !== profile.value.password) {
        return Promise.reject("The confirmation is not matched with password!");
      } else {
        return Promise.resolve();
      }
    };

    const filterOption = (input, option) => {
      // console.log(input, option)
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    const onFinish = () => {
      createUserMutate({
        name: profile.value.name,
        email: profile.value.email,
        password: profile.value.password,
        profile_type: 'medical_center',
        registration_number: profile.value.registration_number
      }).then((res) => {
        localStorage.setItem('authed_user', JSON.stringify(res.data.signup))
        store.commit('setAuthedUser', res.data.signup)
        router.push({
          name: 'signup_medical_center_complete_profile'
        })
      })
    }
    return {
      profile,
      onFinish,
      store,
      filterOption,
      validatePassConfirmation,
      loading,
      t
    }
  }
}
</script>

<style scoped>

</style>