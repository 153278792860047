import gql from "graphql-tag";

const GetUserSubscriptionsGql = gql`
    query {
      user_subscriptions{
        id
        order_reference_id
        type
        status
        expire_at
        start_at
        is_recurring
      }
    }
`

export default GetUserSubscriptionsGql;
