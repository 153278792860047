<template>
  <a-modal
      v-model:visible="visible"
      :title="$t('postNewJob')"
      @ok="submitForm"
      :confirm-loading="confirmLoading"
      :okText="$t('save')"
      :afterClose="resetData"
      v-model:model="job"
  >
    <a-form :layout="'vertical'" :model="job" @finish="saveData">
      <a-form-item :label="$t('jobTitle')" name="title" :required="true" :rules="{ max: 255, whitespace: true }">
        <a-input v-model:value="job.title" />
      </a-form-item>
      <a-form-item :label="$t('jobOverview')" name="overview" :required="true" :rules="{ max: 255,  whitespace: true }">
<!--        <a-input  placeholder="Enter job overview" />-->
        <a-textarea
            v-model:value="job.overview"
            :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </a-form-item>
      <a-form-item :label="$t('jobLocation')" name="location" :required="true" :rules="{ whitespace: true }">
        <a-select
            v-model:value="job.location"
            style="width: 100%"
            show-search
            :options="store.state.countries.map(x => {
                    return {
                      label: x.name,
                      value: x.name
                    }
                  })"
        ></a-select>
      </a-form-item>
      <a-form-item  name="specialty" :required="!job.specialty_id" :validateOnRuleChange="true" :validateTrigger="false" :hideRequiredMark="false">
        <template #label>
          <span v-if="job.specialty_id" style="display: inline-block; margin-right: 4px; color: #ff4d4f; font-size: 12px; line-height: 1; ">*</span> {{ $t('speciality') }}
        </template>
        <a-select
            v-model:value="job.specialty_id"
            style="width: 100%"
            show-search
            :options="store.state.specialties.map(x => {
              return {
                label: x.name,
                value: x.id
              }
            })"
            :filterOption="(inputValue, option) => {
              // console.log(inputValue,option)
              return (option.label.toLowerCase().includes(inputValue.toLowerCase()) ? option : false)
            }"
        ></a-select>
      </a-form-item>
<!--      <a-form-item :label="$t('speciality')" name="specialty" :rules="{required: true}">-->
<!--        <a-select-->
<!--          ref="select"-->
<!--          v-model:value="job.specialty_id"-->
<!--          :options="store.state.specialties.map(x => {-->
<!--            return {-->
<!--              label: x.name,-->
<!--              value: x.id-->
<!--            }-->
<!--          })"-->
<!--        >-->
<!--&lt;!&ndash;          <a-select-option v-for="(item, index) in store.state.specialties" :key="index" :value="item.id">{{ item.name }}</a-select-option>&ndash;&gt;-->
<!--        </a-select>-->
<!--      </a-form-item>-->
      <a-row :justify="'space-between'" align="center" v-if="job.id">
        <a-col>
          {{$t('activity')}}
        </a-col>
        <a-col>
          <a-switch v-model:checked="job.is_active" :size="'small'" />
        </a-col>
      </a-row>
<!--      <a-form-item label="Skills" name="skills" :required="job.specialty_id == ''">-->
<!--        <tags-input v-model="job.skills"></tags-input>-->
<!--      </a-form-item>-->
      <a-button type="primary" html-type="submit" class="d-none" id="submit-button" :loading="updateJobMutationLoading || saveJobMutationLoading" />
    </a-form>
  </a-modal>
</template>

<script>
import {inject, ref} from "vue";
import {useStore} from "vuex";
import {useMutation} from "@vue/apollo-composable";
import {CreateJobMutationGql} from "@/gql/create-job-mutation-gql";
import {message} from "ant-design-vue";
import {UpdateJobMutationGql} from "@/gql/update-job-mutation-gql";
import {useI18n} from "vue-i18n";

export default {
  name: "JobModalComponent",
  setup() {
    let visible = ref(false)
    let confirmLoading = ref(false)
    let store = useStore()
    let job = ref({
      id: '',
      title: '',
      overview: '',
      specialty_id: '',
      skills: ''
    })
    const emitter = inject('emitter')
    const submitForm = () => {
      document.getElementById('submit-button').click();
    }
    const resetData = () => {
      job.value = {
        id: '',
        title: '',
        overview: '',
        specialty_id: '',
        skills: ''
      }
    }
    const {t} = useI18n()
    const saveData = () => {
      confirmLoading.value = true;
      if (job.value.id) {
        updateJobMutationMutate({
          id: job.value.id,
          title: job.value.title,
          overview: job.value.overview,
          location: job.value.location,
          specialty_id: job.value.specialty_id,
          is_active: job.value.is_active
        }).then(() => {
          confirmLoading.value = false;
          visible.value = false
          message.success(t('jobUpdated'))
          refetchJobList()
        }).catch(err => {
          console.log(err)
        })
      } else {
        saveJobMutationMutate({
          title: job.value.title,
          overview: job.value.overview,
          location: job.value.location,
          specialty_id: job.value.specialty_id,
        }).then(() => {
          confirmLoading.value = false;
          visible.value = false
          message.success(t('jobAdded'))
          refetchJobList()
        }).catch(err => {
          console.log(err)
        })
      }
    }
    const refetchJobList = () => {
      emitter.emit('refetchJobsList');
      emitter.emit('closeJobDrawer');
    }
    emitter.on('openJobModal', (data) => {
      visible.value = true;
      if (data) {
        job.value.id = data.id
        job.value.title = data.title
        job.value.overview = data.overview
        job.value.location = data.location
        job.value.specialty_id = data.specialty.id
        job.value.is_active = data.is_active
      }
    })

    //Save Job mutation
    const {mutate: saveJobMutationMutate, loading:saveJobMutationLoading} = useMutation(CreateJobMutationGql)
    const {mutate: updateJobMutationMutate, loading:updateJobMutationLoading} = useMutation(UpdateJobMutationGql)

    let specialties = ref(store.state.specialties)
    return {
      specialties,
      visible,
      emitter,
      saveData,
      job,
      submitForm,
      confirmLoading,
      store,
      saveJobMutationMutate,
      saveJobMutationLoading,
      updateJobMutationLoading,
      updateJobMutationMutate,
      resetData,
      refetchJobList
    }
  }
}
</script>

<style scoped>

</style>
