<template>
  <div class="container">
    <a-row>
      <a-col>
        <a class="navbar-brand text-primary fw-bolder" href="#">
<!--          LOGO-->
          <img src="@/assets/images/dr.png" width="90" alt="DoctorsGulf">
        </a>
      </a-col>
      <a-col flex="auto" v-if="store.state.authedUser.user.profile.profile_type == 'user'">
        <a-menu mode="horizontal" v-model:selectedKeys="current" @click="changePage" class="w-100">
          <a-menu-item key="find-work">
            {{ $t('findWork') }}
          </a-menu-item>
          <a-menu-item key="feeds">
            {{ $t('feeds') }}
          </a-menu-item>
          <a-menu-item key="job-search">
            {{ $t('search') }}
          </a-menu-item>
        </a-menu>
      </a-col>
      <a-col flex="auto">
        <a-row justify="end" align="middle" :gutter="10">
<!--          <a-col span="9">-->
<!--            <a-input-group compact class="w-100">-->
<!--              <a-input v-model:value="searchValue" placeholder="Search" class="w-auto">-->
<!--                <template #prefix>-->
<!--                  <img src="@/assets/icons/search_black.svg" width="20" alt="Search">-->
<!--                </template>-->
<!--              </a-input>-->
<!--              <a-select v-model:value="searchType" class="w-25">-->
<!--                <a-select-option value="jobs">Jobs</a-select-option>-->
<!--                <a-select-option value="profiles">Profiles</a-select-option>-->
<!--              </a-select>-->
<!--            </a-input-group>-->
<!--          </a-col>-->
          <a-col>
            <a-button type="text" @click="$i18n.locale = 'ar'" v-if="$i18n.locale == 'en'">
              <template #icon>
                <GlobalOutlined />
              </template>
              العربية
            </a-button>
            <a-button type="text" @click="$i18n.locale = 'en'" v-else>
              <template #icon>
                <GlobalOutlined />
              </template>
              English
            </a-button>
          </a-col>
          <a-col>
            <a-button type="text" @click="openNotificationDrawer()">
              <template #icon>
                <BellOutlined />
              </template>
            </a-button>
          </a-col>
          <a-col>
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <!--            <a class="ant-dropdown-link" @click.prevent>-->
              <!--              Click me-->
              <!--            </a>-->
<!--              <a href="#" data-bs-toggle="dropdown" aria-expanded="false" @click.prevent>-->
<!--                <img src="@/assets/icons/avatar.svg" width="28" alt="user-avatar">-->
<!--              </a>-->
              <a-avatar shape="circle" size="small" data-bs-toggle="dropdown" aria-expanded="false" class="cursor-pointer" @click.prevent v-if="!store.state.authedUser?.user?.profile.image">
                <template #icon><UserOutlined /></template>
              </a-avatar>
              <a-avatar shape="circle" size="small" data-bs-toggle="dropdown" aria-expanded="false" class="cursor-pointer" @click.prevent v-else :src="store.state.authedUser?.user?.profile.image" />
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0">
                    <router-link class="text-decoration-none" :to="{name: 'profile-page', params: {
                    slug: store.state.authedUser.user.profile.profile_slug
                  }}">
                      <div class="d-flex align-items-center">
                        <UserOutlined class="me-2" />
                        {{$t("profile")}}

                      </div>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <router-link class="text-decoration-none" :to="{name: 'password-and-security'}">
                      <div class="d-flex align-items-center">
                        <SettingOutlined class="me-2" />
                        {{$t("setting")}}
                      </div>
                    </router-link>
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item key="3" @click="logout">
                    <LogoutOutlined class="me-2" />
                    {{$t("logout")}}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-col>
        </a-row>

<!--        <a-space direction="horizontal">-->
<!--&lt;!&ndash;          <div class="input-group input-group-sm rounded-3">&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="input-group-text bg-transparent">&ndash;&gt;-->
<!--&lt;!&ndash;              <img src="@/assets/icons/search_black.svg" width="20" alt="Search">&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <input type="text" class="form-control border-start-0 border-end-0" placeholder="Search" aria-label="Search">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="btn-group border border-start-0">&ndash;&gt;-->
<!--&lt;!&ndash;              <button type="button" class="btn btn-text btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">&ndash;&gt;-->
<!--&lt;!&ndash;                Jobs&ndash;&gt;-->
<!--&lt;!&ndash;              </button>&ndash;&gt;-->
<!--&lt;!&ndash;              <ul class="dropdown-menu">&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a class="dropdown-item" href="#">Job</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a class="dropdown-item" href="#">Person</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a class="dropdown-item" href="#">Medical Center</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              </ul>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div>-->
<!--            <a-button type="text" @click="openNotificationDrawer()">-->
<!--              <template #icon>-->
<!--                <BellOutlined />-->
<!--              </template>-->
<!--            </a-button>-->
<!--&lt;!&ndash;            <img src="@/assets/icons/notification.svg" width="20" alt="notification">&ndash;&gt;-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="dropdown-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="" class="me-2" data-bs-toggle="dropdown" aria-expanded="false">&ndash;&gt;-->
<!--&lt;!&ndash;              <img src="@/assets/icons/avatar.svg" width="30" alt="user-avatar">&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;            <ul class="dropdown-menu">&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a class="dropdown-item" href="#"> Profile</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a class="dropdown-item" href="#">Setting</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="text-muted"><hr class="dropdown-divider w-100 bg-light"></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a class="dropdown-item" href="#">Logout</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;            </ul>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <a-dropdown :trigger="['click']" placement="bottomRight">-->
<!--&lt;!&ndash;            <a class="ant-dropdown-link" @click.prevent>&ndash;&gt;-->
<!--&lt;!&ndash;              Click me&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--            <a href="#" data-bs-toggle="dropdown" aria-expanded="false" @click.prevent>-->
<!--              <img src="@/assets/icons/avatar.svg" width="28" alt="user-avatar">-->
<!--            </a>-->
<!--            <template #overlay>-->
<!--              <a-menu>-->
<!--                <a-menu-item key="0">-->
<!--                  <router-link class="text-decoration-none" :to="{name: 'profile-page', params: {-->
<!--                    slug: store.state.authedUser.user.profile.profile_slug-->
<!--                  }}">-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      <UserOutlined class="me-2" />-->
<!--                      Profile-->
<!--                    </div>-->
<!--                  </router-link>-->
<!--                </a-menu-item>-->
<!--                <a-menu-item key="1">-->
<!--                  <router-link class="text-decoration-none" :to="{name: 'password-and-security'}">-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      <SettingOutlined class="me-2" />-->
<!--                      Settings-->
<!--                    </div>-->
<!--                  </router-link>-->
<!--                </a-menu-item>-->
<!--                <a-menu-divider />-->
<!--                <a-menu-item key="3" @click="logout">-->
<!--                  <LogoutOutlined class="me-2" />-->
<!--                  Logout-->
<!--                </a-menu-item>-->
<!--              </a-menu>-->
<!--            </template>-->
<!--          </a-dropdown>-->
<!--        </a-space>-->
      </a-col>
    </a-row>

    <NotificationsDrawer />
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {inject, ref, watch} from 'vue';
import {UserOutlined, SettingOutlined, LogoutOutlined, BellOutlined, GlobalOutlined} from "@ant-design/icons-vue";
import {useStore} from "vuex";
import NotificationsDrawer from "@/components/NotificationsDrawer";

export default {
  name: "NavbarComponent",
  props: ['customClass'],
  components: {
    NotificationsDrawer,
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
    BellOutlined,
    GlobalOutlined
  },
  data() {
    return {
      isAuthed: false
    }
  },
  setup() {
    const emitter = inject('emitter')
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const current = ref([route.name])
    const searchType = ref('jobs')
    const searchValue = ref(null)
    const changePage = (e) => {
      router.push({name: e.key})
    }
    watch(() => route.name, () => {
      current.value = [route.name]
    });
    const logout = () => {
      localStorage.clear()
      router.push({
        name: 'login'
      })
    }
    const openNotificationDrawer = () => {
      emitter.emit('openNotificationDrawer')
    }

    const changeLang = locale => {
      this.$i18n.locale = locale
    }
    return {
      current,
      changePage,
      store,
      logout,
      openNotificationDrawer,
      searchType,
      searchValue,
      changeLang
    }
  }
}
</script>

<style scoped>

</style>
