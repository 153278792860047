<template>
  <div class="container pt-5">
    <a-row>
      <a-col :lg="{span: 20, offset: 2}">
        <a-row>
          <a-col :span="24">
            <a-card class="p-md-4 p-sm-2">
              <a-row>
                <a-col :xs="{span: 0}" :sm="{span: 0}" :lg="{ span: 24 }">
                  <a-space align="center">
                    <div class="profile-picture me-2">
                      <a-avatar :src="profile.image" :size="80" class="profile-avatar" />
                    </div>
                    <div class="ms-2">
                      <div class="mt-2">
                        <a-space>
                          <span>{{ profileData.title }}</span>
                          <a-button type="primary" shape="circle" ghost @click="router.push({name: 'edit-profile'})" v-if="editable">
                            <template #icon><EditOutlined /></template>
                          </a-button>
                          <a-button type="primary" shape="circle" ghost @click="editProfileImage" v-if="editable">
                            <template #icon><CameraOutlined /></template>
                          </a-button>
                          <a-button type="link" shape="circle" size="large" @click="profileNotification" :loading="createNotificationLoading || deleteNotificationLoading" v-else>
                            <template #icon>
                              <BellFilled v-if="profileData.is_notifiable" />
                              <BellOutlined v-else />
                            </template>
                          </a-button>
                        </a-space>
                      </div>
                      <small>
                        {{ profileData.location }}
                      </small>
                    </div>
                  </a-space>
                </a-col>
                <a-col :xs="{span: 24}" :sm="{span: 24}" :lg="{ span: 0 }" class="text-center">
                  <div class="profile-picture me-2">
                    <a-avatar :src="profile.image" :size="100" class="profile-avatar" />
                  </div>
                  <a-space direction="vertical" class="w-75">
                    <div class="mt-2">
                      {{ profileData.title }}
                    </div>
                    <small>
                      {{ profileData.location }}
                    </small>
                    <div class="mt-2">
                      <!--                  <a-button type="primary" shape="circle" ghost @click="router.push({name: 'edit-profile'})" v-if="editable">-->
                      <!--                    <template #icon><EditOutlined /></template>-->
                      <!--                  </a-button>-->
                      <a-button type="primary" block @click="router.push({name: 'edit-profile'})" v-if="editable">
                        <EditOutlined />
                        {{ $t('edit') }} {{$t('profile')}}
                      </a-button>
                      <a-button type="link" shape="circle" size="large" @click="profileNotification" :loading="createNotificationLoading || deleteNotificationLoading" v-else>
                        <template #icon>
                          <BellFilled v-if="profileData.is_notifiable" />
                          <BellOutlined v-else />
                        </template>
                      </a-button>
                    </div>
                  </a-space>
                </a-col>
              </a-row>
              <a-row class="mt-4">
                <a-col :lg="{span: 8}" :sm="{span: 24 , order: 2}" :xs="{span: 24 , order: 2}">
                  <a-row>
                    <a-col :xs="{span: 24}" :sm="{span: 24}" :lg="{span: 23}">
                      <div class="mt-3 mb-3">
                        <div class="fw-bolder">
                          {{ $t('foundYear') }}
                        </div>
                        <div class="text-muted">
                          <p>{{ profileData.founded }}</p>
                        </div>
                      </div>
                      <div class="mt-3 mb-3">
                        <div class="fw-bolder">
                          {{$t('website')}}
                        </div>
                        <div class="text-muted">
                          <a :href="profileData.website" class="text-decoration-none">{{ profileData.website }}</a>
                        </div>
                      </div>
                      <div class="mt-3 mb-3">
                        <div class="fw-bolder">
                          {{ $t('phone') }}
                        </div>
                        <div class="text-muted">
                          <p>{{ profileData.phone }}</p>
                        </div>
                      </div>
                      <div class="mt-3 mb-3">
                        <div class="fw-bolder">
                          {{ $t('address') }}
                        </div>
                        <div class="text-muted">
                          <p>{{ profileData.address }}</p>
                        </div>
                      </div>
                      <div class="mt-3 mb-3">
                        <div class="fw-bolder">
                          {{ $t('rate') }}
                        </div>
                        <div class="text-muted">
                          <StarOutlined /> <small>4.2</small>
                        </div>
                      </div>
                    </a-col>
                    <a-col :xs="{span: 0}" :sm="{span: 0}" :lg="{span: 1}">
                      <a-divider type="vertical" class="h-100 mt-3 mb-3" />
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :lg="{span: 16}" :sm="{span: 24 , order: 2}" :xs="{span: 24, order: 1}">
                  <a-row class="ps-lg-3">
                    <a-col :span="24" class="mt-lg-3 mt-3">
                      <h5 class="fw-bold">{{ $t('overview') }}</h5>
                      <div class="bio mt-3">
                        <a-typography-paragraph
                            :ellipsis="ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false"
                            :content="profileData.bio"
                        />
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <a-divider type="horizontal" />
                    </a-col>
                    <a-col :span="24">
                      <h5 class="fw-bold">{{ $t('specialties') }}:</h5>
                      <div class="bio mt-3">
                        <span class="badge text-bg-primary m-1" v-for="(item, index) in profileData.specialties" :key="index">
                          {{ item.name }}
                        </span>
                      </div>
                    </a-col>
                    <a-col :lg="{span: 0}" :sm="{span: 24}" :xs="{span: 24}">
                      <a-divider />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="24" class="mt-3 mb-5">
            <a-card class="p-md-4 p-sm-2">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="fw-bold mt-2">
                  {{ $t('reviews') }}
                </h5>
                <a href="#" class="btn btn-link" @click.prevent="openReviewDrawer" v-if="reviewer">
                  <img src="@/assets/icons/add.svg" width="28" alt="add-review">
                </a>
              </div>
              <div>
                <ReviewComponent v-for="(item, index) in reviewsLoadMore ? profileData.reviews : profileData.reviews.slice(0, 3)" :review="item" :key="index" />
              </div>
              <div class="mt-4 text-center" v-if="profileData.reviews.length > 3">
                <a-button type="primary" @click.prevent="reviewsLoadMore = false" v-if="reviewsLoadMore">
                  {{ $t('loadLess') }}
                </a-button>
                <a-button type="primary" @click.prevent="reviewsLoadMore = true" v-else>
                  {{ $t('loadMore') }}
                </a-button>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <ReviewModalComponent />
  </div>
</template>

<script>
import ReviewComponent from "@/components/ReviewComponent";
import {EditOutlined, BellOutlined, BellFilled, StarOutlined, CameraOutlined} from "@ant-design/icons-vue";
import {inject, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import ReviewModalComponent from "@/components/ReviewModalComponent";
import {useStore} from "vuex";
import {useMutation} from "@vue/apollo-composable";
import {CreateNotificationMutationGql} from "@/gql/create-notification-mutation-gql";
import {DeleteNotificationMutationGql} from "@/gql/delete-notification-mutation-gql";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
export default {
  name: "ClientProfileView",
  components: {
    ReviewModalComponent,
    ReviewComponent,
    EditOutlined,
    BellOutlined,
    BellFilled,
    StarOutlined,
    CameraOutlined
  },
  props: {
    profile: {
      required: true,
      type: Object
    },
  },
  setup(props) {
    const profileData = ref(JSON.parse(JSON.stringify(props.profile)))
    const editable = ref(false);
    const store = useStore()
    const emitter = inject('emitter')
    const router = useRouter()
    const ellipsis = ref(true)
    const reviewsLoadMore = ref(false)
    const reviewer = ref(store.state.authedUser.user.profile.profile_type != 'medical_center');
    // console.log(store.state.authedUser.user.profile.profile_type != 'medical_center')
    const openReviewDrawer = () => {
      emitter.emit('openReviewDrawer', {profile_id: profileData.value.id});
    }
    onMounted(() => {
      if (store.state.authedUser.user.profile.profile_slug == profileData.value.profile_slug) {
        editable.value = true;
      }
    })
    const {t} = useI18n()
    const {mutate: createNotificationMutation, loading: createNotificationLoading} = useMutation(CreateNotificationMutationGql)
    const {mutate: deleteNotificationMutation, loading: deleteNotificationLoading} = useMutation(DeleteNotificationMutationGql)
    const profileNotification = () => {
      if (profileData.value.is_notifiable) {
        deleteNotificationMutation({
          profile_id: profileData.value.id
        }).then(() => {
          message.success(t('success'))
          profileData.value.is_notifiable = false
        })
      } else {
        createNotificationMutation({
          profile_id: profileData.value.id
        }).then(() => {
          message.success(t('success'))
          profileData.value.is_notifiable = true
        })
      }
    }
    const editProfileImage = () => {
      emitter.emit('editProfileImage', {
        profile_id: props.profile.id,
        image: props.profile.image
      });
    }
    return {
      ellipsis,
      router,
      openReviewDrawer,
      reviewsLoadMore,
      editable,
      reviewer,
      profileNotification,
      createNotificationLoading,
      deleteNotificationLoading,
      profileData,
      editProfileImage
    }
  }
}
</script>

<style scoped>

</style>
