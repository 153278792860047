import gql from "graphql-tag";

export const ViewProfileGql = gql`
    query profile($slug:String!){
        profile(slug:$slug){
            id
            title
            profile_slug
            profile_type
            image
            bio
            location
            founded
            website
            address
            phone
            languages
            registration_number
            profile_type
            is_notifiable
            user{
                name
                id
            }
            employment_history{
                id
                title
                end_date
                start_date
                employer_name
                sort
            }
            certificates{
                id
                name
                provider
                expire_date
                issued_date
                certificate_id
            }
            education {
                id
                name
                school
                start_date
                end_date
                degree
            }
            specialties {
                id
                name
            }
            reviews {
                id
                rate
                review
                created_at
                user {
                    id
                    name
                    profile {
                        id
                        title
                        image
                    }
                }
            }
        }
    }
`