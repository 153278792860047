<template>
  <div>
    <a-modal v-model:visible="visible" :title="$t('subscription')">
      <SubscriptionView />
      <template #footer>

      </template>
    </a-modal>
  </div>
</template>

<script setup>
import SubscriptionView from "@/views/signup/SubscriptionView.vue";
import {inject, ref} from "vue";

const visible = ref(false)
const emitter = inject('emitter')

emitter.on('openSubscriptionModal', () => {
  visible.value = true
})

</script>

<style scoped>

</style>
