<template>
<div>
  <a-modal v-model:visible="visible" @ok="submitForm">
    <a-form
        :model="review"
        name="basic"
        autocomplete="off"
        layout="vertical"
        @finish="saveData"
    >
      <a-form-item
          :label="$t('rate')"
          name="rate"
          :rules="[{ required: true }]"
      >
        <a-rate v-model:value="review.rate" allow-half />
      </a-form-item>
      <a-form-item
          :label="$t('review')"
          name="review"
          :rules="[{ required: true, whitespace: true }]"
      >
        <a-textarea
            v-model:value="review.review"
            :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </a-form-item>
      <a-button type="primary" html-type="submit" id="submitReviewForm" class="d-none">{{$t('save')}}</a-button>
    </a-form>
  </a-modal>
</div>
</template>

<script>
import {inject, ref} from 'vue'
import {useMutation} from "@vue/apollo-composable";
import {CreateReviewMutationGql} from "@/gql/create-review-mutation-gql";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
export default {
  name: "ReviewModalComponent",
  setup() {
    const emitter = inject('emitter')
    const visible = ref(false);
    const profileID = ref(null);
    const review = ref({
      id: null,
      review: null,
      rate: null
    });
    const {t} = useI18n()
    const saveData = () => {
      saveReview({
        review: review.value.review,
        rate: review.value.rate.toString(),
        profile_id: profileID.value
      }).then(() => {
        visible.value = false
        message.success(t('reviewAdded'))
        emitter.emit('refetchProfileData')
      })
    }
    const submitForm = () => {
      document.getElementById('submitReviewForm').click()
    }

    const {mutate: saveReview} = useMutation(CreateReviewMutationGql);
    emitter.on('openReviewDrawer', (data) => {
      visible.value = true;
      profileID.value = data.profile_id
      if (data) {
        review.value = data
      }
    });
    return {
      visible,
      review,
      saveData,
      submitForm
    }
  }
}
</script>

<style scoped>

</style>
