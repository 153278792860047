<template>
  <div>
    <div class="container-fluid pt-4 pb-4 bg-white rounded-3 shadow-sm">
      <a-row>
        <a-col :span="24" align="center">
          <div>
            <a-space direction="vertical" class="w-100">
              <a-avatar :size="80" :src="authedUser.user.profile.image" v-if="authedUser.user.profile.image" />
              <a-avatar :size="80" v-else>
                <template #icon><UserOutlined /></template>
              </a-avatar>
              <span class="fw-bold">{{ authedUser.user.name }}</span>
              <small class="fw-light">{{ authedUser.user.profile.title }}</small>
              <a-divider />
<!--              <div class="d-flex justify-content-between align-items-center pb-1">-->
<!--                <small>-->
<!--                  Who viewed your profile-->
<!--                </small>-->
<!--                <small class="fw-bold text-primary">-->
<!--                  100-->
<!--                </small>-->
<!--              </div>-->
              <div v-if="loading">
                <a-spin />
              </div>
              <div class="d-flex justify-content-between align-items-center pb-1" v-else>
                <small>
                  {{ $t('subscriptionEndsAt') }}
                </small>
                <small class="fw-bold text-primary">
                  <!--            {{ result.whoAmI.profile.subscription_end_at }}-->
                  {{moment(userSubscription.expire_at).format('DD MMM Y')}}
<!--                  22/10/2003-->
                </small>
              </div>
              <div class="d-grid gap-2 pb-1 mt-3">
                <a-button type="primary" ghost v-if="authedUser.user.profile.profile_type == 'medical_center'" @click="openJobModal">
                  {{ $t('postNewJob') }}</a-button>
                <a-button type="primary" @click="$router.push({name: 'profile-page', params: {
                  slug: authedUser.user.profile.profile_slug
                }})">{{ $t('viewProfile') }}</a-button>
              </div>
            </a-space>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="container-fluid mt-2">
      <div class="text-center text-muted">
        <small>Doctors Gulf Corporation © {{new Date().getFullYear()}}</small>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {inject, ref} from "vue";
import {useQuery} from "@vue/apollo-composable";
import GetUserSubscriptionGql from "@/gql/get-user-subscription-gql";
// import moment from "moment";
const store = useStore()
const emitter = inject('emitter')
const moment = inject('moment')
const authedUser = store.state.authedUser

const userSubscription = ref(null)
const openJobModal = () => {
  emitter.emit('openJobModal', null)
}

const {result, loading, onResult} = useQuery(GetUserSubscriptionGql, {}, {
  clientId: 'subscriptions'
})
onResult(() => {
  userSubscription.value = result.value.user_subscription
})
</script>
