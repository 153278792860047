<template>
  <div>
    <a-row class="vh-100" v-if="isReady">
      <a-col :span="17" class="bg-light">
        <div class="d-flex align-items-center justify-content-center w-100 h-100">
          <img src="@/assets/images/undraw_my_password.svg" style="width: 40%;" alt="Login">
        </div>
      </a-col>
      <a-col :span="7" class="borderd-left my-auto">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <div class="text-center mb-5">
              <img src="@/assets/images/dr.png" width="200" alt="doctor-in">
              <h4 class="mt-2">{{ $t('resetPassword') }}</h4>
            </div>
          </div>
        </div>
        <div class="p-4 pt-2">
          <a-form layout="vertical" :model="resetPassword" @finish="onFinish" autocomplete="off">
            <a-form-item :label="$t('newPassword')" name="new_password" :rules="[{ required: true }]">
              <a-input v-model:value="resetPassword.new_password" />
            </a-form-item>

            <a-form-item :label="$t('confirmPassword')" name="confirmation_password" :rules="[{ required: true }]">
              <a-input v-model:value="resetPassword.confirmation_password" />
            </a-form-item>

            <a-form-item>
              <a-button type="primary" block class="fw-bold" html-type="submit">{{$t('resetPassword')}}</a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
    <a-row class="vh-100" v-if="isExpired">
      <a-col :span="8" :offset="8" class="my-auto">
        <div class="d-flex align-items-center justify-content-center">
          <div>
            <div class="text-center mb-5">
              <img src="@/assets/images/logo.svg" alt="doctor-in">
              <!--            <h4 class="mt-2">Login into your account</h4>-->
            </div>
          </div>
        </div>
        <div class="p-4 pt-2">
          <div class="d-grid gap-2 text-center">
            <a-typography-title :level="4">
              {{ $t('resetTokenInvalid') }}
            </a-typography-title>
            <a-button type="primary" class="fw-bold" size="large" ghost block @click="$router.push({name: 'login'})">
              {{ $t('backToLogin') }}</a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {ref} from "vue";
import {useMutation, useQuery} from "@vue/apollo-composable";
import {message} from "ant-design-vue";
import {CheckResetPasswordTokenGql} from "@/gql/check-reset-password-token-gql";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {ResetPasswordMutationGql} from "@/gql/reset-password-mutation-gql";
import {useI18n} from "vue-i18n";

export default {
  name: "reset-password-page",
  setup() {
    const store = useStore();
    const router = useRouter();
    const isReady = ref(false);
    const isExpired = ref(false);
    const route = useRoute();
    const resetPassword = ref({
      new_password: null,
      confirmation_password: null
    });
    const {loading: checkResetPasswordTokenLoading, onResult, onError} = useQuery(CheckResetPasswordTokenGql, {
      token: route.params.token
    }, {
      clientId: 'auth'
    });

    onResult(() => {
      isReady.value = true
    });

    onError(() => {
      isExpired.value = true
    })

    const {mutate: resetPasswordMutation, loading: resetPasswordLoading} = useMutation(ResetPasswordMutationGql, {
      clientId: 'auth'
    })
    const {t} = useI18n()
    const onFinish = () => {
      resetPasswordMutation({
        token: route.params.token,
        password: resetPassword.value.new_password
      }).then((res) => {
        localStorage.setItem('authed_user', JSON.stringify(res.data.resetPassword))
        store.commit('setAuthedUser', res.data.resetPassword)
        let routeName = 'find-work';
        console.log(res.data.resetPassword.user.profile.profile_type)
        if (res.data.resetPassword.user.profile.profile_type == 'medical_center') {
          routeName = 'client-dashboard'
        }
        router.push({
          name: routeName
        })
        message.success(t('passUpdated'))
      })
    }

    return {
      resetPassword,
      onFinish,
      resetPasswordLoading,
      checkResetPasswordTokenLoading,
      isExpired,
      isReady
    }
  }
}
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
