import { createStore } from 'vuex'

export default createStore({
  state: {
    specialties: [],
    authedUser: {},
    countries: require('@/assets/countries.json'),
    languages: require('@/assets/laguages.json'),
  },
  getters: {

  },
  mutations: {
    setAuthedUser(state, data) {
      state.authedUser = data
    },
    setSpecialties(state, data) {
      state.specialties = data
    },
    updateProfileImage(state, data) {
      state.authedUser.user.profile.image = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
