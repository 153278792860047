<template>
  <div class="container mt-5 pb-5">
    <div v-if="loading || !queryEnabled">
      <a-row :gutter="10">
        <a-col :xs="{span: 24}" :sm="{span: 24}" :lg="{span: 14, offset: 5}">
          <div class="container">
            <a-skeleton avatar active :paragraph="{ rows: 4 }" />
            <a-skeleton avatar active :paragraph="{ rows: 4 }" />
            <a-skeleton avatar active :paragraph="{ rows: 4 }" />
          </div>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <a-row :gutter="10" v-if="result.jobs.data.length > 0">
        <a-col :lg="{span: 10}" :xs="{span: 24}" :sm="{span: 24}" class="position-relative">
          <div class="jobs-section h-100 w-100">
            <a-row>
              <a-col :xs="{span: 24}" :lg="{span: 0}">
                <a-input-search
                    v-model:value="routeQueryParams.title"
                    :placeholder="$t('enterJobTitle')"
                    enter-button
                    size="large"
                    @search="onSearch"
                />
                <a-button type="link" @click="openAdvancedSearchModal">{{ $t('advancedSearch') }}</a-button>
              </a-col>
              <a-col :xs="{span: 24}" :sm="{span: 24}" :md="{span: 24}" :lg="{span: 0}" v-for="(item, index) in result.jobs.data" :key="index">
                <SearchJobComponent class="mb-3" :job="item" @click="openJobDrawer(item.id)" />
              </a-col>
              <a-col :xs="{span: 0}" :sm="{span: 0}" :md="{span: 0}" :lg="{span: 24}" v-for="(item, index) in result.jobs.data" :key="index">
                <SearchJobComponent class="mb-3" :job="item" @click="viewJob(item.id)" />
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :lg="{span: 14}" :xs="{span: 0}" :sm="{span: 0}">
          <div class="container">
            <a-row :gutter="2">
              <a-col :span="24" class="mb-2">
                <a-input-search
                    v-model:value="routeQueryParams.title"
                    :placeholder="$t('enterJobTitle')"
                    enter-button
                    size="large"
                    @search="onSearch"
                />
                <a-button type="link" @click="openAdvancedSearchModal">{{ $t('advancedSearch') }}</a-button>
              </a-col>
              <a-col :span="24" class="job-view bg-white rounded-3">
                <JobView v-model:job_id="selectedJobID" v-if="selectedJobID"/>
              </a-col>
            </a-row>
          </div>
        </a-col>

      </a-row>

      <div v-else>
        <a-row>
          <a-col :span="10" :offset="7">
            <div class="d-flex justify-content-center mb-5">
              <a-input-search
                  v-model:value="routeQueryParams.title"
                  :placeholder="$t('enterJobTitle')"
                  enter-button
                  size="large"
                  @search="onSearch"
              />
            </div>
          </a-col>
          <a-col :span="10" :offset="7">
            <a-empty
                :image="require('@/assets/images/undraw_void.svg')"
                :image-style="{height: '300px' }"
                class="mt-3"
            >
              <template #description>
<!--                <svg>{{require('@/assets/images/undraw_void.svg')}}</svg>-->
                <span class="text-muted pt-3 text-center">
                  {{ $t('noData') }}
                </span>
              </template>
            </a-empty>
          </a-col>
        </a-row>
      </div>
    </div>
    <AdvancedSearchModal />
  </div>
</template>

<script>
import SearchJobComponent from "@/components/SearchJobComponent";
import JobView from "@/views/JobView";
import {GetJobsGql} from "@/gql/get-jobs-gql";
import {useQuery} from "@vue/apollo-composable";
import {ref, reactive, watch, inject} from "vue";
import {useRoute, useRouter} from 'vue-router';
import AdvancedSearchModal from "@/components/AdvancedSearchModal";

export default {
  name: "JobsSearchPage",
  components: {
    AdvancedSearchModal,
    JobView,
    SearchJobComponent,
  },
  setup() {
    let route = useRoute();
    let router = useRouter();
    let queryEnabled = ref(false)
    let selectedJobID = ref(null)
    let routeQueryParams = ref({
      title: '',
      location: null,
      specialty_id: null
    })
    let queryVars = reactive({
      queryType: '',
      limit: 50,
      page: 1,
    })
    const emitter = inject('emitter')

    routeQueryParams.value = route.query
    queryVars.title = routeQueryParams.value.title
    queryVars.location = routeQueryParams.value.location
    queryVars.specialty_id = routeQueryParams.value.specialty_id
    queryEnabled.value = true

    const {result, loading, refetch} = useQuery(GetJobsGql, queryVars, () => ({
      enabled: queryEnabled.value,
    }));
    console.log(queryVars)

    const viewJob = (id) => {
      selectedJobID.value = id
    }

    const onSearch = (value) => {
      queryVars.title = value
      router.replace({
        name: route.name,
        query: routeQueryParams.value
      })
      refetch()
    }

    watch(result, result => {
      if (result && result.jobs.data.length > 0) {
        selectedJobID.value = result.jobs.data[0].id
      }
    });

    const openAdvancedSearchModal = () => {
      emitter.emit('openAdvancedSearchModal');
    }

    const openJobDrawer = (id) => {
      emitter.emit('viewJobDrawer', id)
    }

    emitter.on('advancedJobSearchEvent', (data) => {
      console.log(data)
      if (data.location) {
        queryVars.location = data.location;
        console.log(data.location)
      }
      if (data.specialty_id) {
        queryVars.specialty_id = data.specialty_id;
        console.log(data.specialty_id)
      }
      router.replace({
        name: route.name,
        query: routeQueryParams.value
      });
      refetch()
    });

    return {
      result,
      loading,
      queryEnabled,
      selectedJobID,
      viewJob,
      routeQueryParams,
      onSearch,
      openAdvancedSearchModal,
      openJobDrawer
    }
  },
}
</script>

<style scoped>
.jobs-section {
  overflow-y: auto;
  position: relative;
  height: 100%;
  padding-right: 5px;
}

@media only screen and (min-width: 900px) {
  /* For mobile phones: */
  .jobs-section {
    position: absolute;
  }
}

.job-view {
  height: 75vh;
}
</style>
