<template>
  <nav :class="'navbar navbar-expand-lg ' + customClass">
    <div class="container">
      <a class="navbar-brand text-primary" href="#">
        <img src="@/assets/images/dr.png" width="90" class="" alt="DoctorsGulf">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-if="$route.meta.name == 'platform'">
          <li class="nav-item">
            <router-link :to="{name: 'find-job'}" :class="'nav-link ' + ($route.name == 'find-job' ? 'text-primary' : '')" aria-current="page">
              {{ $t('findJob') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'platform_jobs_search'}" :class="'nav-link ' + ($route.name == 'platform_jobs_search' ? 'text-primary' : '')" aria-current="page">{{ $t('search') }}</router-link>
          </li>
        </ul>

        <ul class="navbar-nav me-auto mx-auto mb-2 mb-lg-0" v-else>
          <li class="nav-item">
            <router-link :to="{name: 'website_home'}" :class="'nav-link ' + ($route.name == 'website_home' ? 'text-primary' : '')" aria-current="page">
              {{ $t('feeds') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'website_pricing'}" :class="'nav-link ' + ($route.name == 'website_pricing' ? 'text-primary' : '')" aria-current="page">
              {{ $t('pricing') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'website_contact_us'}" :class="'nav-link ' + ($route.name == 'website_contact_us' ? 'text-primary' : '')" aria-current="page" href="#">
              {{ $t('contactUs') }}</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <a class="nav-link" aria-current="page" href="#">Jobs</a>-->
<!--          </li>-->
        </ul>

        <div class="d-flex align-items-center" v-if="isAuthed">
          <div class="input-group input-group-sm rounded-3 me-3">
            <span class="input-group-text bg-transparent">
              <img src="@/assets/icons/search_black.svg" width="20" alt="Search">
            </span>
            <input type="text" class="form-control border-start-0 border-end-0" placeholder="Search" aria-label="Search">
            <div class="btn-group border border-start-0">
              <button type="button" class="btn btn-text btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                Jobs
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Job</a></li>
                <li><a class="dropdown-item" href="#">Person</a></li>
                <li><a class="dropdown-item" href="#">Medical Center</a></li>
              </ul>
            </div>
          </div>
          <a href="" class="me-2">
            <img src="@/assets/icons/notification.svg" width="20" alt="notification">
          </a>
          <div class="dropdown-center">
            <a href="" class="me-2" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="@/assets/icons/avatar.svg" width="30" alt="user-avatar">
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#"> Profile</a></li>
              <li><a class="dropdown-item" href="#">Setting</a></li>
              <li class="text-muted"><hr class="dropdown-divider w-100 bg-light"></li>
              <li><a class="dropdown-item" href="#">Logout</a></li>
            </ul>
          </div>
        </div>
        <div class="d-flex" v-else>
          <a-space>
            <div>
              <a-button type="text" @click="$i18n.locale = 'ar'" v-if="$i18n.locale == 'en'">
                <template #icon>
                  <GlobalOutlined />
                </template>
                العربية
              </a-button>
              <a-button type="text" @click="$i18n.locale = 'en'" v-else>
                <template #icon>
                  <GlobalOutlined />
                </template>
                English
              </a-button>
            </div>
            <router-link :to="{name: 'login'}">
              <a-button type="primary">
                {{ $t('signIn') }}
              </a-button>
            </router-link>
          </a-space>
<!--          <router-link :to="{name: 'login'}">-->
<!--            <a-button type="primary">-->
<!--              Sign In-->
<!--            </a-button>-->
<!--          </router-link>-->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {GlobalOutlined} from "@ant-design/icons-vue";

export default {
  name: "navbar-component",
  components: {GlobalOutlined},
  props: ['customClass', 'isAuthed']
}
</script>

<style scoped>
.nav-link.active {
  color: red
}
.dropdown-menu[data-bs-popper] {
  left: -8rem;
}
/*@media (min-width: 1200px) {*/
/*  .container-xl, .container-lg, .container-md, .container-sm, .container {*/
/*    max-width: 1050px;*/
/*  }*/
/*}*/
</style>
