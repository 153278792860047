<template>
  <div>
    <a-result status="404" title="404" :sub-title="$t('pageNotFound')">
      <template #extra>
        <a-button type="primary" @click="() => router.back()">{{ $t('backHome') }}</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: "NotFoundComponent",
  setup() {
    const router = useRouter();
    return {
      router
    }
  }
}
</script>

<style scoped>

</style>
