<template>
  <div>
    <a-modal v-model:visible="visible" :title="$t('report')" :afterClose="close">
      <a-form
          :model="report"
          name="basic"
          autocomplete="off"
          layout="vertical"
          @finish="saveData"
      >
        <a-form-item
            name="reason"
            :rules="[{ required: true, message: $t('reportReason') }]"
        >
          <template #label>
            <a-typography-title :level="5">{{ $t('whyReport') }}?</a-typography-title>
          </template>
          <a-radio-group v-model:value="report.reason">
            <a-radio :style="radioStyle" value="Suspicious, spam, or fake">{{ $t('fake') }}</a-radio>
            <a-radio :style="radioStyle" value="Harassment or hateful speech">{{ $t('harmful') }}</a-radio>
            <a-radio :style="radioStyle" value="Violence or physical harm">{{$t('violence')}}</a-radio>
            <a-radio :style="radioStyle" value="Sexual Content">{{$t('sexual')}}</a-radio>
<!--            <a-radio :style="radioStyle" value="Other">-->
<!--              Other...-->
<!--            </a-radio>-->
          </a-radio-group>
<!--          <a-input v-if="report.reason === 'Other'" />-->
        </a-form-item>

        <a-button html-type="submit" id="submitReviewForm" class="d-none"></a-button>
      </a-form>

      <template #footer>
        <div>
          <a-button type="primary" ghost @click="visible=false">{{ $t('close') }}</a-button>
          <a-button type="primary" @click="submitForm" :loading="loading">{{ $t('report') }}</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {inject, reactive, ref} from 'vue';
import {CreateReportMutationGql} from "@/gql/create-report-mutation-gql";
import {useMutation} from "@vue/apollo-composable";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
export default {
  name: "ReportModalComponent",
  setup() {
    const visible = ref(false);
    const emitter = inject('emitter');
    const report = ref({
      'id': '',
      'type': '',
      'reason': ''
    });
    const radioStyle = reactive({
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    });
    const {t} = useI18n()
    const {mutate, loading} = useMutation(CreateReportMutationGql);
    const saveData = () => {
      mutate(report.value).then(() => {
        message.success(t('reportAdded'))
        visible.value = false
      })
    }
    const close = () => {
      report.value = {
        'id': '',
        'type': '',
        'reason': ''
      }
    }

    const submitForm = () => {
      document.getElementById('submitReviewForm').click();
    }

    emitter.on('openReportDrawer', data => {
      report.value.id = data.id;
      report.value.type = data.type;
      visible.value = true
    })

    return {
      visible,
      report,
      saveData,
      radioStyle,
      close,
      submitForm,
      loading
    }
  }
}
</script>

<style scoped>

</style>
