<template>
  <a-config-provider :component-size="componentSize" :locale="$i18n.locale == 'en' ? enUS : ar_EG">
    <router-view v-slot="{ Component }" v-if="!specialtyLoading">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <CertificateModalComponent />
    <JobModalComponent />
    <ViewJobDrawer />
    <ReportModalComponent />
  </a-config-provider>
</template>

<script>
import {inject, ref, watch, h} from "vue";
import {useMutation, useQuery} from "@vue/apollo-composable";
import {GetSpecialtiesGql} from "@/gql/get-specialties-gql";
import {useStore} from "vuex";
import CertificateModalComponent from "@/components/CertificateModalComponent";
import JobModalComponent from "@/components/JobModalComponent";
import ViewJobDrawer from "@/components/ViewJobDrawer";
import {CreateFirebaseTokenGql} from "@/gql/create-firebase-token-gql";
import {getToken, onMessage} from "firebase/messaging";
import ReportModalComponent from "@/components/ReportModalComponent";
import {notification, Button} from "ant-design-vue";
import getAuthData from "@/global functions/handel-auth-data";
// import {CheckProfileApprovalGql} from "@/gql/check-profile-approval-gql";
import ar_EG from 'ant-design-vue/es/locale/ar_EG';
import enUS from 'ant-design-vue/es/locale/en_US';

import {useRouter} from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  components: {ReportModalComponent, ViewJobDrawer, JobModalComponent, CertificateModalComponent},
  setup() {
    const messaging = inject('messaging');
    const componentSize = ref('default')
    const store = useStore();
    const router = useRouter();
    const {result: specialtyResult, loading: specialtyLoading} = useQuery(GetSpecialtiesGql, {}, {
      clientId: 'auth'
    })
    const {t} = useI18n()
    const emitter = inject('emitter')

    watch(() => specialtyResult.value, () => {
      if(specialtyResult.value) {
        store.commit('setSpecialties', specialtyResult.value.specialties)
      }
    });

    //Firebase Token Mutation
    const { mutate } = useMutation(CreateFirebaseTokenGql);

    //Notification Permission Request
    const authedData = getAuthData()
    if(authedData) {
      if (authedData.user.profile.is_approved) {
        Notification.requestPermission().then(() => {
          getToken(messaging).then(token => {
            mutate({
              token
            })
          });
          onMessage(messaging, payload => {
            emitter.emit('refetchNotificationsData');
            emitter.emit('refetchJobsList');
            const key = `open${Date.now()}`;

            notification.open({
              message: payload.notification.title,
              description: payload.notification.body,
              btn: () => h(Button, {
                type: 'primary',
                size: 'small',
                onClick: () => viewJob(payload.data.job_id),
              }, {
                default: () => 'View Job',
              }),
              key,
              onClose: close,
            });
          })
        })
      }
    }
    const viewJob = (id) => {
      router.push({name: 'job-view', params: {
        id: id
        }})
    }
    return {
      componentSize,
      specialtyLoading,
      t,
      enUS,
      ar_EG
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
