<template>
<div>
  <a-row>
    <a-col :span="10" :offset="6">
      <a-card>

      </a-card>
    </a-col>
  </a-row>
</div>
</template>

<script>
import {ref} from 'vue'
export default {
  name: "AccountPreferencesPage",
  setup() {
    const user = ref({
      name: null
    });
    const onFinish = () => {
      //
    }
    return {
      user,
      onFinish
    }
  }
}
</script>

<style scoped>

</style>