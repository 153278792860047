import gql from "graphql-tag";

export const GetPostsGql = gql`
    query posts($page:Int!, $limit:Int!){
        posts(page:$page, limit:$limit) {
            data {
                id
                post
                file {
                    file_url
                    file_type
                }
                user {
                    id
                    name
                    email
                    profile{
                        id
                        title
                        image
                    }
                }
                is_liked
                privacy
                file_id
                created_at
            }
        }
    }
`