<template>
  <div class="footer bg-secondary text-white text-center pt-3 pb-3">
    {{ $t('copyRights') }}
  </div>
</template>

<script>
export default {
  name: "footer-component"
}
</script>

<style scoped>

</style>