<template>
  <a-modal v-model:visible="visible" :title="`${$t('applicationsOf')}: ` + job.title">
    <div v-if="loading">
      <a-skeleton avatar :paragraph="{ rows: 2 }" />
      <a-skeleton avatar :paragraph="{ rows: 2 }" />
    </div>
    <div v-else>
      <div v-if="result.getJobsApplications.length > 0">
        <a-card v-for="(item, index) in result.getJobsApplications" :key="index" class="my-2">
          <a-row :justify="'space-between'" :align="'middle'">
            <a-col>
              <a-space>
                <a-avatar :size="'small'">
                  <template #icon><UserOutlined /></template>
                </a-avatar>
                <span>
                  {{item.user.name}}
                </span>
              </a-space>
            </a-col>
            <a-col>
              <a-space :size="8">
                <a-button type="link" size="small" :disabled="!item.file" @click="downloadFile(item.file.file_url)">
                  {{$t('resume')}}
                </a-button>
                <a-button type="link" size="small" @click="navigateToProfile(item)">
                  {{$t('profile')}}
                </a-button>
              </a-space>
            </a-col>
          </a-row>
        </a-card>
      </div>
      <div v-else>
        <a-empty :description="$t('noData')"></a-empty>
      </div>
    </div>

    <template #footer>
      <a-space>
        <a-button @click="visible = false">
          {{ $t('close') }}
        </a-button>
<!--        <a-button type="primary" :loading="isLoading" @click="applyJob">-->
<!--          Apply-->
<!--        </a-button>-->
      </a-space>
    </template>
  </a-modal>
</template>

<script>
import {inject, ref} from 'vue'
import { UserOutlined } from '@ant-design/icons-vue';
import {useLazyQuery} from "@vue/apollo-composable";
import {GetJobsApplicationsGql} from "@/gql/get-job-applications-gql";
import {useRouter} from "vue-router";
export default {
  name: "ViewJobApplicationsModalComponent",
  components: {
    UserOutlined
  },
  setup() {
    const visible = ref(false);
    const emitter = inject('emitter');
    const router = useRouter();
    const job = ref({
      id: null,
      title: null
    });
    const {result, load, loading} = useLazyQuery(GetJobsApplicationsGql)

    emitter.on('openViewApplicationModal', (data) => {
      visible.value = true;
      job.value = data;
      load(null, {
        id: job.value.id
      });
    });

    const navigateToProfile = (item) => {
      let routeURL = router.resolve({name: 'profile-page', params: {
        slug: item.user.profile.profile_slug
      }})
      window.open(routeURL.href, '_blank');
    }

    const downloadFile = (file_url) => {
      window.open(file_url, '_blank');
    }

    return {
      visible,
      result,
      loading,
      job,
      router,
      navigateToProfile,
      downloadFile
    }
  }
}
</script>

<style scoped>

</style>
