import gql from "graphql-tag";

export const CreateCertificateGql = gql`
    mutation createCertificate($name:String!,$provider:String!,$issued_date:String!,$expire_date:String!,$certificate_id:String!){
        createCertificate(name:$name, provider:$provider,issued_date:$issued_date, expire_date:$expire_date, certificate_id:$certificate_id){
            id
            name
            provider
            issued_date
            expire_date
            certificate_id
        }
    }
`