<template>
  <div>
    <div v-if="loading">
      <a-skeleton avatar active :paragraph="{ rows: 4 }" class="border-bottom pb-3 pb-3 mb-3 mt-3" />
      <a-skeleton avatar active :paragraph="{ rows: 4 }" />
    </div>
    <div v-else-if="!loading && jobs.length > 0">
      <InfiniteScrollComponent @loadMore="loadMoreJobs" :loading="loadMoreLoading">
        <HomeJobComponent v-for="(item, index) in jobs" :job="item" :key="index" @click="viewJob(item.id)"/>
      </InfiniteScrollComponent>
    </div>
    <a-empty
        :image="require('@/assets/images/undraw_empty.svg')"
        :image-style="{height: '200px' }"
        class="mt-3"
        v-else
    >
      <template #description>
          <span class="text-muted pt-3">
            {{ $t('noData') }}
          </span>
      </template>
    </a-empty>
  </div>
</template>

<script>
import HomeJobComponent from "@/components/HomeJobComponent";
import {useLazyQuery, useQuery} from '@vue/apollo-composable'
import {GetJobsGql} from "@/gql/get-jobs-gql";
import {inject, ref} from "vue";
import InfiniteScrollComponent from "@/components/InfiniteScrollComponent";

export default {
  name: "HomeJobsListView",
  components: {InfiniteScrollComponent, HomeJobComponent},
  props: {
    queryType: {
      type: String
    }
  },
  setup(props) {
    const emitter = inject('emitter')
    const emptyImageUrl = require('@/assets/images/undraw_empty.svg')
    const page = ref(1);
    const jobs = ref([]);
    const last_page = ref(1);
    // const limit = ref(50)
    const {error, loading, onResult, refetch} = useQuery(GetJobsGql, {
      queryType: props.queryType,
      title: '',
      page: 1,
      limit: 10
    });
    const {loading:loadMoreLoading, onResult: onLoadMoreResult, load} = useLazyQuery(GetJobsGql, {
      queryType: props.queryType,
      title: '',
      page: page,
      limit: 10
    })

    emitter.on('refetchJobsList', () => {
      refetch()
    })

    onLoadMoreResult((value) => {
      jobs.value.push(...value.data.jobs.data)
      last_page.value = value.data.jobs.last_page
    })

    onResult((value) => {
      jobs.value = value.data.jobs.data
      last_page.value = value.data.jobs.last_page
    })

    const viewJob = (id) => {
      emitter.emit('viewJobDrawer', id)
    }

    const loadMoreJobs = () => {
      if(!loadMoreLoading.value && last_page.value != page.value) {
        page.value++
        load();
      }
    }

    return {
      jobs,
      error,
      loading,
      emptyImageUrl,
      viewJob,
      loadMoreJobs,
      loadMoreLoading,
      page
    }
  }
}
</script>

<style scoped>

</style>
