<template>
  <div class="mt-2">
    <a-row>
      <a-col :span="10" :offset="6">
        <a-card>
          <a-typography-title :level="4">
            {{ $t('changePassword') }}
          </a-typography-title>
          <a-form
              :model="user"
              autocomplete="off"
              @finish="onFinish"
              layout="vertical"
          >
            <a-form-item
                :label="$t('oldPassword')"
                name="old_password"
                :rules="[{ required: true, message: 'Please input your old password!' }]"
            >
              <a-input-password v-model:value="user.old_password" />
            </a-form-item>
            <a-form-item
                :label="$t('newPassword')"
                name="new_password"
                :rules="[{ required: true, message: 'Please input your new password!' }]"
            >
              <a-input-password v-model:value="user.new_password" />
            </a-form-item>
            <a-form-item
                :label="$t('confirmNewPassword')"
                name="confirmation_password"
                :rules="[{ required: true, message: 'Please input confirm new password!' }]"
            >
              <a-input-password v-model:value="user.confirmation_password" />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit">
                {{ $t('save') }}
              </a-button>
            </a-form-item>
          </a-form>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {ref} from "vue";
import {useMutation} from "@vue/apollo-composable";
import {message} from "ant-design-vue";
import {UpdatePasswordMutationGql} from "@/gql/update-password-mutation-gql";
import {useI18n} from "vue-i18n";

export default {
  name: "PasswordSettingPage",
  setup() {
    const user = ref({
      old_password: null,
      new_password: null,
      confirmation_password: null
    })
    const {t} = useI18n()
    const {mutate} = useMutation(UpdatePasswordMutationGql)
    const onFinish = () => {
      mutate({
        old_password: user.value.old_password,
        new_password: user.value.new_password
      }).then(() => {
        message.success(t('passUpdated'))
        user.value = {
          old_password: null,
          new_password: null,
          confirmation_password: null
        }
      }).catch(() => {
        message.error(t('oldPassNotMatched'));
      })
    }
    return {
      user,
      onFinish
    }
  }
}
</script>

<style scoped>

</style>
