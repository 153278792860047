<template>
  <a-modal v-model:visible="visible" :title="`${$t('applyToJob')}: ` + job.title" @cancel="() => console.log('asdasd')">
    <a-upload
        id="resume-file"
        accept="pdf"
        v-model:file-list="resumeFile"
        :max-count="1"
        :response="false"
        :beforeUpload="(file) => {
          // console.log('asdasdasd')
          return false;
        }"
    >
      <a-button>
        <UploadOutlined />
        {{ $t('uploadResume') }} <small class="text-muted"> ({{ $t('optional') }})</small>
      </a-button>
    </a-upload>

    <template #footer>
      <a-space>
        <a-button @click="visible = false" :disabled="isLoading">
          {{ $t('close') }}
        </a-button>
        <a-button type="primary" :loading="isLoading" @click="applyJob">
          {{ $t('applyToJob') }}
        </a-button>
      </a-space>
    </template>
  </a-modal>
</template>

<script>
import {inject, ref} from 'vue'
import { UploadOutlined } from '@ant-design/icons-vue'
import axios from "axios";
import {useMutation} from "@vue/apollo-composable";
import {CreateJobApplicationMutationGql} from "@/gql/create-job-application-mutation-gql"
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
export default {
  name: "ApplyJobModalComponent",
  components: {
    UploadOutlined
  },
  setup() {
    const visible = ref(false);
    const emitter = inject('emitter');
    const isLoading = ref(false);
    const job = ref({
      id: null,
      title: null
    });
    const resumeFile = ref(null)

    emitter.on('openApplyJobModal', (data) => {
      visible.value = true;
      job.value = data
    });

    const {t} = useI18n()
    const {mutate: createJobApplicationMutation} = useMutation(CreateJobApplicationMutationGql);
    const applyJob = async () => {
      const file_id = await createFileId();
      createJobApplicationMutation({
        file_id: file_id ?? null,
        id: job.value.id
      }).then(() => {
        message.success(t('requestApplied'));
        emitter.emit('userAppliedToJob');
        visible.value = false;
        isLoading.value = false;
        resetData();
      });
    }

    const resetData = () => {
      job.value = {
        id: null,
        title: null
      }
      resumeFile.value = null
    }

    const createFileId = async () => {
      let file_id = null;
      isLoading.value = true;
      let createFile = {};
      if (resumeFile.value) {
        // Create a FormData object
        // const inputFile = document.getElementById('resume-file').files[0]
        console.log(resumeFile.value[0].originFileObj)
        let bodyFormData = new FormData();
        bodyFormData.set('operations', JSON.stringify({
          'query': `mutation createFile($file:Upload!, $file_type: String!) {
              createFile(file: $file, file_type: $file_type) {
                  id
              }
          }`,
          'variables': {"file": resumeFile.value[0].originFileObj, 'file_type': 'resume'}
        }));
        bodyFormData.set('operationName', null);
        bodyFormData.set('map', JSON.stringify({"file":["variables.file"]}));
        bodyFormData.append('file', resumeFile.value[0].originFileObj);

        let authedUser = JSON.parse(localStorage.getItem("authed_user"));
        // Post the request to GraphQL controller via Axios, jQuery.ajax, or vanilla XMLHttpRequest
        createFile = await axios.post(`${process.env.VUE_APP_GRAPHQL_URL}`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `${authedUser.token_type} ${authedUser.access_token}`,
          }
        })
        file_id = createFile.data.data.createFile.id
      }
      return file_id;
    }

    return {
      visible,
      job,
      resumeFile,
      applyJob,
      isLoading
    }
  }
}
</script>

<style scoped>

</style>
