import gql from "graphql-tag";

export const GetCertificateGql = gql`
    query certificates($limit:Int!, $page:Int!, $profile_id:Int){
        certificates(limit:$limit,page:$page,profile_id:$profile_id){
            data {
                id
                name
                provider
                issued_date
                expire_date
                certificate_id
            }
        }
    }
`