<template>
  <a-modal v-model:visible="visible" :title="$t('certificates')" @ok="submitForm" centered>
    <a-form :layout="'vertical'" :model="certificate" @finish="saveData" v-if="visible">
      <a-form-item :label="$t('certName')" name="name" :rules="[{required: true},{max: 255}, { whitespace: true }]">
        <a-input v-model:value="certificate.name" />
      </a-form-item>
      <a-form-item :label="$t('provider')" name="provider" :rules="[{required: true},{max: 255}, { whitespace: true }]">
        <a-input v-model:value="certificate.provider" />
      </a-form-item>
      <a-form-item :label="$t('issuedAt')" name="issued_date" :rules="[{required: true}]">
<!--        <a-input v-model:value="certificate.issued_date" placeholder="Please Enter Certificate Issued Date" />-->
        <a-date-picker v-model:value="certificate.issued_date" :format="'MMM YYYY'" class="w-100" picker="month" :disabled-date="disabledDateStart" @change="onChangeStartDate"/>
      </a-form-item>
      <a-form-item>
        <a-checkbox v-model:checked="certificate.expireable" @change="() => certificate.expire_date = ''">{{$t('certNoExpire')}}</a-checkbox>
      </a-form-item>
      <a-form-item :label="$t('expireAt')" name="expire_date" :rules="[{required: !certificate.expireable}]" >
        <a-date-picker v-model:value="certificate.expire_date" :format="'MMM YYYY'" class="w-100" picker="month" :disabled="certificate.expireable" :disabled-date="disabledDate" />
      </a-form-item>
      <a-form-item :label="$t('certId')" name="certificate_id" :rules="[{max: 255}, { whitespace: true }]" >
        <a-input v-model:value="certificate.certificate_id" />
      </a-form-item>
      <a-button type="primary" html-type="submit" class="d-none" id="submit-button" />
    </a-form>

    <template>
      <div class="d-flex justify-content-between">
        <a-popconfirm
            :title="$t('areSreDeleteEx')"
            :ok-text="$t('yes')"
            :cancel-text="$t('no')"
            @confirm="deleteData"
        >
          <a-button key="delete" danger>
            {{ $t('delete') }}
          </a-button>
        </a-popconfirm>
        <div>
          <a-button key="back" @click="visible = false">{{ $t('close') }}</a-button>
          <a-button key="submit" type="primary" @click="saveData" :loading="confirmLoading">{{ $t('save') }}</a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {inject, ref} from "vue";
import {useStore} from "vuex";
import {useMutation} from "@vue/apollo-composable";
import {message} from "ant-design-vue";
import {CreateCertificateGql} from "@/gql/create-certificate-gql";
import dayjs from 'dayjs'
import {UpdateCertificateGql} from "@/gql/update-certificate-gql";
import {useI18n} from "vue-i18n";

export default {
  name: "CertificateModalComponent",
  setup() {
    // const moment = inject('moment');
    let visible = ref(false)
    let confirmLoading = ref(false)
    let store = useStore()
    let certificate = ref({
      name: '',
      provider: '',
      issued_date: '',
      expire_date: '',
      certificate_id: '',
      expireable: false
    })
    const emitter = inject('emitter')
    const submitForm = () => {
      document.getElementById('submit-button').click();
    }
    const {t} = useI18n()
    const saveData = () => {
      confirmLoading.value = true;
      if (certificate.value.id) {
        updateCertificateMutationMutate(certificate.value).then(() => {
          confirmLoading.value = true;
          visible.value = false
          message.success(t('certPosted'))
          emitter.emit('certificatedSaved')
          emitUpdateProfile()
        })
      } else {
        saveCertificateMutationMutate(certificate.value).then(() => {
          confirmLoading.value = true;
          visible.value = false
          message.success(t('certPosted'))
          emitter.emit('certificatedSaved')
          emitUpdateProfile()
        })
      }
    }
    emitter.on('openCertificateModal', (data) => {
      visible.value = true
      if (data) {
        data = JSON.parse(JSON.stringify(data))
        data.issued_date = dayjs(data.issued_date);
        data.expire_date =  data.expire_date ? dayjs(data.expire_date) : '';
        data.expireable =  !data.expire_date;
        certificate.value = data
      } else {
        certificate.value = {
          name: '',
          provider: '',
          issued_date: '',
          expire_date: '',
          certificate_id: '',
          expireable: false
        }
      }
    })

    const emitUpdateProfile = () => {
      confirmLoading.value = false;
      emitter.emit('refetchProfileData')
    }

    const {mutate: saveCertificateMutationMutate, loading:saveCertificateMutationLoading} = useMutation(CreateCertificateGql)
    const {mutate: updateCertificateMutationMutate, loading:updateCertificateMutationLoading} = useMutation(UpdateCertificateGql)

    const disabledDate = current => {
      return current && current < dayjs(certificate.value.issued_date).add(1, 'M').endOf('day')
    };
    const disabledDateStart = current => {
      return current && current > dayjs().endOf('day')
    };

    const onChangeStartDate = () => {
      if (dayjs(certificate.value.issued_date).endOf('day') > dayjs(certificate.value.expire_date).endOf('day')) {
        certificate.value.expire_date = null
      }
    }

    return {
      visible,
      emitter,
      saveData,
      certificate,
      submitForm,
      confirmLoading,
      store,
      saveCertificateMutationMutate,
      saveCertificateMutationLoading,
      updateCertificateMutationMutate,
      updateCertificateMutationLoading,
      disabledDate,
      disabledDateStart,
      onChangeStartDate
    }
  }
}
</script>

<style scoped>

</style>
