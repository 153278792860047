import gql from "graphql-tag";

export const GetNotificationsGql = gql`
    query notifications($limit:Int!, $page:Int!){
        notifications(limit:$limit, page:$page){
            data{
                id
                notifiable{
                    __typename
                    ... on Job {
                        id
                        title
                        profile{
                            id
                            title
                            image
                        }
                    }
                }
                data
                type
                created_at
            }
            last_page
        }
    }
`