import gql from "graphql-tag";

export const UserQuery = gql`
    query{
        user{
            id
            name
            email
            profile{
                id
                is_approved
                registration_stage
                profile_type
            }
        }
    }
`