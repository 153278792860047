import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'

function getHeaders() {
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": process.env.VUE_APP_BASE_URL
    };
    let authedUser = localStorage.getItem("authed_user");
    if (authedUser) {
        authedUser = JSON.parse(authedUser)
        headers["Authorization"] = `${authedUser.token_type} ${authedUser.access_token}`;
    }
    return headers;
}

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: `${process.env.VUE_APP_GRAPHQL_AUTH_URL}`,
    fetch: (uri, options) => {
        options.headers = getHeaders();
        return fetch(uri, options);
    },
})

// Cache implementation
const cache = new InMemoryCache()

// Handle errors
const errorLink = onError(error => {
    if(error.response.errors[0].message == 'validation') {
        alert(Object.values(error.response.errors[0].extensions.validation)[0][0])
    }
    // console.log(Object.values(error.response.errors[0].extensions.validation)[0][0])
    // console.log('error', error.response.errors[0].extensions.validation)
})

// Create the apollo client
const defaultApolloClient = new ApolloClient({
    link: errorLink.concat(httpLink),
    cache,
})

export default defaultApolloClient;