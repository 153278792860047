<template>
  <a-modal v-model:visible="visible" :title="title" centered @cancel="resetData">
    <a-form
        :model="employment_history"
        name="basic"
        autocomplete="off"
        @finish="saveData"
        layout="vertical"
    >
      <a-form-item
          :label="$t('title')"
          name="title"
          :rules="[{ required: true }, {max: 255}, {whitespace: true}]"
      >
        <a-input v-model:value="employment_history.title" />
      </a-form-item>
      <a-form-item
          :label="$t('startDate')"
          name="start_date"
          :rules="[{ required: true}]"
      >
        <a-date-picker v-model:value="employment_history.start_date" :format="'MMM YYYY'" class="w-100" picker="month" :disabled-date="disabledDateStart" @change="onChangeStartDate" />
<!--        <a-input v-model:value="employment_history.start_date" />-->
      </a-form-item>
      <a-form-item
          :label="$t('endAtDate')"
          name="end_date"
          :rules="[{ required: true }]"
      >
        <a-date-picker v-model:value="employment_history.end_date" :format="'MMM YYYY'" class="w-100" picker="month" :disabled-date="disabledDate" />
<!--        <a-input v-model:value="employment_history.end_date" />-->
      </a-form-item>
      <a-form-item
          :label="$t('workedAt')"
          name="employer_name"
          :rules="[{ required: true}, {max: 255}, {whitespace: true}]"
      >
        <a-input v-model:value="employment_history.employer_name" />
      </a-form-item>

      <a-button html-type="submit" id="submitEmploymentHistoryModal" />
    </a-form>
    <template #footer>
      <div :class="'d-flex ' + (employment_history.id ? 'justify-content-between' : 'justify-content-end')">
        <a-popconfirm
            :title="$t('areSreDeleteEx')"
            :ok-text="$t('yes')"
            :cancel-text="$t('no')"
            @confirm="deleteData"
            v-if="employment_history.id"
        >
          <a-button key="delete" danger>
            {{$t('deleteExperience')}}
          </a-button>
        </a-popconfirm>
        <div>
          <a-button key="back" @click="visible = false">{{ $t('close') }}</a-button>
          <a-button key="submit" type="primary" @click="submitForm" :loading="confirmLoading">{{ $t('save') }}</a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {inject, ref} from 'vue'
import {useMutation} from "@vue/apollo-composable";
import {CreateEmploymentHistoryGql} from "@/gql/create-employment-history-gql";
import {message} from "ant-design-vue";
import dayjs from 'dayjs'
import {UpdateEmploymentHistoryGql} from "@/gql/update-employment-history-gql";
import {DeleteEmploymentHistoryGql} from "@/gql/delete-employment-history-mutation-gql";
import {useI18n} from "vue-i18n";
export default {
  name: "EmploymentHistoryModalComponent",
  setup() {
    const visible = ref(false)
    const title = ref(null)
    const employment_history = ref({
      title: null,
      start_date: null,
      end_date: null,
      employer_name: null,
      profile_id: null,
    });
    const confirmLoading = ref(false)
    const emitter = inject('emitter')
    emitter.on('openEmploymentHistoryModal', (data) => {
      if (data.employment_history) {
        data.employment_history.start_date = dayjs(data.employment_history.start_date)
        data.employment_history.end_date = dayjs(data.employment_history.end_date)
        employment_history.value = data.employment_history;
        title.value = t('editEmploymentHistory');
      } else {
        employment_history.value.profile_id = parseInt(data.profile_id);
        title.value = t('addEmploymentHistory');
      }
      visible.value = true;
    });
    const {mutate: createEmployment} = useMutation(CreateEmploymentHistoryGql);
    const {mutate: updateEmployment} = useMutation(UpdateEmploymentHistoryGql);
    const {mutate: deleteEmployment} = useMutation(DeleteEmploymentHistoryGql);
    const {t} = useI18n()
    const saveData = () => {
      confirmLoading.value = true
      if (employment_history.value.id) {
        updateEmployment(employment_history.value).then(() => {
          message.success(t('expUpdated'))
          emitUpdateProfile()
        })
      } else {
        createEmployment(employment_history.value).then(() => {
          message.success(t('expAdded'))
          emitUpdateProfile()
        });
      }
    }
    const submitForm = () => {
      document.getElementById('submitEmploymentHistoryModal').click()
    }
    const deleteData = () => {
      deleteEmployment({
        id: employment_history.value.id
      }).then(() => {
        message.success(t('expDeleted'));
        emitUpdateProfile()
      })
    }

    const emitUpdateProfile = () => {
      emitter.emit('refetchProfileData')
    }

    const disabledDate = current => {
      return employment_history.value.start_date && dayjs(current).endOf('day') < dayjs(employment_history.value.start_date).add('1', 'M').endOf('day')
    };

    const disabledDateStart = current => {
      return current && current > dayjs().endOf('day')
    };

    const onChangeStartDate = () => {
      if (dayjs(employment_history.value.start_date).endOf('day') > dayjs(employment_history.value.end_date).endOf('day')) {
        employment_history.value.end_date = null
      }
    }

    const resetData = () => {
      employment_history.value = {
        title: null,
        start_date: null,
        end_date: null,
        employer_name: null,
        profile_id: null,
      }
    }
    return {
      employment_history,
      visible,
      title,
      saveData,
      submitForm,
      deleteData,
      confirmLoading,
      disabledDate,
      disabledDateStart,
      onChangeStartDate,
      resetData
    }
  }
}
</script>

<style scoped>

</style>
