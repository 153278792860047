<template>
  <a-drawer
      v-model:visible="visible"
      placement="right"
      @close="closeDrawer"
      :width="width"
  >
    <JobView :job_id="jobId" v-if="jobId" />
  </a-drawer>
</template>

<script>
import {inject, ref} from "vue";
import JobView from "@/views/JobView";

export default {
  name: "ViewJobDrawer",
  components: {JobView},
  setup() {
    let visible = ref(false)
    let jobId = ref(null)
    const emitter = inject('emitter')
    const closeDrawer = () => {
      jobId.value = null
    }
    emitter.on('viewJobDrawer', (id) => {
      jobId.value = id
      visible.value = true
    })
    emitter.on('closeJobDrawer', () => {
      visible.value = false
    })
    const width = window.innerWidth > 900 ? '45%' : '100%'
    return {
      visible,
      jobId,
      closeDrawer,
      width
    }
  }
}
</script>

<style scoped>
/*.my-drawer {*/
/*  width: 50%;*/
/*}*/
/*@media only screen and (max-width: @screen-md) {*/
/*  .my-drawer {*/
/*    width: 70%;*/
/*  }*/
/*}*/
</style>