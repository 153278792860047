import gql from "graphql-tag";

export const GetJobsApplicationsGql = gql`
    query getJobsApplications($id:Int!){
        getJobsApplications(id:$id){
            id
            user {
                id
                name
                profile {
                    profile_slug
                    image
                }
            }
            file {
                file_url
                file_type
            }
        }
    }
`