import gql from "graphql-tag";

export const ResetPasswordMutationGql = gql`
    mutation resetPassword($token: String!, $password:String!){
        resetPassword(token:$token, password: $password) {
            access_token
            token_type
            user {
                id
                name
                email
                profile {
                    id
                    title
                    image
                    profile_slug
                    profile_type
                }
            }
        }
    }
`