const locale = {
    // شريط التنقل
    "findWork": "ابحث عن عمل",
    "feeds": "المنشورات",
    "search": "بحث",
    "profile": "الملف الشخصي",
    "setting": "الاعدادات",
    "logout": "تسجيل خروج",
    "notification": "الإشعارات",

    // الصفحة الرئيسية
    "enterJobTitle": "أدخل عنوان الوظيفة",
    "jobsMightLike": "الوظائف التي قد تعجبك",
    "bestMatches": "وظائف متطابقه لخبراتك",
    "mostRecent": "الأحدث",
    "noData": "عذرًا، لا توجد بيانات لعرضها",
    "subscriptionEndsAt": "موعد انتهاء الاشتراك",
    subscription: "الاشتراك",
    "copyRights": "شركة اطباء الخليج ©",
    "postNewJob": "نشر وظيفة جديدة",
    "viewProfile": "عرض الملف الشخصي",
    "advancedSearch": "بحث متقدم",
    "specialty": "التخصص",
    specialities: "التخصصات",
    "jobLocation": "موقع الوظيفة",
    "reset": "إعادة ضبط",
    "more": "المزيد",

    // عرض الوظائف
    "posted": "نُشرت",
    "viewApplications": "عرض الطلبات",
    "edit": 'تعديل',
    "skillAndExperience": "المهارات والخبرة",
    "report": "الإبلاغ",
    "applyToJob": "التقدم إلى الوظيفة",
    "aboutTheClient": "عن العميل",
    "delete": "حذف",

    // التغذية
    "whatIsInYourMind": "ما يدور في ذهنك؟",
    "public": 'عام',
    "private": 'خاص',
    "addPhoto": "إضافة صورة",
    "addVideo": "إضافة فيديو",
    "post": "نشر",
    "createPost": "إنشاء منشور",
    "updatePost": "تعديل منشور",
    "like": "أعجبني",
    "liked": "تم الاعجاب",
    "comment": "التعليقات",
    "share": "مشاركة",
    "shareLink": "مشاركة الرابط",
    "close": "أغلق",
    "copy": "نسخ",
    "applied": "تم التقدم",
    writeComment: "اكتب تعليقك",


    // الملف الشخصي
    "languages": "اللغات",
    "specialties": "التخصصات",
    "certificates": "الشهادات",
    "provider": "الجهة المصدرة",
    "issued": "صدرت في",
    "loadMore": "تحميل المزيد",
    "employmentHistory": "سجل التوظيف",
    "title": "المسمى الوظيفي",
    "startDate": "تاريخ البدء",
    "endAtDate": "تاريخ النهاية",
    "workedAt": "عمل في",
    "deleteExperience": "حذف الخبرة",
    "save": "حفظ",
    "certName": "اسم الشهادة",
    "issuedAt": "صدرت في",
    "certDoesntExpired": "هذه الشهادة لا تنتهي صلاحيتها",
    "expireAt": "تاريخ انتهاء الصلاحية",
    "certId": "معرف الشهادة",
    pleaseSelectSpeciality: "من فضلك ادخل التخصص",
    certNoExpire: "صلاحيه هذه الشهاده لا تنتهي",

    optional: 'اختياري',
    uploadResume: 'تحميل السيره الذاتيه',
    fieldOfStudy: "مجال الدراسة",
    school: "جهة التعليم",
    degree: "المؤهل الدراسي",
    addEducation: "اضافة مؤهل",
    addEmploymentHistory: "أضف تاريخ التوظيف",
    overview: "نظرة عامة",
    jobOverview: "نظرة عامة على الوظيفة",
    speciality: "التخصص",
    rate: "التقييم",
    review: "المراجعة",

    //Lang
    "basic": "أساسي (مبتدئ)",
    "conversational": "محادثة (متوسط)",
    "proficient": "متقدم (جيد)",
    "fluent": "طلاقة (متحدث أصلي)",
    addNewLanguage: "اضافة لغة جديدة",


    backHome: "العودة الي الصفحة الرئيسية",
    pageNotFound: "الصفحة غير موجودة",
    removeMedia: "حذف الملف",

    //Report
    whyReport: "لماذا تقوم بالتبليغ عن هذا؟",
    "fake": "مشبوه، بريد عشوائي، أو مزيف",
    "harmful": "التحرش أو الكلام البغيض",
    "violence": "العنف أو الأذى الجسدي",
    "sexual": "محتوى جنسي",

    pickupSubscription: "اختر خطة للاشتراك",
    applicationsOf: "الطلبات الخاصه بـ",
    resume: "السيرة الذاتية",

    //Auth
    "email": "البريد الإلكتروني",
    "password": "كلمة المرور",
    "or": "أو",
    "forgotPassword": "نسيت كلمة المرور؟",
    "backToLogin": "العودة إلى صفحة تسجيل الدخول",
    "loginToAccount": "تسجيل الدخول إلى حسابك",
    "joinUs": "انضم إلينا",
    "signIn": "تسجيل الدخول",
    "resetPassword": "إعادة تعيين كلمة المرور",
    "newPassword": "كلمة المرور الجديدة",
    "confirmPassword": "تأكيد كلمة المرور",
    "confirmNewPassword": "تأكيد كلمة المرور الجديدة",
    "resetTokenInvalid": "رمز إعادة تعيين كلمة المرور غير صالح!",
    "thanksForReg": "شكرا على تسجيلك.",
    "currentlyReview": "حاليا، نقوم بمراجعة معلوماتك، وسنرسل لك بريدًا إلكترونيًا لإبلاغك بالموافقة.",
    "thanks": "شكرا",
    "team": "فريق",

    //Setting
    "passAndSec": "كلمة المرور والأمان",
    "changePassword": "تغيير كلمة المرور",
    "oldPassword": "كلمة المرور القديمة",
    "newSubscription": "اشتراك جديد",
    "subscriptionDetails": "تفاصيل الاشتراك",
    "subRefId": "معرف مرجع اشتراك",
    "validTil": "صالح حتى",
    "subscriptionType": "نوع الاشتراك",

    "regNumber": "رقم التسجيل",
    "foundYear": "سنة التأسيس",
    "address": "العنوان",
    "location": "الموقع",
    "website": "الموقع الإلكتروني",
    "phone": "رقم الهاتف",
    "reviews": "التعليقات",
    "loadLess": "تحميل أقل",
    "education": "التعليم",
    "skills": "المهارات",
    "pricing": "الأسعار",
    "contactUs": "اتصل بنا",

    //Site
    weHlp: "نساعدك في العثور على أفضل فرصة عمل",
    weHlpParagraph: "نحن منصة شاملة للأطباء والمهنيين الطبيين للعثور على فرصة العمل المثالية. سواء كنت تبحث عن وظيفة جديدة بالقرب من المنزل أو مغامرة في مكان اخر",
    getStarted: "ابدأ الآن",
    weOffer: "نقدم مجموعة شاملة",
    rangeFeature: "من الميزات",
    jobSearch: "العثور على الوظيفة المثالية",
    jobSearchParagraph: "السماح للأطباء بالبحث في قوائم الوظائف وتصفيتها بناءً على معايير مثل التخصص والموقع ونوع العقد ومستوى الخبرة",
    profileCreation: "إنشاء ملف شخصي مهني",
    profileCreationParagraph: "توفير للأطباء القدرة على إنشاء ملفات تعريف شاملة تسلط الضوء على مؤهلاتهم وخبرتهم وشهاداتهم ومجالات خبرتهم",
    ApplicationAndResumeSubmission: "التقديم للوظائف وإرسال السير الذاتية",
    "ApplicationAndResumeSubmissionParagraph": "سمح للأطباء بالتقدم للوظائف بسهولة عن طريق تقديم سيرتهم الذاتية والمستندات اللازمة",
    "jobAlerts": "تنبيهات الوظائف",
    "jobAlertsParagraph": "أرسل إشعارات إلى الأطباء عند توفر فرص عمل جديدة تتطابق مع تفضيلاتهم ومؤهلاتهم",
    "ratingAndReview": "التقييمات والمراجعات",
    "ratingAndReviewParagraph": "مكن الأطباء من تقييم ومراجعة أصحاب العمل وتجاربهم في العمل معهم، مما يساعد الأطباء الآخرين على اتخاذ قرارات أكثر استنارة",
    "proNetworking": "التواصل المهني",
    "proNetworkingParagraph": "قم ببناء ميزات تسمح للأطباء بالاتصال والتواصل مع المهنيين الآخرين في مجالهم، وتعزيز التعاون وتبادل المعرفة",

    "readyToGetStarted": "هل أنت جاهز لتحقيق النجاح؟",
    "takeControl": "خذ زمام المبادرة في مسيرتك المهنية معنا",

    registerAsPro: "انضم الينا كممارس للمهنه",
    registerAsMed: "انضم الينا كمركز طبي",
    fullName: "الاسم بالكامل",
    next: "التالي",
    emailOrPassNotMatched: "البريد الإلكتروني أو كلمة المرور غير متطابقين",
    emailRequired: 'البريد الإلكتروني مطلوب',
    passRequired: 'كلمة المرور مطلوبة',
    createAccount: 'إنشاء حساب',
    selectLocation: 'الرجاء تحديد موقعك',
    selectSpeciality: 'الرجاء تحديد التخصص',

    profileTitle: "عنوان الصفحه الشخصيه",
    myPostedJobs: 'الوظائف المعلن عنها',
    jobTitle: "عنوان الوظيفه",
    activity: 'الفاعليه',

    editProfileImage: "تعديل الصورة الشخصية",
    changeImage: "اختر صورة جديدة",
    upload: 'تحميل',

    "profileUpdated": "تم تحديث الملف الشخصي بنجاح!",
    "profileImage": "تم تحديث صورة الملف الشخصي بنجاح!",
    "commentAdded": "تمت إضافة التعليق بنجاح!",
    "commentUpdated": "تم تحديث التعليق بنجاح!",
    "requestApplied": "تم تقديم طلبك بنجاح!",
    "certPosted": "تم نشر الشهادة بنجاح!",
    "eduUpdated": "تم تحديث التعليم بنجاح!",
    "eduDeleted": "تم حذف التعليم بنجاح!",
    "eduAdded": "تم إنشاء التعليم بنجاح!",
    "expAdded": "تم إنشاء الخبرة بنجاح!",
    "expUpdated": "تم تحديث الخبرة بنجاح!",
    "expDeleted": "تم حذف الخبرة بنجاح!",
    "jobUpdated": "تم تحديث الوظيفة بنجاح!",
    "jobAdded": "تم إنشاء الوظيفة بنجاح!",
    "langUpdated": "تم تحديث اللغة بنجاح!",
    "copied": "تم النسخ إلى الحافظة!",
    "reportAdded": "تم إرسال التقرير بنجاح!",
    "reviewAdded": "تم حفظ المراجعة بنجاح!",
    "messageSent": "تم إرسال الرسالة بنجاح.",
    "resetPassSent": "تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور بنجاح!",
    "emailNotMatched": "البريد الإلكتروني غير مطابق!",
    "passUpdated": "تم تحديث كلمة المرور بنجاح!",
    "oldPassNotMatched": "كلمة المرور القديمة غير مطابقة.",
    "success": "تمت العملية بنجاح",
    emailIsNotValid: "البريد الإلكتروني غير صالح",

    provideCertificate: 'الرجاء إضافة شهادة موثقة.',
    sendRequest: 'ارسل طلب',
    languagesRequired: 'الرجاء إضافة اللغات.',
    noExpirationDate: 'لا يوجد تاريخ انتهاء',
    areSreDeleteEx: 'هل أنت متأكد من الحذف؟',
    deleteEducation: "حذف",
    yes: "نعم",
    no: 'لا',
    editEmploymentHistory: "تعديل تاريخ التوظيف",

    areSureDeletePost: 'هل أنت متأكد من حذف هذه المنشور؟',
    recoverDeletePost: 'لا يمكنك استعادة هذا المنشور!',

    commentNotValid: 'التعليق ليس صالحا',
    commentMax: 'التعليق  يصل إلى 500 أحرف كحد اقصي',
    update: "تعديل",
    reportReason: "يرجي اختيار سبب الابلاغ",
    subscribeNow: 'اشترك الان',
    annual: "سنويا",
    monthly: "شهريا",

    //Pricing Page
    'variety': 'نحن نقدم مجموعة متنوعة من الخطط لتلبية احتياجاتك',
    'professionals': 'الممارسين للمهنة',
    'medical_centers': 'المراكز الطبية',
    weOfferPrices: 'نحن نقدم مجموعة متنوعة من الخطط لتلبية احتياجاتك',
    'weLoveToHearFromYou': 'نحن نحب أن نسمع منك.',
    message: 'الرساله',
    sendMessage: 'ارسال'

}

export default locale;
