<template>
  <a-drawer
      v-model:visible="visible"
      placement="right"
      :title="$t('notification')"
      @close="closeDrawer"
  >
    <div v-if="loading">
      <!--    <a-skeleton avatar active :paragraph="{ rows: 2 }" />-->
      <!--    <a-skeleton avatar active :paragraph="{ rows: 2 }" />-->
      <!--    <a-skeleton avatar active :paragraph="{ rows: 2 }" />-->
    </div>
    <div v-else>
<!--      <InfiniteScrollComponent @loadMore="loadMore" :loading="loadMoreLoading">-->
        <a-list item-layout="horizontal" :data-source="notifications" :loading="loadMoreLoading" :load-more="loadMore()">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-list-item-meta>
                <template #description>
                  {{ item.notifiable.profile.title }} has posted a new job.
                  <br>
                  <small>{{ moment(item.created_at).fromNow() }}</small>
                </template>
                <template #title>
                  <router-link :to="{
                    name: 'job-view',
                    params: {
                      id: item.notifiable.id
                    }
                  }" @click="visible = false">{{ item.data.platform_job.title.substring(0, 20) }}</router-link>
                </template>
                <template #avatar>
                  <a-avatar :src="item.notifiable.profile.image" />
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
<!--      </InfiniteScrollComponent>-->
    </div>
  </a-drawer>
</template>

<script>
import {inject, ref} from "vue";
import {useLazyQuery, useQuery} from "@vue/apollo-composable";
import {GetNotificationsGql} from "@/gql/get-notifications-query-gql";
// import InfiniteScrollComponent from "@/components/InfiniteScrollComponent";

export default {
  name: "NotificationsDrawer",
  components: {
    // InfiniteScrollComponent
  },
  setup() {
    let visible = ref(false)
    const emitter = inject('emitter')
    const moment = inject('moment')
    const page = ref(1)
    const last_page = ref(1)
    const limit = ref(10)
    const notifications = ref([]);
    const closeDrawer = () => {
      //
    }
    const {result, loading, onResult, refetch} = useQuery(GetNotificationsGql, {
      page: page.value,
      limit: limit.value
    });

    const {loading:loadMoreLoading, onResult: onLoadMoreResult, load} = useLazyQuery(GetNotificationsGql, {
      page: page.value,
      limit: limit.value
    })

    onResult((value) => {
      notifications.value = [...value.data.notifications.data]
      last_page.value = value.data.notifications.last_page
    })

    onLoadMoreResult((value) => {
      // value.data.notifications.data.map(x => {
      //   notifications.value.push(Object.assign({}, x, {selected:false}))
      // })
      notifications.value.push(...value.data.notifications.data)
      last_page.value = value.data.notifications.last_page
    })
    const loadMore = () => {
      if(last_page.value !== page.value && notifications.value.length > 0) {
        if (loadMoreLoading.value == false) {
          page.value++
          load();
        }
      }
    }

    emitter.on('openNotificationDrawer', () => {
      visible.value = true
    })
    emitter.on('closeNotificationDrawer', () => {
      visible.value = false
    })
    emitter.on('refetchNotificationsData', () => {
      refetch()
    })
    return {
      visible,
      closeDrawer,
      result,
      loading,
      moment,
      loadMore,
      loadMoreLoading,
      notifications
    }
  }
}
</script>

<style scoped>

</style>
