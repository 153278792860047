<template>
<div>
  <a-form layout="vertical"
          :model="signup"
          @finish="submitForm"
          name="basic"
          autocomplete="off">
    <a-form-item :label="$t('fullName')" name="name" :rules="[{required: true},{max: 255}, { whitespace: true }]" >
      <a-input v-model:value="signup.name" />
    </a-form-item>
    <a-form-item :label="$t('email')" name="email" :rules="[{required: true}, { type: 'email' , message: $t('emailIsNotValid')}]" >
      <a-input type="email" v-model:value="signup.email" />
<!--      <small class="text-danger">Your email is exists</small>-->
    </a-form-item>
    <a-form-item :label="$t('password')" name="password" :rules="[{required: true}, {max: 255}, {min: 8}, { whitespace: true }]" >
      <a-input-password v-model:value="signup.password" />
    </a-form-item>
    <a-form-item :label="$t('confirmPassword')" name="confirmation_password" :rules="[{required: true},{max: 255}, { whitespace: true }]">
      <a-input-password v-model:value="signup.confirmation_password"  />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" :disabled="disabled" :loading="loading" block class="fw-bold" html-type="submit">{{ $t('next') }} (1 of 3)</a-button>
    </a-form-item>
  </a-form>
</div>
</template>

<script>
import {computed, reactive} from "vue";
import {SignupGql} from "@/gql/signup-user-gql";
import {useMutation} from '@vue/apollo-composable'
import {useStore} from "vuex";
import {useRouter} from "vue-router";
// import { logErrorMessages } from '@vue/apollo-util'

export default {
  name: "SignupProfessionalView",
  setup() {
    const route = useRouter();
    const store = useStore()
    const signup = reactive({
      name: '',
      email: '',
      password: '',
      confirmation_password: ''
    })

    const validationFieldStatus = reactive({
      name: {
        hasError: false,
        errorMessage: null
      },
      email: {
        hasError: false,
        errorMessage: null
      },
      password: {
        hasError: false,
        errorMessage: null
      }

    })
    const disabled = computed(() => {
      return !(signup.name && signup.email && signup.password && (signup.password == signup.confirmation_password));
    });

    const {mutate: signupUserMutation, loading, onError, onDone} = useMutation(SignupGql, {
      clientId: 'auth'
    });

    onError(error => {
      let graphQLErrors = error.graphQLErrors[0]
      if (graphQLErrors.message == 'validation') {
        let validations = graphQLErrors.extensions.validation;
        for(let validation in validations) {
          validationFieldStatus[validation] = {hasError: true, errorMessage: validations[validation][0]}
          // validations[validation]
        }
      }
    })

    onDone(res => {
      localStorage.setItem('authed_user', JSON.stringify(res.data.signup))
      store.commit('setAuthedUser', res.data.signup)
      route.push({
        name: 'signup_profile_complete_user'
      });
    })

    const submitForm = () => {
      Object.assign(validationFieldStatus, {
        name: {
          hasError: false,
          errorMessage: null
        },
        email: {
          hasError: false,
          errorMessage: null
        },
        password: {
          hasError: false,
          errorMessage: null
        }
      });
      signupUserMutation({
        name: signup.name,
        email: signup.email,
        password: signup.password,
        profile_type: 'user',
      })
    }

    return {
      disabled,
      signup,
      signupUserMutation,
      submitForm,
      validationFieldStatus,
      loading
    }
  },
}
</script>

<style scoped>

</style>
