import gql from "graphql-tag";

export const SignupGql = gql`
    mutation signup($email:String!, $name:String!, $password:String!,$profile_type:String) {
        signup(email:$email, name:$name, password:$password,profile_type:$profile_type) {
            access_token
            token_type
            user {
                id
                name
                email
                profile {
                    id
                    title
                    image
                    profile_slug
                    profile_type
                    is_approved
                }
            }
        }
    }
`