<template>
<!--  <div class="job-component pt-4 bg-white p-md-3 p-xs-2 border-bottom cursor-pointer rounded-3">-->
    <div>
      <a-card>
        <a-row :gutter="5">
          <a-col :span="3">
            <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-if="propsPost.user.profile.image" :src="propsPost.user.profile.image" />
            <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-else>
              <template #icon>
                <UserOutlined />
              </template>
            </a-avatar>
          </a-col>
          <a-col :span="21">
            <a-row :justify="'space-between'">
              <a-col>
                <div class="fw-bold">{{ propsPost.user.name }}</div>
                <a-space>
                  <small class="text-muted">{{ $t('posted') }} {{ moment(propsPost.created_at).fromNow() }}</small>
                  <small class="text-muted">-</small>
                  <small class="text-muted">
                    <LockOutlined v-if="propsPost.privacy == 'private'" />
                    <GlobalOutlined v-if="propsPost.privacy == 'public'" />
                    {{ propsPost.privacy == 'public' ? $t('public') : $t('private') }}
                  </small>
                </a-space>
              </a-col>
              <a-col>
                <a-dropdown :trigger="['click']">
                  <span class="cursor-pointer">
                    <MoreOutlined />
                  </span>
                  <template #overlay>
                      <a-menu v-if="authedUser.user.id == propsPost.user.id">
                        <a-menu-item key="0" @click.prevent="editPost">
                          <EditOutlined />
                          {{ $t('edit') }}
                        </a-menu-item>
                        <a-menu-item key="1" @click.prevent="deletePost" class="text-danger">
                          <DeleteOutlined />
                          {{ $t('delete') }}
                        </a-menu-item>
                      </a-menu>
                      <a-menu v-else>
                        <a-menu-item key="2" @click="openReportDrawer">
                          <!--                      <a-space>-->
                          <FlagOutlined />
                          {{ $t('report') }}
                          <!--                      </a-space>-->
                        </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </a-col>
            </a-row>
            <a-typography-paragraph
                class="my-3"
                :ellipsis="ellipsis ? { rows: 3, expandable: true, symbol: 'more' } : false"
                :content="propsPost.post"
            />
          </a-col>
          <a-col :span="24" v-if="propsPost.file">
            <div class="d-flex text-center w-100 justify-content-center mt-3">
              <img :src="propsPost.file.file_url"  alt="photo" style="max-height: 50rem; max-width: 100%" v-if="propsPost.file.file_type == 'image'">
              <!--            <VideoPlayerComponent :src="propsPost.file.file_url" class="w-100" v-else />-->
              <video controls class="w-100" id="video" preload="metadata" style="max-height: 30rem" v-else>
                <source :src="propsPost.file.file_url" type="video/mp4" />
              </video>
            </div>
          </a-col>
        </a-row>

        <template #actions>
          <span :class="propsPost.is_liked ? 'text-primary' : ''" @click="likeOrUnlikePost">
            <LikeOutlined key="like" class="px-1" />
<!--            Like (24)-->
            <span>
              {{ propsPost.is_liked ? $t('liked') : $t('like') }}
            </span>
          </span>
          <span>
            <router-link class="text-decoration-none" :to="{name: 'view-post', params: {
              id: propsPost.id
            }}">
              <CommentOutlined key="comment" />
<!--            Comment (233)-->
            {{ $t('comment') }}
            </router-link>
          </span>
          <span @click="copyModalVisible = true">
            <ShareAltOutlined key="share" />
            {{ $t('share') }}
          </span>
        </template>
      </a-card>

      <!-- Copy Modal -->
      <a-modal v-model:visible="copyModalVisible" :closable="false" :title="$t('shareLink')">
        <template #footer>
          <a-button type="default" @click="copyModalVisible = false">{{ $t('close') }}</a-button>
        </template>

        <a-input v-model:value="post_url" readonly>
          <template #suffix>
            <a-tooltip :title="$t('copy')">
              <a-button @click="copyUrl">
                <template #icon><CopyOutlined /></template>
              </a-button>
            </a-tooltip>
          </template>
        </a-input>
      </a-modal>
    </div>
<!--  </div>-->
</template>

<script>
import {createVNode, inject, ref} from "vue";
import {
    LikeOutlined,
    CommentOutlined,
    UserOutlined,
    ShareAltOutlined,
    LockOutlined,
    GlobalOutlined,
    MoreOutlined,
    EditOutlined,
    FlagOutlined,
    CopyOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons-vue";
import {useMutation} from "@vue/apollo-composable";
import {LikeUnlikePostMutationGql} from '@/gql/like-unlike-post-mutation-gql'
import {useStore} from "vuex";
import {message, Modal} from "ant-design-vue";
import {DeletePostMutationGql} from "@/gql/delete-post-mutation-gql";
import {useI18n} from "vue-i18n";
// import VideoPlayerComponent from "@/components/VideoComponent.vue";

export default {
  props: {
    post: {
      required: true,
      type: Object
    }
  },
  name: "PostComponent",
  components: {
    // VideoPlayerComponent,
    LockOutlined,
    GlobalOutlined,
    LikeOutlined,
    CommentOutlined,
    UserOutlined,
    ShareAltOutlined,
    MoreOutlined,
    EditOutlined,
    FlagOutlined,
    CopyOutlined,
    DeleteOutlined
  },
  setup(props) {
    const store = useStore()
    const moment = inject('moment');
    const ellipsis = ref(true);
    const propsPost = ref(JSON.parse(JSON.stringify(props.post)));
    const authedUser = store.state.authedUser;
    const emitter = inject('emitter');
    const copyModalVisible = ref(false);
    const post_url = ref(process.env.VUE_APP_BASE_URL + '/in/view-post/' + propsPost.value.id);

    const {mutate: LikeOrUnlikeMutation, loading: likeOrUnlikeMutationLoading} = useMutation(LikeUnlikePostMutationGql);
    const {mutate: DeletePostMutate} = useMutation(DeletePostMutationGql);
    const likeOrUnlikePost = () => {
      propsPost.value.is_liked = !propsPost.value.is_liked
      LikeOrUnlikeMutation({
        post_id: props.post.id,
        is_liked: !props.post.is_liked,
      }).then(() => {
        //
      })
    }

    const editPost = () => {
      emitter.emit('openPostModal', propsPost.value);
    }
    const {t} = useI18n()
    const copyUrl = () => {
      navigator.clipboard.writeText(post_url.value).then(function() {
        message.success(t('copied'))
      });
    }

    const openReportDrawer = () => {
      emitter.emit('openReportDrawer', {id: propsPost.value.id, type: 'post'});
    }

    const deletePost = () => {
      Modal.confirm({
        title: t('areSreDeleteEx'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('recoverDeletePost'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          return new Promise((resolve, reject) => {
            DeletePostMutate({
              id: propsPost.value.id
            }).then(() => {
              emitter.emit('updatePostsData')
              resolve();
            }).catch(() => reject())
          })
        },
        onCancel() {
          //
        },
      });
    }
    return {
      moment,
      ellipsis,
      likeOrUnlikePost,
      likeOrUnlikeMutationLoading,
      propsPost,
      authedUser,
      editPost,
      copyModalVisible,
      post_url,
      copyUrl,
      openReportDrawer,
      deletePost
    }
  }
}
</script>

<style scoped>

</style>
