<template>
  <div>
    <a-row>
      <a-col :span="24">
        <a-input-search
            v-model:value="searchJobTitle"
            :placeholder="$t('enterJobTitle')"
            enter-button
            size="large"
            @search="onSearch"
        />
      </a-col>
      <a-col :span="24">
        <div class="container-fluid bg-white p-4 mt-3 rounded-3">
          <a-typography-title :level="3">{{ $t("jobsMightLike") }}</a-typography-title>
          <a-tabs v-model:activeKey="activeTabKey">
            <a-tab-pane key="1" :tab="$t('bestMatches')">
              <HomeJobsListView :query-type="'matched'"/>
            </a-tab-pane>
            <a-tab-pane key="2" :tab="$t('mostRecent')">
              <HomeJobsListView :query-type="'recent'"/>
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import HomeJobsListView from "@/views/HomeViews/HomeJobsListView";
export default {
  name: "HomeJobsView",
  components: {
    HomeJobsListView
  },

  data() {
    return {
      searchJobTitle: '',
      activeTabKey: '1'
    }
  },
  methods: {
    onSearch(value) {
      this.$router.push({
        name: 'job-search',
        query: {
          title: value
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
