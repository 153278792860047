import {
  createRouter,
  createWebHistory,
  // createWebHashHistory
} from 'vue-router'
import LoginPage from "@/pages/auth/LoginPage";
import SignupPage from "@/pages/auth/SignupPage";
import SignupProfileType from "@/views/signup/SignupProfileTypeView";
import SignupProfissionalView from "@/views/signup/profissional/SignupProfissionalView";
import SignupProfessionalCompleteProfile from "@/views/signup/profissional/SignupProfessionalCompleteProfile";
import FindWorkPage from "@/pages/FindWorkPage";
import MasterTemplate from "@/templates/master.template";
import JobsSearchPage from "@/pages/JobsSearchPage";
import ProfilePage from "@/pages/ProfilePage";
import getAuthData from "@/global functions/handel-auth-data";
import store from '../store/index'
import ClientDashboardPage from "@/pages/ClientDashboardPage";
import SignupProfessionalCertificates from "@/views/signup/profissional/SignupProfessionalCertificates";
import SignupPendingRequest from "@/pages/auth/SignupPendingRequest";
import EditProfilePage from "@/pages/EditProfilePage";
import ViewJobPage from "@/pages/ViewJobPage";
import SettingsPage from '@/pages/settings/index'
import AccountPreferencesPage from "@/pages/settings/AccountPreferencesPage";
import SubscriptionPage from "@/pages/settings/SubscriptionPage";
import PasswordSettingPage from "@/pages/settings/PasswordSettingPage";
import ForgotPasswordPage from "@/pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "@/pages/auth/ResetPasswordPage";
import SignupMedicalView from "@/views/signup/medical_center/SignupMedicalView";
import SignupMedicalCompleteProfile from "@/views/signup/medical_center/SignupMedicalCompleteProfile";
import SubscriptionView from "@/views/signup/SubscriptionView";
import Home_page from "@/pages/home_page";
import Pricing_page from "@/pages/pricing_page";
import FeedsPage from "@/pages/feeds.vue";
import ViewPostPage from "@/pages/posts/view.vue"
import ContactusPage from "@/pages/ContactusPage.vue";
import subscriptionsApolloClient from '@/ApolloClient/SubscriptionsApolloClient';
import { UserQuery } from '@/gql/user-query-gql';

const routes = [
  {
    path: '/',
    name: 'website_home',
    component: Home_page,
  },
  {
    path: '/contact-us',
    name: 'website_contact_us',
    component: ContactusPage,
  },
  {
    path: '/pricing',
    name: 'website_pricing',
    component: Pricing_page,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { title: 'Login' },
    beforeEnter: (to, from, next) => checkIfGuest(to, from, next),
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: ForgotPasswordPage,
    meta: { title: 'Forgot Password' },
    beforeEnter: (to, from, next) => checkIfGuest(to, from, next),
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPasswordPage,
    meta: { title: 'Reset Password' },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupPage,
    meta: { title: 'Sign Up' },
    children: [
      {
        path: '',
        name: 'signup_profile_type',
        component: SignupProfileType,
        beforeEnter: (to, from, next) => checkIfGuest(to, from, next),
      },
      {
        path: 'medical-center',
        name: 'signup_medical_center',
        component: SignupMedicalView,
        beforeEnter: (to, from, next) => checkIfGuest(to, from, next),
      },
      {
        path: 'medical-center/complete-profile',
        name: 'signup_medical_center_complete_profile',
        component: SignupMedicalCompleteProfile,
      },
      {
        path: 'professional/user',
        name: 'signup_profile_user',
        component: SignupProfissionalView
      },
      {
        path: 'professional/complete-profile',
        name: 'signup_profile_complete_user',
        component: SignupProfessionalCompleteProfile,
        beforeEnter: (to, from, next) => checkIfUserAuthed(to, from, next),
      },
      {
        path: 'professional/certificates',
        name: 'signup_profile_certificates',
        component: SignupProfessionalCertificates,
        beforeEnter: (to, from, next) => checkIfUserAuthed(to, from, next),
      },
      {
        path: 'pending-approval',
        name: 'pending-approval',
        component: SignupPendingRequest,
        beforeEnter: (to, from, next) => checkIfUserAuthed(to, from, next),
      },
      {
        path: 'subscription',
        name: 'subscription-page',
        component: SubscriptionView,
        beforeEnter: (to, from, next) => checkIfUserAuthed(to, from, next),
      },
    ]
  },
  {
    path: '/in',
    name: 'platform',
    component: MasterTemplate,
    beforeEnter: (to, from, next) => checkIfUserAuthed(to, from, next),
    meta: { title: 'platform' },
    children: [
      {
        path: 'feeds',
        name: 'feeds',
        meta: { title: 'Feeds' },
        component: FeedsPage,
      },
      {
        path: 'view-post/:id',
        name: 'view-post',
        meta: { title: 'View Post' },
        component: ViewPostPage,
      },
      {
        path: 'find-work',
        name: 'find-work',
        meta: { title: 'Find Work' },
        component: FindWorkPage,
        beforeEnter: (to, from, next) => checkIfUser(to, from, next),
      },
      {
        path: 'client/dashboard',
        name: 'client-dashboard',
        component: ClientDashboardPage,
        beforeEnter: (to, from, next) => checkIfClient(to, from, next),
      },
      {
        path: 'jobs/search',
        name: 'job-search',
        component: JobsSearchPage,
        meta: { title: 'Search' },
        beforeEnter: (to, from, next) => checkIfUser(to, from, next),
      },
      {
        path: '/profile/:slug',
        name: 'profile-page',
        component: ProfilePage,
      },
      {
        path: '/profile/edit',
        name: 'edit-profile',
        component: EditProfilePage
      },
      {
        path: 'job/view/:id',
        name: 'job-view',
        component: ViewJobPage
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsPage,
        children: [
          {
            name: 'account-preferences',
            path: 'account-preferences',
            component: AccountPreferencesPage
          },
          {
            name: 'subscription',
            path: 'subscription',
            component: SubscriptionPage
          },
          {
            name: 'password-and-security',
            path: 'password-and-security',
            component: PasswordSettingPage
          },
        ]
      },
    ]
  }
]
/*eslint-disable */
function checkIfUserAuthed(to, from, next) {
  const authedData = getAuthData()
  if (authedData) {
    store.state.authedUser = authedData
    checkIfProfileCompleted(authedData, next, to)
    next()
  } else {
    next({name: 'login'})
  }
}

async function checkIfProfileCompleted (authedData, next, to) {
  console.log('authedData here', authedData);
  subscriptionsApolloClient.query({
    query: UserQuery,
    context: {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${authedData.access_token}`
      }
    }
  }).then(result => {
    if (result.data.user.profile.profile_type == 'user') {
      if (result.data.user.profile.registration_stage == 'certificates') {
        next({name: 'signup_profile_certificates'})
      }
    } else if (result.data.user.profile.profile_type == 'medical_center' && result.data.user.profile.registration_stage != 'pending') {
        next({name: 'signup_medical_center_complete_profile'})
    } else if (result.data.user.profile.registration_stage == 'pending' && !authedData.user.profile.is_approved && to.name != 'pending-approval') {
      next({name: 'pending-approval'})
    }
    next()
  })
  // const {result, onResult} = useQuery(WhoAmI)
  // onResult((result) => {
  //   console.log('result', result);
  // })
}
function checkIfGuest(to, from, next) {
  const authedData = getAuthData()
  if (!authedData) {
    next()
  } else {
    store.state.authedUser = authedData
    next({name: 'platform'})
  }
}

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes
})

const checkIfClient = (to, from, next) => {
  const authedData = getAuthData()
  if (authedData.user.profile.profile_type == 'medical_center') {
    next();
  } else {
    next({name: 'platform'});
  }
}

const checkIfUser = async (to, from, next) => {
  const authedData = getAuthData()
  if (authedData.user.profile.profile_type == 'user') {
    // await checkIfProfileCompleted(authedData, next)
    if (!authedData.user.profile.is_approved) {
      if (to.name != 'pending-approval') {
        next({name: 'pending-approval'})
      }
    }
    next()
  } else {
    next({name: 'platform'})
  }
}

const DEFAULT_TITLE = 'DoctorsGulf';
router.afterEach((to, from) => {
  document.title = to.meta.title ? `DoctorsGulf | ${to.meta.title}` : DEFAULT_TITLE;
});

export default router
