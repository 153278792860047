<template>
<div class="container mt-4">
  <a-row>
    <a-col :span="16" :offset="4">
      <a-card>
        <JobView :job_id="jobID" />
      </a-card>
    </a-col>
  </a-row>
</div>
</template>

<script>
import {useRoute} from "vue-router";
import JobView from "@/views/JobView";

export default {
  name: "ViewJobPage",
  components: {JobView},
  setup() {
    const route = useRoute();
    const jobID = parseInt(route.params.id)
    return {
      jobID
    }
  }

}
</script>

<style scoped>

</style>