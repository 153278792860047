<template>
  <div class="mt-3 mb-3 pb-3">
    <div>
      <small class="fw-bolder">
        <a-typography-text>{{ employment_history.title }} | {{ employment_history.employer_name }}</a-typography-text>
        <a-button type="link" shape="circle" @click="openEmploymentHistoryModal" v-if="editable">
          <template #icon><EditOutlined /></template>
        </a-button>
      </small>
    </div>
    <div>
      <small class="text-muted">
        {{ moment(employment_history.start_date).format('MMM YYYY') }} - {{employment_history.end_date ? moment(employment_history.end_date).format('MMM YYYY') : 'To Present'}}
      </small>
    </div>
  </div>
</template>

<script>
import {EditOutlined} from "@ant-design/icons-vue";
import {inject} from "vue";

export default {
  name: "EmploymentHistoryComponent",
  props: {
    employment_history: {
      required: true,
      type: Object
    },
    editable: {
      required: true,
      type: Boolean
    }
  },
  components: {
    EditOutlined
  },
  setup(props) {
    const moment = inject('moment')
    const emitter = inject('emitter')
    const openEmploymentHistoryModal = () => {
      emitter.emit('openEmploymentHistoryModal', {
        employment_history: JSON.parse(JSON.stringify(props.employment_history))
      });
    }
    return {
      openEmploymentHistoryModal,
      moment,
    }
  }
}
</script>

<style scoped>

</style>