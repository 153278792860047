import gql from "graphql-tag";

export const UpdateJobMutationGql = gql`
    mutation updateJob(
        $id:Int!, 
        $title:String!, 
        $overview:String!, 
        $location:String!, 
        $specialty_id:Int!, 
        $is_active:Boolean!
    ){
        updateJob(
            id:$id,
            title:$title,
            overview:$overview,
            location:$location,
            specialty_id:$specialty_id, 
            is_active:$is_active
        ){
            id
        }
    }
`