<template>
<div ref="scrollComponent">
  <slot />
  <div class="text-center my-3" v-if="loading">
    <a-spin :indicator="indicator" />
  </div>
</div>
</template>

<script>
import {onMounted, onUnmounted, ref, h} from "vue";
import {LoadingOutlined} from "@ant-design/icons-vue";

export default {
  name: "InfiniteScrollComponent",
  props: {
    loading: {
      required: true,
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const scrollComponent = ref(null)
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px',
      },
      spin: true,
    });
    onMounted(() => {
      window.addEventListener("scroll", handleScroll)
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })
    const handleScroll = () => {
      let element = scrollComponent.value;
      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        emit('loadMore')
      }
    }

    return {
      scrollComponent,
      indicator
    }
  }
}
</script>

<style scoped>

</style>