<template>
  <div>
    <div v-if="loading">
      <a-skeleton avatar active :paragraph="{ rows: 4 }" class="border-bottom pb-3 pb-3 mb-3 mt-3" />
      <a-skeleton avatar active :paragraph="{ rows: 4 }" />
    </div>
    <div v-else-if="!loading && posts.length > 0">
      <a-row :gutter="[10, 10]">
        <a-col :span="24" v-for="(post, index) in posts" :key="index">
  <!--      <InfiniteScrollComponent @loadMore="loadMoreJobs" :loading="loadMoreLoading">-->
          <PostComponent  :post="post" />
  <!--      </InfiniteScrollComponent>-->
        </a-col>
      </a-row>
    </div>
    <a-empty
        :image="require('@/assets/images/undraw_empty.svg')"
        :image-style="{height: '200px' }"
        class="mt-3"
        v-else
    >
      <template #description>
          <span class="text-muted pt-3">
            {{ $t('noData') }}
          </span>
      </template>
    </a-empty>
  </div>
</template>

<script>
import PostComponent from "@/components/PostComponent.vue";
import {GetPostsGql} from "@/gql/get-posts-gql";
import {useQuery} from "@vue/apollo-composable";
import {ref, inject} from 'vue'
export default {
  name: "PostsList",
  components: {PostComponent},
  setup() {
    const posts = ref([]);
    const emitter = inject('emitter');
    const {result, loading, onResult, refetch} = useQuery(GetPostsGql, {
      page: 1,
      limit: 50
    }, {
      fetchPolicy: 'no-cache'
    })

    onResult(() => {
      posts.value = result.value.posts.data
    })

    emitter.on('updatePostsData', () => {
      refetch()
    })

    return {
      result,
      loading,
      posts
    }
  }
}
</script>

<style scoped>

</style>
