<template>
<!--  <div class="p-md-4 p-sm-2 mt-3 border-bottom border-0 cursor-pointer">-->
<!--    <div >-->
<!--      <a-row>-->
<!--        <a-col :span="2">-->
<!--          <a-avatar :size="64" :src="review.user.profile.image" />-->
<!--        </a-col>-->
<!--        <a-col :span="22">-->
<!--          <div class="fw-bold">{{ review.user.name }}</div>-->
<!--          <small class="text-muted">-->
<!--&lt;!&ndash;            <star-rating :read-only="true" :star-size="15" :rating="3" :show-rating="false"></star-rating>&ndash;&gt;-->
<!--          </small>-->
<!--          <p class="pt-2">-->
<!--            {{ review.review }}-->
<!--          </p>-->
<!--          <div class="mt-2">-->
<!--            <small>{{ moment(review.created_at).fromNow() }}</small>-->
<!--            &lt;!&ndash;            - &ndash;&gt;-->
<!--            &lt;!&ndash;            <img src="@/assets/icons/location.svg" width="14" alt="location">&ndash;&gt;-->
<!--            &lt;!&ndash;            <small> Egypt</small>&ndash;&gt;-->
<!--          </div>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </div>-->
<!--  </div>-->

  <div class="pt-4 bg-white  border-bottom cursor-pointer rounded-3">
    <div>
      <a-row :gutter="5">
        <a-col :xs="{span: 3}" :sm="{span: 3}" :lg="{span: 2}">
          <a-avatar :size="{md: 50, lg: 50, xl: 50, xxl: 50}" :src="review.user.profile.image" v-if="review.user.profile.image" />
          <a-avatar shape="square" :size="{md: 50, lg: 50, xl: 50, xxl: 50}" v-else>
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </a-col>
        <a-col :xs="{span: 21}" :sm="{span: 21}" :lg="{span: 22}">
          <div class="fw-bold">{{ review.user.name }}</div>
<!--          <small class="text-muted">-->
<!--            {{ job.profile.title }}-->
<!--          </small>-->
          <!--          <p class="pt-2">-->
          <!--            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.-->
          <!--          </p>-->
          <!--          <div>-->
          <!--            <span class="badge text-bg-primary me-2" v-for="(skill, index) in skills" :key="index">-->
          <!--              {{ skill }}-->
          <!--            </span>-->
          <!--          </div>-->
          <a-typography-paragraph
              :ellipsis="ellipsis ? { rows: 2, expandable: true, symbol: $t('more') } : false"
              :content="review.review"
          />
          <div class="mt-2">
            <small>Posted {{ moment(review.created_at).fromNow() }}</small>
<!--            - -->
<!--            <img src="@/assets/icons/location.svg" width="14" alt="location">-->
<!--            <small> {{ job.location }}</small>-->
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue";
export default {
  name: "ReviewComponent",
  components: {
    //
  },
  props: {
    review: {
      required: true,
      type: Object
    }
  },
  setup() {
    const moment = inject('moment');
    const ellipsis = ref(true);
    return {
      moment,
      ellipsis
    }
  }
}
</script>

<style scoped>

</style>
