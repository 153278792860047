<template>
  <a-row class="vh-100">
    <a-col :xs="{ span: 24 }" :lg="{ span: 8, offset: 8 }" class="my-auto">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <div class="text-center mb-5">
            <img src="@/assets/images/dr.png" width="200" alt="doctor-in">
<!--            <h4 class="mt-2">Login into your account</h4>-->
          </div>
        </div>
      </div>
      <div class="p-4 pt-2">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "SignupPage"
}
</script>

<style scoped>

</style>