import gql from "graphql-tag";

export const CreateCommentMutationGql = gql`
    mutation createComment($comment:String!, $post_id: Int!) {
        createComment(comment: $comment, post_id: $post_id) {
            id
            comment
            user {
                id
                name
                email
                profile{
                    id
                    title
                    image
                }
            }
            created_at
        }
    }
`