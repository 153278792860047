import gql from "graphql-tag";

export const LoginGql = gql`
    mutation login($email: String!, $password: String!){
        login(email:$email, password:$password){
            access_token
            token_type
            user {
                id
                name
                email
                profile {
                    id
                    title
                    image
                    profile_slug
                    profile_type
                    is_approved
                }
            }
        }
    }
`