<template>
  <div v-if="!loading">
    <ClientProfileView v-if="result.profile.profile_type == 'medical_center'" :profile="result.profile" />
    <ProfessionalProfileView v-else :profile="result.profile" />
    <EditProfileImageModalComponent />
  </div>
</template>

<script>
import ProfessionalProfileView from "@/views/ProfilesViews/ProfessionalProfileView";
import ClientProfileView from "@/views/ProfilesViews/ClientProfileView";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useQuery} from "@vue/apollo-composable";
import {ViewProfileGql} from "@/gql/view-profile-gql";
import {inject} from "vue";
import EditProfileImageModalComponent from "@/components/EditProfileImageModalComponent";
export default {
  name: "ProfilePage",
  components: {
    EditProfileImageModalComponent,
    ProfessionalProfileView,
    ClientProfileView
  },
  setup() {
    const emitter = inject('emitter')
    const store = useStore()
    const route = useRoute()
    const {result, loading, refetch} = useQuery(ViewProfileGql, {
      slug: route.params.slug
    }, {
      fetchPolicy: 'no-cache'
    })
    emitter.on('refetchProfileData', () => {
      refetch()
    })
    return {
      store,
      result,
      loading
    }
  }
}
</script>

<style>
.profile-avatar-edit {
  width: 100%;
  height: 100%;
  /*background-color: black;*/
  top: 0;
  border-radius: 50%;
  opacity: 0;
}
.profile-avatar-edit:hover {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: 0.4s;
}
</style>