import gql from "graphql-tag";

const GetUserSubscriptionGql = gql`
    query {
      user_subscription{
        id
        order_reference_id
        type
        status
        expire_at
      }
    }
`

export default GetUserSubscriptionGql;
