<template>
<div>
  <div class="container-fluid p-lg-4">
    <a-skeleton avatar :paragraph="{ rows: 4 }" v-if="loading" />
    <a-row v-else>
      <a-col :span="24">
        <a-space direction="vertical" class="w-100" :size="1">
          <a-row :justify="'space-between'">
            <a-col>
              <h3 class="fw-bold">{{ result.job.title }}</h3>
            </a-col>

            <a-col>
              <a-space>
                <div v-if="authUser.user.profile.profile_type == 'user'">
                  <a-button type="primary" @click="openApplyJobModal" v-if="authUser.user.id != result.job.id" :disabled="result.job.is_applied">
                    {{ !result.job.is_applied ? $t('applyToJob') : $t('applied') }}
                  </a-button>
                </div>
                <div v-if="authUser.user.profile.profile_type == 'medical_center'">
                  <a-button type="primary" @click="openViewApplicationModal">
                    {{ $t('viewApplications') }}
                  </a-button>
                </div>
                <a-dropdown :trigger="['click']" placement="bottomRight">
                  <!--                <a class="ant-dropdown-link" @click.prevent>-->
                  <MoreOutlined class="fs-5" />
                  <!--                </a>-->
                  <template #overlay>
                    <a-menu v-if="store.state.authedUser.user.id == result.job.profile.user.id">
                      <a-menu-item key="0" @click.prevent="editJob">
                        <EditOutlined />
                        {{ $t("edit") }}
                      </a-menu-item>
                    </a-menu>
                    <a-menu v-else>
                      <a-menu-item key="0" @click.prevent="copyModalVisible = true">
                        <!--                      <a-space>-->
                        <ShareAltOutlined />
                        {{$t("share")}}
                        <!--                      </a-space>-->
                      </a-menu-item>
                      <a-menu-item key="2" @click="openReportDrawer">
                        <!--                      <a-space>-->
                        <FlagOutlined />
                        {{ $t('report') }}
                        <!--                      </a-space>-->
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </a-space>
            </a-col>

<!--            <a href="#" class="btn btn-link" @click.prevent="copyModalVisible = true">-->
<!--              <img src="@/assets/icons/share-android.svg" width="28" alt="more-options">-->
<!--&lt;!&ndash;              <CopyOutlined style="font-size: 20px" />&ndash;&gt;-->
<!--            </a>-->
          </a-row>
          <span class="text-muted">
          {{ result.job.specialty.name }}
          </span>
          <div>
            <small class="text-muted">
              {{ $t('posted') }} {{ diffForHumans(result.job.created_at) }} -
            </small>
<!--            <img src="@/assets/icons/location.svg" width="14" alt="location">-->
            <small><EnvironmentOutlined class="text-primary" /> {{ result.job.location }}</small>
          </div>
        </a-space>
      </a-col>
      <a-col :span="24" class="pt-4">
        <a-typography-paragraph
            :ellipsis="ellipsis ? { rows: 5, expandable: true, symbol: 'more' } : false"
            :content="result.job.overview"
        />
      </a-col>
      <a-col :span="24">
        <a-divider />
      </a-col>
      <a-col :span="24">
        <h4 class="fw-bold">{{ $t('skillAndExperience') }}</h4>
        <div>
          <div>
            <span class="badge text-bg-primary me-2" >
              {{ result.job.specialty.name }}
            </span>
          </div>
        </div>
      </a-col>
      <a-col :span="24">
        <a-divider />
      </a-col>
      <a-col :span="24">
        <h4 class="fw-bold">{{ $t('aboutTheClient') }}</h4>
        <a-row class="mt-2 mb-2">
          <a-col :span="3">
            <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-if="result.job.profile.image" :src="result.job.profile.image" />
            <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-else>
              <template #icon>
                <UserOutlined />
              </template>
            </a-avatar>
          </a-col>
          <a-col :span="21">
            <div class="fw-bold">
              <router-link class="link-dark" @click="closeDrawer" :to="{name: 'profile-page', params: {
                slug: result.job.profile.profile_slug
              }}">
                {{ result.job.profile.title }}
              </router-link>
            </div>
            <div>
              <div>
                <star-rating :read-only="true" :star-size="20" :show-rating="false"></star-rating>
              </div>
              <small class="text-muted">
<!--                <img src="@/assets/icons/location.svg" width="14" alt="location">-->
                <EnvironmentOutlined class="text-primary" />
                {{ result.job.profile.location }}
              </small>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <!-- Copy Modal -->
    <a-modal v-model:visible="copyModalVisible" :closable="false" :title="$t('shareLink')">
      <template #footer>
        <a-button type="default" @click="copyModalVisible = false">{{ $t('close') }}</a-button>
      </template>

      <a-input v-model:value="jobURL" readonly>
        <template #suffix>
          <a-tooltip :title="$t('copy')">
            <a-button @click="copyUrl">
              <template #icon><CopyOutlined /></template>
            </a-button>
          </a-tooltip>
        </template>
      </a-input>
    </a-modal>
  </div>
  <ApplyJobModalComponent />
  <ViewJobApplicationsModalComponent />
</div>
</template>

<script>
import {useQuery} from "@vue/apollo-composable";
import {ViewJobGql} from "@/gql/view-job-gql";
import {inject, onMounted, reactive, ref, watch} from "vue";
import {
    CopyOutlined,
    MoreOutlined,
    ShareAltOutlined,
    FlagOutlined,
    EditOutlined,
    UserOutlined,
    EnvironmentOutlined
} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {useStore} from "vuex";
import ApplyJobModalComponent from "@/components/ApplyJobModalComponent.vue";
import ViewJobApplicationsModalComponent from "@/components/ViewJobApplicationModalComponent.vue";
import {useI18n} from "vue-i18n";
export default {
  name: "JobView",
  components: {
    ViewJobApplicationsModalComponent,
    ApplyJobModalComponent,
    CopyOutlined,
    MoreOutlined,
    ShareAltOutlined,
    FlagOutlined,
    EditOutlined,
    UserOutlined,
    EnvironmentOutlined
  },
  props: {
    job_id: {
      required: true,
      type: Number
    }
  },
  mounted() {
    this.$gtag.screenview({
      screen_name: 'Job View',
    })
  },
  setup(props) {
    const store = useStore()
    const emitter = inject('emitter');
    let ellipsis = ref(true)
    let jobId = ref(props.job_id);
    const copyModalVisible = ref(false)
    let queryVars = reactive({
      id: jobId.value
    })
    const moment = inject('moment');
    const authUser = store.state.authedUser
    const diffForHumans = (date) => {
      return moment(date).fromNow()
    }
    const jobURL = ref('')
    const {result, loading, error, refetch} = useQuery(ViewJobGql, queryVars)
    watch(() => props.job_id, (newValue) => {
      queryVars.id = newValue
      refetch()
    });
    const {t} = useI18n()
    const copyUrl = () => {
      navigator.clipboard.writeText(jobURL.value).then(function() {
        message.success(t('copied'))
      });
    }
    const closeDrawer = () => {
      emitter.emit('closeJobDrawer')
    }
    const openReportDrawer = () => {
      emitter.emit('openReportDrawer', {id: props.job_id, type: 'job'});
    }

    const editJob = () => {
      emitter.emit('openJobModal', result.value.job);
    }

    onMounted(() => {
      jobURL.value = process.env.VUE_APP_BASE_URL + '/in/job/view/' + jobId.value
    })

    const openApplyJobModal = () => {
      emitter.emit('openApplyJobModal', {
        id: jobId.value,
        title: result.value.job.title
      });
    }

    emitter.on('userAppliedToJob', () => {
      refetch()
    });

    const openViewApplicationModal = () => {
      emitter.emit('openViewApplicationModal', {
        id: jobId.value,
        title: result.value.job.title
      });
    }

    return {
      result,
      loading,
      error,
      diffForHumans,
      jobId,
      queryVars,
      ellipsis,
      copyModalVisible,
      jobURL,
      copyUrl,
      closeDrawer,
      openReportDrawer,
      store,
      editJob,
      openApplyJobModal,
      authUser,
      openViewApplicationModal
    }
  },
}
</script>

<style scoped lang="scss">
.link-dark {
  text-decoration: none;
}
.link-dark:hover {
  text-decoration: underline;
  color: var(--bs-link-color)!important;
}
</style>
