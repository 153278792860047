<template>
  <a-modal v-model:visible="visible" :title="$t('advancedSearch')">
    <a-form
        :model="search"
        name="basic"
        autocomplete="off"
        layout="vertical"
    >
      <a-form-item
          :label="$t('specialty')"
          name="specialty_id"
      >
        <a-select
            v-model:value="search.specialty_id"
            show-search
            :filter-option="filterOption"
            :options="store.state.specialties.map(item => {return {value: item.id, label: item.name}})"
        >
        </a-select>
      </a-form-item>
      <a-form-item
          :label="$t('jobLocation')"
          name="location"
      >
        <a-select
            v-model:value="search.location"
            show-search
            :filter-option="filterOption"
            :options="store.state.countries.map(item => {return {value: item.name, label: item.name}})"
        >
        </a-select>
      </a-form-item>
    </a-form>

    <template #footer>
      <div>
        <a-button type="default" @click="resetSearch">{{ $t('reset') }}</a-button>
        <a-button type="primary" @click="handleSearch">{{ $t('search') }}</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {inject, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "AdvancedSearchModal",
  setup() {
    const store = useStore();
    const visible = ref(false);
    const emitter = inject('emitter')
    emitter.on('openAdvancedSearchModal', () => {
      visible.value = true
    })
    const search = ref({
      specialty_id: null,
      location: null
    });
    const handleSearch = () => {
      emitter.emit('advancedJobSearchEvent', search.value)
      visible.value = false
    }

    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    const resetSearch = () => {
      search.value = {
        specialty_id: null,
        location: null
      };
      handleSearch()
    }
    return {
      visible,
      handleSearch,
      search,
      store,
      filterOption,
      resetSearch
    }
  }
}
</script>

<style scoped>

</style>
