<template>
  <div class="">
    <a-row class="min-vh-100">
      <a-col :span="4" class="bg-white">
<!--        <a-card>-->
          <a-menu
              v-model:selectedKeys="selectedKeys"
              mode="vertical"
              class="w-100 h-100"
          >
            <a-menu-item key="1" @click="router.push({name: 'password-and-security'})">
              <template #icon>
                <LockOutlined />
              </template>
              {{ $t('passAndSec') }}
            </a-menu-item>
            <a-menu-item key="2" @click="router.push({name: 'subscription'})">
              <template #icon>
                <CreditCardOutlined />
              </template>
              {{ $t('subscription') }}
            </a-menu-item>
          </a-menu>
<!--        </a-card>-->
      </a-col>
      <a-col :span="20">
        <router-view />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {LockOutlined, CreditCardOutlined} from "@ant-design/icons-vue";
import {useRouter} from "vue-router";

export default {
  name: "SettingsPage",
  components: {
    LockOutlined,
    CreditCardOutlined
  },
  setup() {
    const selectedKeys = [];
    const router = useRouter();
    return {
      selectedKeys,
      router
    }
  }
}
</script>

<style scoped>

</style>
