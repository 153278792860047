import gql from "graphql-tag";

export const UpdateCertificateGql = gql`
    mutation updateCertificate($id: Int!, $name:String!,$provider:String!,$issued_date:String!,$expire_date:String!,$certificate_id:String!){
        updateCertificate(id:$id, name:$name, provider:$provider,issued_date:$issued_date, expire_date:$expire_date, certificate_id:$certificate_id){
            id
            name
            provider
            issued_date
            expire_date
            certificate_id
        }
    }
`