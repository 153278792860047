import {createApp, h, provide, watch, onMounted} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//Antd
import Antd from 'ant-design-vue';
//Bootstrap
import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
import './style/main.less'
import './style/main.scss'
import {UserOutlined} from "@ant-design/icons-vue";
import StarRating from 'vue-star-rating'
import {ApolloClients} from '@vue/apollo-composable'
import DefaultApolloClient from "@/ApolloClient/DefaultApolloClient";
import getAuthData from "@/global functions/handel-auth-data";
import mitt from "mitt";
import moment from "moment";
import formatPhoneNumber from '@/global functions/mobile-formater'
import VoerroTagsInput from '@voerro/vue-tagsinput';
import {HandelGraphqlErrors} from "@/global functions/handel-graphql-errors";
import VueGtag from "vue-gtag";
import AuthApolloClient from "@/ApolloClient/AuthApolloClient";
import dayjs from "dayjs";
import axios from 'axios'
import VueAxios from 'vue-axios'
import './registerServiceWorker'
import messaging from './firebase/firebase';
import 'video.js/dist/video-js.css';
import PublicApolloClient from "@/ApolloClient/PublicApolloClient";
import SubscriptionsApolloClient from "@/ApolloClient/SubscriptionsApolloClient";
import { createI18n } from 'vue-i18n';
import EnLocal from "@/locales/en"
import ArLocal from "@/locales/ar"



const i18n = createI18n({
    // something vue-i18n options here ...
    legacy: false,
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages: {
        en: EnLocal,
        ar: ArLocal
    }
})
createApp({
    setup () {
        let convertMomentTimeZone = (value) => {
            return moment.utc(value).local().lang(i18n.global.locale.value)
        }
        provide('messaging', messaging)
        provide(ApolloClients, {
            default: DefaultApolloClient,
            auth: AuthApolloClient,
            public: PublicApolloClient,
            subscriptions: SubscriptionsApolloClient
        })
        provide('getAuthedData', getAuthData());
        provide('emitter', mitt())
        // provide('moment', moment)
        provide('moment', convertMomentTimeZone)
        provide('dayjs', dayjs)
        provide('formatPhoneNumber', formatPhoneNumber())
        provide('HandelGraphqlErrors', HandelGraphqlErrors)

        watch(i18n.global.locale, () => {
            console.log('lang changed: ', i18n.global.locale.value)
            localStorage.setItem('currentLang', i18n.global.locale.value)
        })
        onMounted(() => {
            let getCurrentLang = localStorage.getItem('currentLang')
            if (getCurrentLang) {
                i18n.global.locale.value = getCurrentLang
            }
        })
    },
    render:() => h(App)
})
    .use(i18n)
    .use(Antd)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(VueGtag, {
        appName: 'Doctor In',
        pageTrackerScreenviewEnabled: true,
        config: { id: "G-ZX7T7Y9KVZ" }
    }, router)
    .component('UserOutlined', UserOutlined)
    .component('tags-input', VoerroTagsInput)
    .component('star-rating', StarRating)
    .mount('#app')
