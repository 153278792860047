import gql from "graphql-tag";

export const CreateJobMutationGql = gql`
    mutation createJob(
        $title:String!, 
        $overview:String!, 
        $location:String!, 
        $specialty_id:Int!, 
#        $skills:ArrayScalar!
    ){
        createJob(
            title:$title,
            overview:$overview,
            location:$location,
            specialty_id:$specialty_id, 
#            skills:$skills
        ){
            id
        }
    }
`