<template>
  <a-modal v-model:visible="visible" :title="post.id ? $t('updatePost') : $t('createPost')" :width="600">
    <div>
      <a-space class="w-100" direction="vertical" size="middle">
        <a-row :gutter="[10]">
          <a-col>
            <a-avatar :size="45" :src="authedUser.user.profile.image" v-if="authedUser.user.profile.image" />
          </a-col>
          <a-col>
            <a-space direction="vertical" :size="2">
              <span class="fw-bold">{{authedUser.user.name}}</span>
              <a-dropdown :trigger="['click']">
                <span class="text-primary cursor-pointer">
                  {{ post.privacy == 'Public' ? $t('public') : $t('private') }}
                  <DownOutlined />
                </span>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="0" @click="changePrivacy('Public')" :class="post.privacy == 'Public' ? 'text-primary' : ''">
                      {{ $t('public') }}
                    </a-menu-item>
                    <a-menu-item key="1" @click="changePrivacy('Private')" :class="post.privacy == 'Private' ? 'text-primary' : ''">
                      {{ $t('private') }}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-textarea
                :bordered="false"
                v-model:value="post.post"
                :placeholder="$t('whatIsInYourMind')"
                :auto-size="{ minRows: 2, maxRows: 10 }"
                v-if="file || post.file_id"
            />
            <a-textarea
                :bordered="false"
                v-model:value="post.post"
                :placeholder="$t('whatIsInYourMind')"
                :auto-size="{ minRows: 10, maxRows: 10 }"
                v-else
            />
            <div v-if="file && !post.file_id" class="text-center">
              <img :src="file" alt="" v-if="file_type == 'image'" style="height: 20rem">
              <video controls class="w-100" id="video" preload="metadata" v-else-if="file_type == 'video'">
                <source :src="file" type="video/mp4" />
              </video>
            </div>
            <div v-else-if="post.file_id">
              <img :src="post.file.file_url" alt="photo" v-if="post.file.file_type == 'image'" class="w-100">
              <video controls class="w-100" id="video" preload="metadata" v-else-if="post.file.file_type == 'video'">
                <source :src="post.file.file_url" type="video/mp4" />
              </video>
            </div>
          </a-col>
          <input type="file" class="d-none" id="post-file" @change="onChangeFile">
        </a-row>
      </a-space>
    </div>

    <template #footer>
      <a-row :justify="'space-between'">
        <a-col>
          <a-button type="primary" ghost v-if="file || post.file_id" @click="removeMedia">
            {{ $t('removeMedia') }}
          </a-button>
          <a-space direction="horizontal" v-else>
            <a-tooltip>
              <template #title>{{ $t('addPhoto') }}</template>
              <a-button type="text" @click="addFile('image')">
                <template #icon>
                  <CameraOutlined />
                </template>
              </a-button>
            </a-tooltip>
            <a-tooltip>
              <template #title>{{ $t('addVideo') }}</template>
              <a-button type="text" @click="addFile('video')">
                <template #icon>
                  <VideoCameraOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </a-col>
        <a-col>
            <a-button type="primary" :disabled="!post.post && !file && post.file_id" @click="createPost" :loading="isLoading">
              {{post.id ? $t('updatePost') : $t('post')}}
            </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import {inject, ref} from 'vue'
import {useStore} from "vuex";
import {
  DownOutlined,
    CameraOutlined,
    VideoCameraOutlined
  // GlobalOutlined,
  // LockOutlined
} from "@ant-design/icons-vue";
import {useMutation} from "@vue/apollo-composable";
// import {CreateFileMutationGql} from "@/gql/create-file-mutation-gql";
import {CreatePostMutationGql} from "@/gql/create-post-mutation-gql";
import axios from "axios";
import {UpdatePostMutationGql} from "@/gql/update-post-mutation-gql";

export default {
  name: "PostModalComponent",
  components: {
    DownOutlined,
    CameraOutlined,
    VideoCameraOutlined,
    // GlobalOutlined,
    // LockOutlined
  },
  setup() {
    const visible = ref(false);
    const store = useStore();
    const emitter = inject('emitter')
    const authedUser = store.state.authedUser;
    const isLoading = ref(false);
    const file = ref('');
    const file_type = ref('');
    const post = ref({
      id: null,
      post: null,
      file_id: null,
      privacy: 'Public',
      file: null
    })
    emitter.on('openPostModal', (data) => {
      visible.value = true;
      if (data) {
        post.value = JSON.parse(JSON.stringify(data))
      }
    })

    const addFile = (type) => {
      file.value = null
      const fileInput = document.getElementById('post-file');
      if (type == 'image') {
        fileInput.setAttribute('accept', 'image/*');
      } else {
        fileInput.setAttribute('accept', 'video/mp4');
      }
      file_type.value = type;
      fileInput.click();
    }

    const onChangeFile = async (e) => {
      file.value = await renderFile(e)
    }

    const renderFile = (e) => {
      return new Promise((resolve) => {
        var file = e.target.files[0];
        var reader  = new FileReader();
        // it's onload event and you forgot (parameters)
        reader.onload = (e) =>  {
          resolve(e.target.result)
        }
        // you have to declare the file loading
        reader.readAsDataURL(file);
      })
    }
    // const {mutate: createFileMutate, /*error: createFileError*/ } = useMutation(CreateFileMutationGql);
    const {mutate: createPostMutate, /*error: createFileError*/ } = useMutation(CreatePostMutationGql);
    const {mutate: updatePostMutate, /*error: createFileError*/ } = useMutation(UpdatePostMutationGql);
    const createPost = async () => {
      isLoading.value = true;
      let createFile = {};
      if (file.value) {
        // createFile = await createFileMutate({
        //   file: document.getElementById('post-file').files[0],
        //   file_type: file_type.value
        // });
        // Create a FormData object
        const inputFile = document.getElementById('post-file').files[0]
        let bodyFormData = new FormData();
        bodyFormData.set('operations', JSON.stringify({
          'query': `mutation createFile($file:Upload!, $file_type: String!) {
              createFile(file: $file, file_type: $file_type) {
                  id
              }
          }`,
          'variables': {"file": inputFile, 'file_type': file_type.value}
        }));
        bodyFormData.set('operationName', null);
        bodyFormData.set('map', JSON.stringify({"file":["variables.file"]}));
        bodyFormData.append('file', inputFile);

        let authedUser = JSON.parse(localStorage.getItem("authed_user"));
        // Post the request to GraphQL controller via Axios, jQuery.ajax, or vanilla XMLHttpRequest
        createFile = await axios.post(`${process.env.VUE_APP_GRAPHQL_URL}`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `${authedUser.token_type} ${authedUser.access_token}`,
          }
        })
      }
      if (post.value.id) {
        await updatePostMutate({
          id: post.value.id,
          post: post.value.post,
          file_id: file.value ? createFile.data.data.createFile.id : parseInt(post.value.file_id),
          privacy: post.value.privacy.toLowerCase()
        }).then(() => {
          isLoading.value = false
          resetData()
          visible.value = false
          emitter.emit('updatePostsData')
        });
      } else {
        await createPostMutate({
          post: post.value.post,
          file_id: file.value ? createFile.data.data.createFile.id : null,
          privacy: post.value.privacy.toLowerCase()
        }).then(() => {
          isLoading.value = false
          resetData()
          visible.value = false
          emitter.emit('updatePostsData')
        });
      }
    }

    const resetData = () => {
      post.value = {
        id: null,
        post: null,
        file_id: null,
        privacy: 'Public',
        file: null
      };
      file.value = null;
    }

    const changePrivacy = (type) => {
      post.value.privacy = type
    }

    const removeMedia = () => {
      file.value = null;
      post.value.file_id = null;
      post.value.file = null;
    }

    return {
      visible,
      authedUser,
      post,
      file,
      file_type,
      addFile,
      onChangeFile,
      createPost,
      isLoading,
      resetData,
      createPostMutate,
      changePrivacy,
      removeMedia
    }
  }
}
</script>

<style scoped>
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
