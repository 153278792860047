<template>
<div>
  <a-typography-title :level="4" class="text-center">
    {{ $t('pickupSubscription') }}
  </a-typography-title>
  <div>
    <a-form
        :model="formState"
        name="basic"
        layout="vertical"
        autocomplete="off"
        @finish="onFinish"
    >
      <a-form-item
          name="plan"
          :label="$t('pickupSubscription')"
      >
        <a-select
            v-model:value="formState.plan"
            :rules="{required: true}"
        >
          <a-select-option v-for="item in subscriptions" :value="item.id" :key="item.id">{{ $t(item.subscription_type) }} ({{parseFloat(item.subscription_amount).toFixed(2)}} AED)</a-select-option>
        </a-select>
      </a-form-item>
<!--      <a-form-item-->
<!--          name="is_recurring"-->
<!--          label="Recurring"-->
<!--      >-->
<!--&lt;!&ndash;        <template #label>&ndash;&gt;-->
<!--&lt;!&ndash;          <a-space>&ndash;&gt;-->
<!--&lt;!&ndash;            <div>Recurring</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <a-tooltip>&ndash;&gt;-->
<!--&lt;!&ndash;              <template #title>prompt text</template>&ndash;&gt;-->
<!--&lt;!&ndash;              <QuestionCircleOutlined />&ndash;&gt;-->
<!--&lt;!&ndash;            </a-tooltip>&ndash;&gt;-->
<!--&lt;!&ndash;          </a-space>&ndash;&gt;-->
<!--&lt;!&ndash;        </template>&ndash;&gt;-->
<!--        <a-select-->
<!--            v-model:value="formState.isRecurring"-->
<!--            :rules="{required: true}"-->
<!--        >-->
<!--          <a-select-option :value="true">Yes</a-select-option>-->
<!--          <a-select-option :value="false">No, just once</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-item>-->

      <a-button type="primary" html-type="submit" :loading="isLoading" block>{{ $t('subscribeNow') }}</a-button>
    </a-form>
  </div>
</div>
</template>

<script setup>
import {ref} from "vue";
import {useMutation, useQuery} from "@vue/apollo-composable";
import GetSubscriptionsGql from "@/gql/get-subscriptions-gql";
import CreateUserSubscriptionGql from "@/gql/create-user-subscription-gql";
import {useStore} from "vuex";
// import getAuthData from "@/global functions/handel-auth-data";

// const authedData = getAuthData()
const formState = ref({
  plan: null,
  isRecurring: false
});
const subscriptions = ref([])
const isLoading = ref(false)
const store = useStore();
const authedUser = store.state.authedUser;



const {result, onResult} = useQuery(GetSubscriptionsGql, {}, {
  clientId: 'public'
})
onResult(() => {
  let data = result.value.subscriptions.filter(x => {
    return x.subscription_user_type == authedUser.user.profile.profile_type
  })
  subscriptions.value = data
  formState.value.plan = subscriptions.value[0].id
})

const {mutate} = useMutation(CreateUserSubscriptionGql, {
  clientId: 'subscriptions'
})
const onFinish = () => {
  isLoading.value = true
  mutate({
    plan_id: formState.value.plan,
    is_recurring: formState.value.isRecurring
  }).then(res => {
    window.location.href = res.data.createUserSubscription;
    isLoading.value = false
  })
}
</script>

<style scoped>

</style>
