import gql from "graphql-tag";

export const CreateReviewMutationGql = gql`
    mutation createReview(
        $review:String!,
        $rate:String!,
        $profile_id:Int!,
    ){
        createReview(
            review:$review,
            rate:$rate,
            profile_id:$profile_id,
        ){
            id
        }
    }
`