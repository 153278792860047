<template>
  <div class="container py-5">
    <a-row :gutter="20">
      <a-col :xs="{span: 24}" :lg="{span: 18}">
        <HomeJobsView/>
      </a-col>
      <a-col :xs="{span: 0}" :lg="{span: 6}" :span="6">
        <HomeWhoAmIView/>
      </a-col>
    </a-row>

    <!-- View Job Drawer -->
<!--    <ViewJobDrawer />-->
  </div>
</template>

<script>
import HomeWhoAmIView from "@/views/HomeViews/HomeWhoAmIView";
import HomeJobsView from "@/views/HomeViews/HomeJobsView";
// import ViewJobDrawer from "@/components/ViewJobDrawer";
export default {
  name: "FindWorkPage",
  components: {
    // ViewJobDrawer,
    HomeWhoAmIView,
    HomeJobsView
  },
  setup() {
    //
  }
}
</script>

<style scoped>

</style>