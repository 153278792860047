import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import router from "@/router";
// import {logErrorMessages} from "@vue/apollo-util";

function getHeaders() {
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": process.env.VUE_APP_BASE_URL
    };
    let authedUser = localStorage.getItem("authed_user");
    if (authedUser) {
        authedUser = JSON.parse(authedUser)
        headers["Authorization"] = `${authedUser.token_type} ${authedUser.access_token}`;
    }
    return headers;
}

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: `${process.env.VUE_APP_GRAPHQL_URL}`,
    fetch: (uri, options) => {
        options.headers = getHeaders();
        return fetch(uri, options);
    },

})


// Cache implementation
const cache = new InMemoryCache()

// Handle errors
const errorLink = onError(error => {
    // if (process.env.NODE_ENV !== 'production') {
    //     logErrorMessages(error)
    // }
    if (error.networkError.statusCode == 403) {
        router.push({name: "subscription-page"})
    }
})

// Create the apollo client
const defaultApolloClient = new ApolloClient({
    link: errorLink.concat(httpLink),
    cache,
})

export default defaultApolloClient;
