<template>
<div>
  <a-modal v-model:visible="visible" :title="$t('addEducation')" centered>

    <a-form
        :model="education"
        name="basic"
        autocomplete="off"
        @finish="saveData"
        layout="vertical"
    >
      <a-form-item
          :label="$t('fieldOfStudy')"
          name="name"
          :rules="[{ required: true }, { whitespace: true }, {max: 255}]"
      >
        <a-input v-model:value="education.name" />
      </a-form-item>

      <a-form-item
          :label="$t('school')"
          name="school"
          :rules="[{ required: true }, { whitespace: true }, {max: 255}]"
      >
        <a-input v-model:value="education.school" />
      </a-form-item>

      <a-form-item
          :label="$t('degree')"
          name="degree"
          :rules="[{ required: true }, { whitespace: true }, {max: 255}]"
      >
        <a-input v-model:value="education.degree" />
      </a-form-item>

      <a-form-item
          :label="$t('startDate')"
          name="start_date"
          :rules="[{ required: true }]"
      >
        <a-date-picker v-model:value="education.start_date" :format="'MMM YYYY'" class="w-100" picker="month" @change="onChangeStartDate" :disabled-date="disabledDateStart" />
        <!--        <a-input v-model:value="education.start_date" />-->
      </a-form-item>

      <a-form-item
          :label="$t('endAtDate')"
          name="end_date"
          :rules="[{ required: true }]"
      >
        <a-date-picker v-model:value="education.end_date" :format="'MMM YYYY'" class="w-100" picker="month" :disabled-date="disabledDate" />
<!--        <a-input v-model:value="education.end_date" />-->
      </a-form-item>

      <a-button type="primary" html-type="submit" class="d-none" id="submitEduForm" />
    </a-form>

    <template #footer>
      <div :class="'d-flex ' + (education.id ? 'justify-content-between' : 'justify-content-end')">
        <a-popconfirm
            :title="$t('areSreDeleteEx')"
            :ok-text="$t('yes')"
            :cancel-text="$t('no')"
            @confirm="deleteData"
            v-if="education.id"
        >
          <a-button key="delete" danger>
            {{ $t('deleteEducation') }}
          </a-button>
        </a-popconfirm>
        <div>
          <a-button key="back" @click="visible = false">{{ $t('close') }}</a-button>
          <a-button key="submit" type="primary" @click="submitForm" :loading="confirmLoading">{{ $t('save') }}</a-button>
        </div>
      </div>
    </template>
  </a-modal>
</div>
</template>

<script>
import {inject, ref} from 'vue'
import {useStore} from "vuex";
import dayjs from 'dayjs'
import {
  // DeleteOutlined,
  // PlusCircleOutlined
} from "@ant-design/icons-vue";
import {useMutation} from "@vue/apollo-composable";
import {message} from "ant-design-vue";
import {CreateEducationMutationGql} from "@/gql/create-education-mutation-gql";
import {UpdateEducationMutationGql} from "@/gql/update-education-mutation-gql";
import {DeleteEducationMutationGql} from "@/gql/delete-education-mutation-gql";
import {useI18n} from "vue-i18n";

export default {
  name: "EducationModalComponent",
  components: {
    // DeleteOutlined,
    // PlusCircleOutlined
  },
  setup() {
    const confirmLoading = ref(false)
    const store = useStore();
    const emitter = inject('emitter')
    const visible = ref(false);
    const profileID = ref(null);
    const education = ref({
      'name': null,
      'school': null,
      'degree': null,
      'start_date': null,
      'end_date': null,
      'profile_id': null
    })
    const title = ref('Add education');
    const {mutate: saveEducation} = useMutation(CreateEducationMutationGql)
    const {mutate: updateEducation} = useMutation(UpdateEducationMutationGql)
    const {mutate: deleteEducation} = useMutation(DeleteEducationMutationGql)
    const {t} = useI18n()
    const saveData = () => {
      confirmLoading.value = true
      if (education.value.id) {
        updateEducation(education.value).then(() => {
          message.success(t('eduUpdated'))
          emitUpdateProfile()
          confirmLoading.value = false
        })
      } else {
        saveEducation(education.value).then(() => {
          emitUpdateProfile()
          message.success(t('eduAdded'))
          confirmLoading.value = false
        })
      }
    }

    const deleteData = () => {
      deleteEducation({
        id: education.value.id
      }).then(() => {
        message.success(t('eduDeleted'))
        emitUpdateProfile()
      })
    }

    const emitUpdateProfile = () => {
      emitter.emit('refetchProfileData')
    }

    emitter.on('openEducationModal', (data) => {
      if (data.education) {
        title.value = 'Edit education'
        education.value = JSON.parse(JSON.stringify(data.education))
        education.value.start_date = dayjs(education.value.start_date)
        education.value.end_date = dayjs(education.value.end_date)
      } else {
        title.value = 'Add education'
        education.value = {
          'name': null,
          'school': null,
          'degree': null,
          'start_date': null,
          'end_date': null,
          'profile_id': null
        }
      }
      visible.value = true;
      profileID.value = data.profile_id
      education.value.profile_id = data.profile_id
    });

    const submitForm = () => {
      document.getElementById('submitEduForm').click()
    }

    const disabledDate = current => {
      return education.value.start_date && dayjs(current).endOf('day') < dayjs(education.value.start_date).add('1', 'M').endOf('day')
    };

    const disabledDateStart = current => {
      return current && current > dayjs().endOf('day')
    };

    const onChangeStartDate = () => {
      if (dayjs(education.value.start_date).endOf('day') > dayjs(education.value.end_date).endOf('day')) {
        education.value.end_date = null
      }
    }

    return {
      visible,
      saveData,
      store,
      education,
      title,
      deleteData,
      confirmLoading,
      submitForm,
      disabledDate,
      onChangeStartDate,
      disabledDateStart
    }
  }
}
</script>

<style scoped>

</style>
