<template>
<a-card>
  <a-row :gutter="5">
    <a-col :span="3">
      <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-if="props.comment.user.profile.image" :src="props.comment.user.profile.image" />
      <a-avatar shape="square" :size="{md: 64, lg: 64, xl: 64, xxl: 64}" v-else>
        <template #icon>
          <UserOutlined />
        </template>
      </a-avatar>
    </a-col>
    <a-col :span="21">
      <a-row :justify="'space-between'">
        <a-col>
          <div class="fw-bold">{{ props.comment.user.name }}</div>
          <small class="text-muted">{{ $t('posted') }} {{ moment(props.comment.created_at).fromNow() }}</small>
        </a-col>
        <a-col>
          <a-tooltip title="Close Edit Comment" v-if="editable">
            <a-button shape="circle" size="small" @click="closeEditComment">
              <template #icon>
                <CloseOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <a-dropdown :trigger="['click']" v-else>
            <span class="cursor-pointer">
              <MoreOutlined />
            </span>
            <template #overlay>
              <a-menu v-if="authedUser.user.id == props.comment.user.id">
                <a-menu-item key="0" @click.prevent="editComment">
                  <EditOutlined />
                  {{ $t('edit') }}
                </a-menu-item>
                <a-menu-item key="1" @click.prevent="deleteComment" class="text-danger">
                  <DeleteOutlined />
                  {{ $t('delete') }}
                </a-menu-item>
              </a-menu>
              <a-menu v-else>
                <a-menu-item key="2" @click="openReportDrawer">
                  <!--                      <a-space>-->
                  <FlagOutlined />
                  {{ $t('report') }}
                  <!--                      </a-space>-->
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-col>
      </a-row>

      <a-typography-paragraph
          class="my-3"
          :ellipsis="ellipsis ? { rows: 3, expandable: true, symbol: $t('more') } : false"
          :content="props.comment.comment"
          v-if="!editable"
      />
      <a-space class="w-100 mt-3" direction="vertical" v-else>
        <a-textarea
            v-model:value="commentText"
            :auto-size="{ minRows: 5, maxRows: 5 }"
            :disabled="updateCommentMutateLoading"
            @change="validateComment"
            :status="commentError ? 'error' : ''"
        />
        <span v-if="commentError" class="text-danger">
              {{commentError}}
        </span>
        <a-button type="primary" block @click="updateComment" :loading="updateCommentMutateLoading" :disabled="commentError || !commentText">
          {{ $t('update') }}
        </a-button>
      </a-space>
    </a-col>
  </a-row>
</a-card>
</template>

<script>
import {createVNode, inject, ref, watch} from "vue";
import {
  MoreOutlined,
  EditOutlined,
  FlagOutlined,
  UserOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import {useStore} from "vuex";
import {useMutation} from "@vue/apollo-composable";
import {UpdateCommentMutationGql} from "@/gql/update-comment-mutation-gql";
import {message, Modal} from "ant-design-vue";
import {DeleteCommentMutationGql} from "@/gql/delete-comment-mutation-gql";
import {useI18n} from "vue-i18n";

export default {
  name: "CommentComponent",
  components: {
    MoreOutlined, EditOutlined, FlagOutlined, UserOutlined, CloseOutlined, DeleteOutlined
  },
  props: {
    comment: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const moment = inject('moment');
    const ellipsis = ref(true);
    const store = useStore()
    const authedUser = store.state.authedUser;
    const emitter = inject('emitter');
    const editable = ref(false);
    // eslint-disable-next-line vue/no-setup-props-destructure
    const commentText = ref(props.comment.comment)
    const propsComment = ref(props.comment)
    const commentError = ref(null);


    watch(() => props.comment.comment, () => {
        commentText.value = props.comment.comment
    });

    const {mutate: updateCommentMutate, loading: updateCommentMutateLoading} = useMutation(UpdateCommentMutationGql);
    const {mutate: deleteCommentMutate} = useMutation(DeleteCommentMutationGql);
    const {t} = useI18n()
    const updateComment = () => {
      updateCommentMutate({
        id: props.comment.id,
        comment: commentText.value
      }).then(() => {
        editable.value = false;
        message.success(t('commentUpdated'));
        // emitter.emit('updateComment')
        emitter.emit('updateCommentsData')
      });
    }

    const editComment = () => {
      editable.value = true;
    }

    const openReportDrawer = () => {
      emitter.emit('openReportDrawer', {id: props.comment.id, type: 'comment'});
    }

    const closeEditComment = () => {
      // console.log(commentText.value)
      // props.comment.value.comment = commentText.value;
      // eslint-disable-next-line vue/no-mutating-props
      commentText.value = props.comment.comment
      editable.value = false;
    }

    const deleteComment = () => {
      Modal.confirm({
        title: t('areSreDeleteEx'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('recoverDeletePost'),
        okText: t('yes'),
        okType: 'danger',
        cancelText: t('no'),
        onOk() {
          return new Promise((resolve, reject) => {
            deleteCommentMutate({
              id: props.comment.id
            }).then(() => {
              emitter.emit('updateCommentsData')
              resolve();
            }).catch(() => reject())
          })
        },
        onCancel() {
          //
        },
      });
    }

    const validateComment = () => {
      commentError.value = null;
      if (!commentText.value) {
        commentError.value = null;
      }
      else if (!commentText.value.replace(/\s/g, '').length) {
        commentError.value = t('commentNotValid');
      } else if (commentText.value.length > 500) {
        commentError.value = t('commentMax')
      }
    }

    return {
      moment,
      ellipsis,
      authedUser,
      openReportDrawer,
      editable,
      editComment,
      commentText,
      updateComment,
      updateCommentMutateLoading,
      propsComment,
      closeEditComment,
      deleteComment,
      props,
      validateComment,
      commentError
    }
  }
}
</script>

<style scoped>

</style>
