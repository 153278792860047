<template>
  <a-row class="vh-100">
    <a-col :span="17" class="bg-light">
      <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <img src="@/assets/images/undraw_forgot_password.svg" style="width: 40%;" alt="Login">
      </div>
    </a-col>
    <a-col :span="7" class="borderd-left my-auto">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <div class="text-center mb-5">
            <img src="@/assets/images/dr.png" width="200" alt="doctor-in">
            <h4 class="mt-2">{{$t('forgotPassword')}}</h4>
          </div>
        </div>
      </div>
      <div class="p-4 pt-2">
        <a-form layout="vertical" :model="forgotPassword" @finish="sendEmail" autocomplete="off">
          <a-form-item :label="$t('email')" name="email" :rules="[{ type: 'email', required: true }]">
            <a-input v-model:value="forgotPassword.email" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" block class="fw-bold" html-type="submit" :loading="loading">{{ $t('forgotPassword') }}</a-button>
          </a-form-item>
        </a-form>
        <div>
          <a-divider class="text-muted"><small>{{ $t('or') }}</small></a-divider>
        </div>
        <a-space direction="vertical" class="w-100">
          <a-button type="primary" ghost block class="fw-bold" @click="$router.push({name: 'login'})">{{ $t('backToLogin') }}</a-button>
<!--          <a-button type="primary" ghost block class="fw-bold" @click="$router.push({name: 'signup_profile_type'})">Forgot Password</a-button>-->
        </a-space>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {ref} from "vue";
import {useMutation} from "@vue/apollo-composable";
import {ForgotPasswordMutationGql} from "@/gql/forgot-password-mutation-gql";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";

export default {
  name: "forgot-password-page",
  setup() {
    const {t} = useI18n()
    const forgotPassword = ref({
      email: null
    })
    const {mutate: forgotPasswordMutation, loading} = useMutation(ForgotPasswordMutationGql, {
      clientId: 'auth'
    })
    const sendEmail = () => {
      forgotPasswordMutation(forgotPassword.value).then(() => {
        message.success(t('resetPassSent'))
      }).catch(() => {
        message.error(t('emailNotMatched'))
      })
    }
    return {
      forgotPassword,
      sendEmail,
      loading
    }
  }
}
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
