<template>
  <div>
    <a-page-header
        class="bg-white"
        :title="$t('subscription')"
    >
      <template #extra>
        <a-button type="primary" @click="openSubscriptionModal">
          {{ $t('newSubscription') }}
        </a-button>
      </template>
    </a-page-header>
    <div v-if="loading" class="text-center">
      <a-spin />
    </div>
    <a-row :gutter="[16,16]" class="mt-3" v-else>
      <a-col :span="14" :offset="5">
        <a-card v-for="item in userSubscriptions" :key="item.id" class="position-relative mb-2">
          <template #title>
            {{ $t('subscriptionDetails') }}
          </template>
          <template #extra>
<!--            <a-dropdown :trigger="['click']" :placement="'bottomLeft'" v-if="moment().unix() < moment(item.start_at).unix()">-->
<!--              <a-button type="text" class="ant-dropdown-link" :icon="h(MoreOutlined)"></a-button>-->
<!--              <template #overlay>-->
<!--                <a-menu>-->
<!--&lt;!&ndash;                  <a-menu-item>&ndash;&gt;-->
<!--&lt;!&ndash;                    <RedoOutlined /> Renew Subscription&ndash;&gt;-->
<!--&lt;!&ndash;                  </a-menu-item>&ndash;&gt;-->
<!--                  <a-menu-item v-if="moment().unix() < moment(item.start_at).unix()">-->
<!--                    <StopOutlined /> Cancel Subscription-->
<!--                  </a-menu-item>-->
<!--                </a-menu>-->
<!--              </template>-->
<!--            </a-dropdown>-->
          </template>
          <a-descriptions :column="{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 24 }">
            <a-descriptions-item :span="24">
              <template #label>
                <span class="fw-bold">
                  {{ $t('subRefId') }}
                </span>
              </template>
              {{ item.order_reference_id }}
            </a-descriptions-item>
            <a-descriptions-item :span="24">
              <template #label>
                <span class="fw-bold">
                  {{ $t('startDate') }}
                </span>
              </template>
              {{ moment(item.start_at).format('DD MMMM Y h:mm A') }}
            </a-descriptions-item>
            <a-descriptions-item :span="24">
              <template #label>
                <span class="fw-bold">
                  {{ $t('validTil') }}
                </span>
              </template>
              {{ moment(item.expire_at).format('DD MMMM Y h:mm A') }}
            </a-descriptions-item>
            <a-descriptions-item :span="24">
              <template #label>
                <span class="fw-bold">
                  {{ $t('subscriptionType') }}
                </span>
              </template>
              {{ $t(item.type) }}
            </a-descriptions-item>
<!--            <a-descriptions-item :span="24">-->
<!--              <template #label>-->
<!--                <span class="fw-bold">-->
<!--                  Recurring-->
<!--                </span>-->
<!--              </template>-->
<!--              {{ item.is_recurring ? "YES" : "NO" }}-->
<!--            </a-descriptions-item>-->
          </a-descriptions>
        </a-card>
      </a-col>
    </a-row>
    <SubscriptionModalComponent />
  </div>
</template>

<script setup>
import {useQuery} from "@vue/apollo-composable";
import GetUserSubscriptionsGql from "@/gql/get-user-subscriptions-gql";
import {
  // h,
  inject,
  ref
} from "vue";
import moment from "moment";
// import {MoreOutlined, StopOutlined} from "@ant-design/icons-vue";
import SubscriptionModalComponent from "@/components/SubscriptionModalComponent.vue";

const userSubscriptions = ref([]);
const emitter = inject('emitter');

const {result, loading, onResult} = useQuery(GetUserSubscriptionsGql, {}, {
  clientId: 'subscriptions'
})

onResult(() => {
  userSubscriptions.value = result.value.user_subscriptions
})

const openSubscriptionModal = () => {
  emitter.emit('openSubscriptionModal')
}
</script>

<style scoped>

</style>
