<template>
  <a-configprovider :direction="'ltr'">
    <a-layout class="min-vh-100">
      <a-layout-header class="bg-white p-0">
        <NavbarComponent />
      </a-layout-header>
      <a-layout-content>
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </a-layout-content>
    </a-layout>
  </a-configprovider>
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent";
import {useRoute, useRouter} from 'vue-router'
import {useStore} from "vuex";
import {onBeforeMount} from 'vue'
export default {
  name: "master-template",
  components: {
    NavbarComponent
  },
  setup() {
    const store = useStore()
    const router = useRouter();
    const route = useRoute();
    onBeforeMount(() => {
      if (route.name == 'platform' && store.state.authedUser.user.profile.profile_type == 'user') {
        router.push({name: 'find-work'})
      } else if (route.name == 'platform' && store.state.authedUser.user.profile.profile_type == 'medical_center') {
        router.push({name: 'client-dashboard'})
      }
    })
  }
}
</script>

<style scoped>

</style>
