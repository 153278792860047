import gql from "graphql-tag";

export const GetJobsGql = gql`
    query jobs($queryType:String!, $limit:Int!, $page:Int!, $title:String, $location: String, $specialty_id: Int){
        jobs(queryType:$queryType, limit:$limit, page:$page, title:$title, location: $location, specialty_id: $specialty_id){
            data{
                id
                title
                overview
                location
                profile{
                    title
                    location
                    image
                }
                created_at
                is_applied
            }
            last_page
        }
    }
`