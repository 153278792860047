import gql from "graphql-tag";

export const ViewJobGql = gql`
    query job($id:Int!){
        job(id:$id){
            id
            title
            overview
            location
            is_active
            created_at
            profile{
                id
                title
                location
                image
                profile_slug
                user {
                    id
                }
            }
            specialty{
                id
                name
            }
            is_applied
        }
    }
`