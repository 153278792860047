import gql from "graphql-tag";


const GetSubscriptionsGql = gql`
    query {
      subscriptions{
        id
        subscription_type
        subscription_amount
        subscription_user_type
      }
    }
`

export default GetSubscriptionsGql;
