<template>
<div>
  <a-modal v-model:visible="visible" :title="$t('languages')" okText="Save" @cancel="handleClose" :destroyOnClose="true">
    <a-input-group compact v-for="(item, index) in languages" :key="index" class="w-100 mb-2">
<!--      <a-select required v-model:value="item.title" class="w-45" show-search :options="store.state.languages.filter(x => {-->
<!--        return {-->
<!--          label: `${x.name} (${x.native})`,-->
<!--          value: x.name-->
<!--        }-->
<!--      })" />-->
      <a-select required v-model:value="item.title" class="w-45" show-search :options="store.state.languages
          .map((x, index) => ({
            label: `${x.name} (${x.native}) `,
            value: x.name,
            disabled: languages.some(y => y.title === x.name)
          }))" />
      <a-select v-model:value="item.value" placeholder="Select Level" class="w-45" show-search required>
        <a-select-option value="Basic">{{$t('basic')}}</a-select-option>
        <a-select-option value="Conversational">{{$t('conversational')}}</a-select-option>
        <a-select-option value="Proficient">{{$t('proficient')}}</a-select-option>
        <a-select-option value="Fluent">{{$t('fluent')}}</a-select-option>
      </a-select>
      <div class="w-10">
        <a-tooltip title="Remove">
          <a-button class="rounded-0 rounded-end" @click="removeLang(index)" danger :disabled="languages.length == 1">
            <template #icon><DeleteOutlined /></template>
          </a-button>
        </a-tooltip>
      </div>
    </a-input-group>
    <template #footer>
      <a-button key="add" @click="addLang">
        {{$t('addNewLanguage')}}
      </a-button>
      <a-button key="back" @click="visible = false">{{ $t('close') }}</a-button>
      <a-button key="submit" type="primary" @click="saveData" :loading="confirmLoading">{{ $t('save') }}</a-button>
    </template>
  </a-modal>
</div>
</template>

<script>
import {inject, ref} from 'vue'
import {useStore} from "vuex";
import {
  DeleteOutlined,
} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {useMutation} from "@vue/apollo-composable";
import {UpdateProfileMutation} from "@/gql/update-profile-mutation";
import {useI18n} from "vue-i18n";

export default {
  name: "LanguagesModalComponent",
  components: {
    DeleteOutlined,
    // PlusCircleOutlined
  },
  setup() {
    const store = useStore();
    const emitter = inject('emitter')
    const visible = ref(false);
    const profileID = ref(null);
    const languages = ref([
      {
        title: null,
        value: null
      }
    ]);
    const confirmLoading = ref(false);
    const validateData = () => {
      let isValid = true
      languages.value.map(x => {
        if (!x.title || !x.value) {
          if (isValid) {
            isValid = false
          }
        }
      })
      return isValid
    };
    const {mutate} = useMutation(UpdateProfileMutation)
    const {t} = useI18n()
    const saveData = () => {
      if (validateData()) {
        confirmLoading.value = true;
        mutate({
          languages: languages.value
        }).then(() => {
          message.success(t('langUpdated'))
          emitUpdateProfile()
        })
      } else {
        message.warning(t('languagesRequired'))
      }
    }
    const removeLang = (index) => {
      languages.value.splice(index, 1);
    }
    const addLang = () => {
      languages.value.push({
        title: null,
        value: null
      });
    }

    const emitUpdateProfile = () => {
      emitter.emit('refetchProfileData')
    }

    // const {mutate: saveReview} = useMutation(CreateReviewMutationGql);
    emitter.on('openLanguagesModal', (data) => {
      visible.value = true;
      profileID.value = data.profile_id
      if (data.languages) {
        languages.value = JSON.parse(JSON.stringify(data.languages))
      }
    });
    const handleClose = () => {
      console.log('canceled')
      languages.value = [
        {
          title: null,
          value: null
        }
      ]
    }

    const filteredLanguages = () => {
      const langStore = store.state.languages;
      const langObjects = languages.value; // Assuming `this.languages` is the array you want to compare against

      return langStore
          .filter(x => !langObjects.some(y => y.name === x.name))
          .map(x => ({
            label: `${x.name} (${x.native})`,
            value: x.name
          }));
    }
    return {
      visible,
      languages,
      saveData,
      store,
      removeLang,
      addLang,
      confirmLoading,
      handleClose,
      filteredLanguages
    }
  }
}
</script>

<style scoped>

</style>
