<template>
  <div>
    <navbar-component/>
<!--    Home page-->
    <div class="section bg-light pt-5 pb-5">
      <div class="container">
        <a-row class="bg-light">
          <a-col span="10" class="mt-auto mb-auto">
<!--            <h2>{{ $t('homepage.bestSolution') }}</h2>-->
            <h2>{{ $t('weHlp') }}</h2>
            <p>
              {{$t('weHlpParagraph')}}
            </p>
            <a-button type="primary" @click="() => $router.push({name: 'login'})">{{$t('getStarted')}}</a-button>
          </a-col>
          <a-col span="14">
            <img class="w-100" src="@/assets/images/undraw_doctors.svg" alt="doctor-in">
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="container mt-5 pt-5">
      <div class="text-center">
        <iframe v-if="locale == 'ar'" style="width: 100%; height: 70vh" src="https://www.youtube.com/embed/UwhXFZT0JtE?si=4MVWFlGH699L3Ecx" title="DoctorsGulf introduction" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <iframe v-else style="width: 100%; height: 70vh" src="https://www.youtube.com/embed/JTxdn4KLSCA?si=5u4Jzw3Yeg4o7_j3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

<!--        <video style="width: 100%" controls v-if="locale == 'en'">-->
<!--          <source :src="`${baseURL}videos/en-vo.mp4`" type="video/mp4">-->
<!--        </video>-->
<!--        <video style="width: 100%" controls v-else>-->
<!--          <source :src="`${baseURL}videos/ar-vo.mp4`" type="video/mp4">-->
<!--        </video>-->
      </div>
    </div>
    <div class="container mt-5 pt-5">
      <div class="text-center">
        <h2>
          {{ $t('weOffer') }} <br>
          {{ $t('rangeFeature') }}
        </h2>
      </div>
      <a-row :gutter="[10, 30]" class="mt-5">
        <a-col span="8" align="center" v-for="(item, index) of features" :key="index">
          <a-row :gutter="[10, 10]">
            <a-col span="24">
              <img :src="item.image.url" :width="item.image.width" :height="item.image.height">
            </a-col>
            <a-col span="24">
              <h4>
                {{ item.title }}
              </h4>
            </a-col>
            <a-col span="24">
              <small>
                {{item.content}}
              </small>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
<!--    <div class="container mt-5 pt-5">-->
<!--      <a-row class="g-5">-->
<!--        <a-col>-->
<!--          <img class="w-100" src="@/assets/images/undraw_professor.svg" alt="features">-->
<!--        </a-col>-->
<!--        <a-col>-->
<!--          <a-row class="g-5">-->
<!--            <a-col :span="12" v-for="(item, index) of other_features" :key="index">-->
<!--              <img :src="item.image.url" alt="">-->
<!--              <p>{{item.content}}</p>-->
<!--            </a-col>-->
<!--          </a-row>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </div>-->
    <div class="bg-light pt-5 pb-5 mt-5">
      <div class="container">
        <a-row class=" g-3 mt-5 mb-5">
          <a-col span="24" align="center">
            <h2>{{ $t('readyToGetStarted') }}</h2>
          </a-col>
          <a-col span="24" align="center">
            <p>
              {{ $t('takeControl') }}
            </p>
          </a-col>
          <a-col span="24" align="center">
            <router-link :to="{name: 'login'}">
              <a-button type="primary">{{ $t('getStarted') }}</a-button>
            </router-link>
          </a-col>
        </a-row>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script setup>
import NavbarComponent from "@/views/Navbar";
import FooterComponent from "@/views/Footer";
import {useI18n} from "vue-i18n";
import {ref, watch} from "vue"

const { t, locale } = useI18n();
// const baseURL = process.env.VUE_APP_BACKEND_URL
const features = ref([
  {
    image: {
      // url: require("@/assets/icons/shopping_icon.svg"),
      url: require("@/assets/icons/search.svg"),
      width: "40",
      height: "40"
    },
    title: t('jobSearch'),
    content: t('jobSearchParagraph')
  },
  {
    image: {
      url: require("@/assets/icons/profile.svg"),
      width: "40",
      height: "40"
    },
    title: t('profileCreation'),
    content: t('profileCreationParagraph')
  },
  {
    image: {
      url: require("@/assets/icons/profile_creation.svg"),
      width: "40",
      height: "40"
    },
    title: t('ApplicationAndResumeSubmission'),
    content: t('ApplicationAndResumeSubmissionParagraph')
  },
  {
    image: {
      url: require("@/assets/icons/jobs-alert.svg"),
      width: "40",
      height: "40"
    },
    title: t('jobAlerts'),
    content: t('jobAlertsParagraph')
  },
  {
    image: {
      url: require("@/assets/icons/rating.svg"),
      width: "40",
      height: "40"
    },
    title: t('ratingAndReview'),
    content: t('ratingAndReviewParagraph')
  },
  {
    image: {
      url: require("@/assets/icons/share.svg"),
      width: "40",
      height: "40"
    },
    title: t('proNetworking'),
    content: t('proNetworkingParagraph')
  },
]);

watch(locale, () => {
  features.value = [
    {
      image: {
        // url: require("@/assets/icons/shopping_icon.svg"),
        url: require("@/assets/icons/search.svg"),
        width: "40",
        height: "40"
      },
      title: t('jobSearch'),
      content: t('jobSearchParagraph')
    },
    {
      image: {
        url: require("@/assets/icons/profile.svg"),
        width: "40",
        height: "40"
      },
      title: t('profileCreation'),
      content: t('profileCreationParagraph')
    },
    {
      image: {
        url: require("@/assets/icons/profile_creation.svg"),
        width: "40",
        height: "40"
      },
      title: t('ApplicationAndResumeSubmission'),
      content: t('ApplicationAndResumeSubmissionParagraph')
    },
    {
      image: {
        url: require("@/assets/icons/jobs-alert.svg"),
        width: "40",
        height: "40"
      },
      title: t('jobAlerts'),
      content: t('jobAlertsParagraph')
    },
    {
      image: {
        url: require("@/assets/icons/rating.svg"),
        width: "40",
        height: "40"
      },
      title: t('ratingAndReview'),
      content: t('ratingAndReviewParagraph')
    },
    {
      image: {
        url: require("@/assets/icons/share.svg"),
        width: "40",
        height: "40"
      },
      title: t('proNetworking'),
      content: t('proNetworkingParagraph')
    },
  ]
})


// const other_features = [
//   {
//     image: {
//       url: require("@/assets/icons/briefcase.svg"),
//       width: "40",
//       height: "40"
//     },
//     content: "Eos tota dicunt democritum no. Has natum gubergren ne. Est viris soleat sadipscing cu. Legere epicuri insolens eu nec, dicit virtute urbanitas id nam, qui in habeo semper eligendi."
//   },
//   {
//     image: {
//       url: require("@/assets/icons/key.svg"),
//       width: "40",
//       height: "40"
//     },
//     content: "Eos tota dicunt democritum no. Has natum gubergren ne. Est viris soleat sadipscing cu. Legere epicuri insolens eu nec, dicit virtute urbanitas id nam, qui in habeo semper eligendi."
//   },
//   {
//     image: {
//       url: require("@/assets/icons/box.svg"),
//       width: "40",
//       height: "40"
//     },
//     content: "Eos tota dicunt democritum no. Has natum gubergren ne. Est viris soleat sadipscing cu. Legere epicuri insolens eu nec, dicit virtute urbanitas id nam, qui in habeo semper eligendi."
//   },
//   {
//     image: {
//       url: require("@/assets/icons/credit card.svg"),
//       width: "40",
//       height: "40"
//     },
//     content: "Eos tota dicunt democritum no. Has natum gubergren ne. Est viris soleat sadipscing cu. Legere epicuri insolens eu nec, dicit virtute urbanitas id nam, qui in habeo semper eligendi."
//   },
//
// ]
</script>

<style scoped>
/*@media (min-width: 1200px) {*/
/*  .container-xl, .container-lg, .container-md, .container-sm, .container {*/
/*    max-width: 1000px;*/
/*  }*/
/*}*/
</style>
