<template>
  <a-row class="vh-100">
    <a-col :xs="{ span: 0 }" :sm="{ span: 0 }" :lg="{ span: 17 }" class="bg-light">
      <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <img src="@/assets/images/undraw_login_re_4vu2.svg" style="width: 60%;" alt="Login">
      </div>
    </a-col>
    <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :lg="{ span: 7 }" class="borderd-left my-auto">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <div class="text-center mb-5">
            <img src="@/assets/images/dr.png" width="200" alt="doctor-in">
            <h4 class="mt-2">{{ $t('loginToAccount') }}</h4>
          </div>
        </div>
      </div>
      <div class="p-4 pt-2">
        <a-form layout="vertical" :model="data.login" @finish="submitForm" autocomplete="off" @validate="handleValidate">
          <a-form-item :label="$t('email')" name="email" :rules="[{ type: 'email', message: $t('emailIsNotValid')}, { required: true }]">
            <a-input v-model:value="data.login.email" />
          </a-form-item>
          <a-form-item :label="$t('password')" name="password" :rules="[{ required: true, min: 8 }]">
            <a-input-password v-model:value="data.login.password" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" block class="fw-bold" html-type="submit" :loading="loading">{{ $t('signIn') }}</a-button>
          </a-form-item>
        </a-form>
        <div>
          <a-divider class="text-muted"><small>{{ $t('or') }}</small></a-divider>
        </div>
        <a-space direction="vertical" class="w-100">
          <a-button type="primary" ghost block class="fw-bold" @click="$router.push({name: 'signup_profile_type'})">
            {{ $t('joinUs') }}</a-button>
          <a-button type="primary" ghost block class="fw-bold" @click="$router.push({name: 'forgot_password'})">
            {{ $t('forgotPassword') }}</a-button>
        </a-space>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {LoginGql} from "@/gql/login-gql";
import {inject, ref} from "vue";
import {useMutation} from "@vue/apollo-composable";
import {useStore} from "vuex";
import {useRouter} from "vue-router"
import {message} from "ant-design-vue";
import { useI18n } from "vue-i18n";

export default {
  name: "login-page",
  setup() {
    const router = useRouter()
    const store = useStore()
    let errors = ref()
    const {t} = useI18n()
    const HandelGraphqlErrors = inject('HandelGraphqlErrors')
    const handleValidate = (_rule, value) => {
      console.log(_rule, value)
    }
    const data = ref({
      login: {
        email: '',
        password: '',
      },
      unauthorized: false,
      joinSection: false,
      loginSection: true,
    })
    const {mutate: loginMutation, onDone, loading} = useMutation(LoginGql, {
      clientId: 'auth'
    })
    onDone(res => {
      localStorage.setItem('authed_user', JSON.stringify(res.data.login))
      store.commit('setAuthedUser', res.data.login)
      let routeName = 'find-work';
      if (res.data.login.user.profile.profile_type == 'medical_center') {
        routeName = 'client-dashboard'
      }
      router.push({
        name: routeName
      })
    })
    const submitForm = () => {
      loginMutation({
        email: data.value.login.email,
        password: data.value.login.password,
      }).catch(error => {
        message.error(t('emailOrPassNotMatched'))
        errors = HandelGraphqlErrors(error.graphQLErrors)
      })
    }
    return {
      submitForm,
      data,
      errors,
      loading,
      handleValidate
    }
  }
}
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
