<template>
  <a-row>
    <a-col :span="24" class="bg-white rounded-3 mb-4">
      <div class="bg-white rounded-3">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="fw-bold mt-2">
            {{ $t('certificates') }}
          </h5>
          <a-button type="link" class="cursor-pointer" @click="openCertificateModal">
            <img src="@/assets/icons/add.svg" width="28" alt="add-certificate">
          </a-button>
<!--          <a href="#" class="btn btn-link">-->

<!--          </a>-->
        </div>
        <div class="mt-4">
          <a-skeleton avatar :paragraph="{ rows: 4 }" v-if="loading" />
          <div v-else>
            <div v-if="result.certificates.data.length > 0">
              <CertificateComponent class="border-bottom" v-for="(item, index) of result.certificates.data" :certificate="item" :key="index" />
            </div>
            <div class="text-center text-muted" v-else>
              {{$t('provideCertificate')}}
            </div>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :span="24" v-if="!loading">
      <a-button type="primary" block class="fw-bold" html-type="submit" :disabled="result.certificates.data.length == 0" @click="sendRequest">
        {{ $t('sendRequest') }}</a-button>
    </a-col>
  </a-row>
</template>

<script>
import {inject} from "vue";
import {useMutation, useQuery} from "@vue/apollo-composable";
import {GetCertificateGql} from "@/gql/get-certificates-gql";
import {useStore} from "vuex";
import CertificateComponent from "@/components/CertificateComponent";
import {MoveToPendingGql} from "@/gql/move-to-pending-gql";
import {useRouter} from "vue-router";

export default {
  name: "SignupProfessionalCertificates",
  components: {CertificateComponent},
  setup() {
    const emitter = inject('emitter')
    const store = useStore()
    const authedUser = store.state.authedUser
    const router = useRouter()
    const openCertificateModal = () => {
      emitter.emit('openCertificateModal', '')
    }
    emitter.on('certificatedSaved', () => {
      refetch()
    })

    const {result, loading, refetch} = useQuery(GetCertificateGql, {
      limit: 1000,
      page:1,
      profile_id: authedUser.user.profile.id
    });
    const {mutate: moveToPending} = useMutation(MoveToPendingGql);
    const sendRequest = () => {
      moveToPending({}).then(() => {
        router.push({
          name: 'pending-approval'
        })
      })
    }
    return {
      openCertificateModal,
      result,
      loading,
      sendRequest
    }
  }
}
</script>

<style scoped>

</style>