//firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyDqmdSK7AP6InyvhIbsj3V0LeuyBWY1Z6Q",
    authDomain: "doctor-in-bfa4c.firebaseapp.com",
    projectId: "doctor-in-bfa4c",
    storageBucket: "doctor-in-bfa4c.appspot.com",
    messagingSenderId: "710824807512",
    appId: "1:710824807512:web:32ada914f14989d3fd4e2d",
    measurementId: "G-WE049BHWCC"
};
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app);
// isSupported().then(() => {
//     navigator.serviceWorker.register(`${process.env.VUE_APP_BASE_URL}firebase-messaging-sw.js`, {scope: "firebase-cloud-messaging-push-scope"})
//         .then((registration) => {
//             messaging.useServiceWorker(registration);
//             console.log('service worker registered')
//         }).catch(err => {
//         console.log('error', err)
//     })
// })

export default messaging;