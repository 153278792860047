<template>
<div>
  <a-form layout="vertical"
          v-model:model="profile"
          @finish="submitForm"
          name="basic"
          autocomplete="off">
    <a-form-item :label="$t('profileTitle')" name="title" :rules="[{required: true},{max: 255}, { whitespace: true }]">
      <a-input v-model:value="profile.title" />
    </a-form-item>
    <a-form-item :label="$t('location')" name="location" :rules="[{required: true},{max: 255}, { whitespace: true }]">
<!--      <a-input v-model:value="profile.location" />-->
      <a-select
          v-model:value="profile.location"
          style="width: 100%"
          show-search
          :options="store.state.countries.map(x => {
                    return {
                      label: x.name,
                      value: x.name
                    }
                  })"
      ></a-select>
    </a-form-item>
    <a-form-item :label="$t('specialty')"  name="specialty" :rules="[{required: true}]">
      <a-select
          show-search
          v-model:value="profile.specialty"
          :options="store.state.specialties.map(x => {
                    return {
                      label: x.name,
                      value: x.id
                    }
                  })"
          :filter-option="filterOption"
      ></a-select>
    </a-form-item>
    <a-form-item>
      <a-button type="primary" block class="fw-bold" html-type="submit">{{ $t('next') }} (2 of 3)</a-button>
    </a-form-item>
  </a-form>
</div>
</template>

<script>
import {useStore} from 'vuex'
import {useMutation} from "@vue/apollo-composable";
import {UpdateProfileMutation} from "@/gql/update-profile-mutation";
import {reactive} from "vue";
import {useRouter} from 'vue-router'

export default {
  name: "SignupProfessionalCompleteProfile",
  setup() {
    const store = useStore()
    const router = useRouter()
    const {mutate: updateProfileMutation, onDone} = useMutation(UpdateProfileMutation)
    const profile = reactive({
      title: null,
      specialty: null,
      location: null
    })
    const filterOption = (input, option) => {
      // console.log(input, option)
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    const submitForm = () => {
      updateProfileMutation({
        "title": profile.title,
        "location":profile.location,
        "bio":"",
        "registration_number":"",
        "image":"",
        "languages":"",
        "founded":"",
        "address":"",
        "phone":"",
        "website":"",
        "type": "registration",
        "specialties": [profile.specialty],
      })
    }

    onDone(() => {
      router.push({
        name: 'signup_profile_certificates'
      })
    })
    return {
      store,
      updateProfileMutation,
      submitForm,
      profile,
      filterOption
    }
  }
}
</script>

<style scoped>

</style>