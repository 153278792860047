<template>
  <div class="container pt-5">
    <a-row :gutter="20">
      <a-col :xs="{span: 24}" :lg="{span: 18}">
<!--        <HomeJobsView/>-->
        <ClientDashboardView />
      </a-col>
      <a-col :xs="{span: 0}" :lg="{span: 6}" :span="6">
        <HomeWhoAmIView/>
      </a-col>
    </a-row>
    <!-- View Job Drawer -->
<!--    <ViewJobDrawer />-->
    <!-- Job Modal -->
<!--    <JobModalComponent />-->
  </div>
</template>

<script>
import HomeWhoAmIView from "@/views/HomeViews/HomeWhoAmIView";
import ClientDashboardView from "@/views/ClientDashboardView";
// import JobModalComponent from "@/components/JobModalComponent";
// import ViewJobDrawer from "@/components/ViewJobDrawer";
export default {
  name: "ClientDashboardPage",
  components: {
    // ViewJobDrawer,
    // JobModalComponent,
    ClientDashboardView,
    HomeWhoAmIView
  }
}
</script>

<style scoped>

</style>