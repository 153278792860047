<template>
  <div class="mb-2">
    <div class="bg-light p-2">
      <div class="pt-3">
        <div v-if="loading">
          <a-skeleton avatar active :paragraph="{ rows: 4 }" class="border-bottom pb-3 pb-3 mb-3 mt-3" />
          <a-skeleton avatar active :paragraph="{ rows: 4 }" />
        </div>
        <div v-else>
          <div v-if="commentsList.length == 0">
            <a-empty
                class="mt-3"
            >
              <template #description>
              <span class="text-muted pt-3">
                {{ $t('noData') }}
              </span>
              </template>
            </a-empty>
          </div>
          <div v-else>
            <CommentComponent class="my-2" :comment="comment" :key="index" v-for="(comment, index) of commentsList" />
            <div class="text-center" v-if="!stopLoading">
              <a-button type="link" :loading="loadingPaginate" @click="LoadMore">{{ $t('loadMore') }}...</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>

      <a-anchor :offsetBottom="0" v-if="!loading">
        <div class="bg-white p-2">
          <a-space direction="vertical" class="w-100">
            <a-textarea :placeholder="`${$t('writeComment')}...`" :status="commentError ? 'error' : ''" :auto-size="{ minRows: 3, maxRows: 3 }" v-model:value="comment" :disabled="createCommentLoading" @change="validateComment" />
            <span v-if="commentError" class="text-danger">
              {{commentError}}
            </span>
            <a-button type="primary" block :disabled="commentError || !comment" size="large" @click="createComment" :loading="createCommentLoading">
              {{ $t('writeComment') }}
            </a-button>
          </a-space>
        </div>
      </a-anchor>

  </div>
</template>

<script>
import {useLazyQuery, useMutation, useQuery} from "@vue/apollo-composable";
import {GetCommentsGql} from "@/gql/get-comments-gql";
import {CreateCommentMutationGql} from "@/gql/create-comment-mutation-gql"
import CommentComponent from "@/components/CommentComponent.vue";
import {ref, inject} from 'vue'
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
export default {
  name: "comments-list-view",
  props: {
    post_id: {
      required: true,
      type: Number
    }
  },
  components: {CommentComponent},
  setup(props) {
    const {t} = useI18n()
    const comment = ref(null);
    const commentsList = ref([]);
    const page = ref(1);
    const stopLoading = ref(false);
    const commentLoading = ref(false);
    const emitter = inject('emitter');
    const commentError = ref('');
    const {result, loading, onResult, refetch} = useQuery(GetCommentsGql, {
      page: 1,
      limit: 2,
      post_id: props.post_id
    }, {
      fetchPolicy: 'network-only'
    });
    onResult(() => {
      commentsList.value = result.value.comments.data;
      if (result.value.comments.last_page == page.value) {
        stopLoading.value = true
      }
    })

    const {result: commentPaginate, load, loading: loadingPaginate, onResult: onPaginateResult, refetch: refetchCommentPagination} = useLazyQuery(GetCommentsGql, {
      page: page,
      limit: 2,
      post_id: props.post_id
    }, {
      fetchPolicy: 'no-cache'
    });

    onPaginateResult(() => {
      commentsList.value = [...commentsList.value, ...commentPaginate.value.comments.data]
      if (commentPaginate.value.comments.last_page == page.value) {
        stopLoading.value = true;
      }
    });

    const LoadMore = () => {
      page.value = page.value+1
      load()
    }

    const {mutate: createCommentMutation, loading: createCommentLoading} = useMutation(CreateCommentMutationGql)
    const createComment = () => {
      createCommentMutation({
        comment: comment.value,
        post_id: props.post_id
      }).then(res => {
        commentsList.value = [res.data.createComment, ...commentsList.value];
        // commentsList.value.unshift(res.data.createComment);
        comment.value = null;
        message.success(t('commentAdded'));
      })
    }

    emitter.on('updateCommentsData', () => {
      if (page.value == 1) {
        refetch()
      } else {
        refetchCommentPagination()
      }
    });

    const validateComment = () => {
      commentError.value = null;
      if (!comment.value) {
        commentError.value = null;
      }
      else if (!comment.value.replace(/\s/g, '').length) {
        commentError.value = t('commentNotValid');
      } else if (comment.value.length > 500) {
        commentError.value = t('commentMax')
      }
    }

    return {
      result,
      loading,
      comment,
      commentsList,
      loadingPaginate,
      stopLoading,
      LoadMore,
      commentLoading,
      createComment,
      createCommentLoading,
      validateComment,
      commentError
    }
  }
}
</script>

<style scoped>

</style>
