<template>
<div class="text-center text-muted">
  <strong>{{$t('thanksForReg')}}</strong>
  <br>
  {{ $t('currentlyReview') }}
  <br>
  {{ $t('thanks') }},
  <br>
  <strong>DoctorsGulf {{ $t('team') }}</strong>
</div>
</template>

<script>
import {useStore} from "vuex";

export default {
  name: "SignupPendingRequest",
  setup() {
    const store = useStore()
    store.state.authedUser = null
    localStorage.removeItem('authed_user')
  }
}
</script>

<style scoped>

</style>
