<template>
  <div class="container py-5">
    <a-row>
      <a-col :span="12" :offset="6">
        <a-card>
          <a-form
              :model="profile"
              name="basic"
              autocomplete="off"
              @finish="onFinish"
              v-if="!loading"
              layout="vertical"
          >
            <a-form-item
                :label="$t('title')"
                name="title"
                :rules="[{ required: true }, { whitespace: true }, {max: 255}]"
            >
              <a-input v-model:value="profile.title" />
            </a-form-item>

            <a-form-item
                :label="$t('overview')"
                name="bio"
                :rules="[{ required: true }, { whitespace: true }, {max: 255}]"
            >
              <a-textarea
                  v-model:value="profile.bio"
                  :auto-size="{ minRows: 5, maxRows: 5 }"
              />
            </a-form-item>

            <a-form-item
                :label="$t('specialities')"
                name="specialties"
                :rules="[{ required: true }]"
            >
              <a-select
                  v-model:value="profile.specialties"
                  mode="multiple"
                  style="width: 100%"
                  :options="store.state.specialties.map(x => {
                    return {
                      label: x.name,
                      value: x.id
                    }
                  })"
                  :filterOption="(inputValue, option) => {
                    return (option.label.toLowerCase().includes(inputValue.toLowerCase()) ? option : false)
                  }"
              ></a-select>
            </a-form-item>

            <a-form-item
                :label="$t('regNumber')"
                name="registration_number"
                :rules="[{ required: true},{ whitespace: true }, {max: 255}]"
                v-if="store.state.authedUser.user.profile.profile_type != 'user'"
            >
              <a-input v-model:value="profile.registration_number" />
            </a-form-item>

            <a-form-item
                :label="$t('foundYear')"
                name="founded"
                :rules="[{ required: true}, { whitespace: true }, {max: 255}]"
                v-if="store.state.authedUser.user.profile.profile_type != 'user'"
            >
              <a-input v-model:value="profile.founded" />
            </a-form-item>

            <a-form-item
                :label="$t('address')"
                name="address"
                :rules="[{ required: true }, { whitespace: true }, {max: 255}]"
            >
              <a-input v-model:value="profile.address" />
            </a-form-item>

            <a-form-item
                :label="$t('location')"
                name="location"
                :rules="[{ required: true }]"
            >
              <a-select
                  v-model:value="profile.location"
                  style="width: 100%"
                  show-search
                  :options="store.state.countries.map(x => {
                    return {
                      label: x.name,
                      value: x.name
                    }
                  })"
              ></a-select>
            </a-form-item>

            <a-form-item
                :label="$t('website')"
                name="website"
                :rules="[{whitespace: true}, {max: 255}]"
            >
              <a-input v-model:value="profile.website" />
            </a-form-item>

            <a-form-item
                :label="$t('phone')"
                name="phone"
                :rules="[{whitespace: true}, {max: 255}]"
            >
              <a-input v-model:value="profile.phone" />
            </a-form-item>

            <a-button type="primary" html-type="submit">{{ $t('save') }}</a-button>
          </a-form>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {ref} from 'vue'
import {useRouter} from "vue-router/dist/vue-router";
import {ViewProfileGql} from "@/gql/view-profile-gql";
import {useStore} from "vuex";
import {useMutation, useQuery} from "@vue/apollo-composable";
import {UpdateProfileMutation} from "@/gql/update-profile-mutation";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
export default {
  name: "EditProfilePage",
  setup() {
    let profile = ref({});
    const router = useRouter()
    const store = useStore()
    const ellipsis = ref(true)
    const selectedSpecialties = ref([])

    const {result, loading, onResult} = useQuery(ViewProfileGql, {
      slug: store.state.authedUser.user.profile.profile_slug
    },{
      fetchPolicy: 'no-cache',
    })
    onResult(() => {
      profile.value = JSON.parse(JSON.stringify(result.value.profile))
      profile.value.specialties = result.value.profile.specialties.map(x => {
        return x.id
      })
    })
    const {t} = useI18n()
    const {mutate: updateProfileMutation} = useMutation(UpdateProfileMutation)
    const onFinish = () => {
      profile.value.type = profile.value.profile_type
      let data = profile.value
      delete data['image']
      updateProfileMutation(data).then(() => {
        message.success(t('profileUpdated'))
        router.push({
          name: 'profile-page',
          params: {
            slug: profile.value.profile_slug
          }
        })
      })
    }
    return {
      ellipsis,
      router,
      profile,
      onFinish,
      loading,
      store,
      selectedSpecialties
    }
  }
}
</script>

<style scoped>

</style>
