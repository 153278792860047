<template>
  <div>
    <a-form
        :model="profile"
        name="basic"
        autocomplete="off"
        @finish="onFinish"
        layout="vertical"
    >
      <a-form-item
          :label="$t('title')"
          name="title"
          :rules="[{ required: true}, {'max': 255}, {whitespace: true}]"
      >
        <a-input v-model:value="profile.title" />
      </a-form-item>

      <a-form-item
          :label="$t('overview')"
          name="bio"
          :rules="[{ required: true}, {'max': 255}, { whitespace: true }]"
      >
        <a-textarea
            v-model:value="profile.bio"
            placeholder="Please input your overview"
            :auto-size="{ minRows: 5, maxRows: 5 }"
        />
      </a-form-item>

      <a-form-item
          :label="$t('specialities')"
          name="specialties"
          :rules="[{ required: true}]"
      >
        <a-select
            v-model:value="profile.specialties"
            mode="multiple"
            style="width: 100%"
            placeholder="Please select"
            :options="store.state.specialties.map(x => {
                    return {
                      label: x.name,
                      value: x.id
                    }
                  })"
        ></a-select>
      </a-form-item>

      <a-form-item
          :label="$t('regNumber')"
          name="registration_number"
          :rules="[{ required: true}, {'max': 255}, { whitespace: true }]"
      >
        <a-input v-model:value="profile.registration_number" />
      </a-form-item>

      <a-form-item
          :label="$t('foundYear')"
          name="founded"
          :rules="[{ required: true}, {'max': 255}, { whitespace: true }]"
      >
        <a-input v-model:value="profile.founded" />
      </a-form-item>

      <a-form-item
          :label="$t('address')"
          name="address"
          :rules="[{ required: true }, {'max': 255}, { whitespace: true }]"
      >
        <a-input v-model:value="profile.address" />
      </a-form-item>

      <a-form-item
          :label="$t('location')"
          name="location"
          :rules="[{ required: true }, { whitespace: true }]"
      >
        <a-select
            v-model:value="profile.location"
            style="width: 100%"
            :placeholder="$t('selectLocation')"
            show-search
            :options="store.state.countries.map(x => {
                    return {
                      label: x.name,
                      value: x.name
                    }
                  })"
        ></a-select>
      </a-form-item>

      <a-form-item
          :label="$t('website')"
          name="website"
          :rules="[{'max': 255}, { whitespace: true }]"
      >
        <a-input v-model:value="profile.website" />
      </a-form-item>

      <a-form-item
          :label="$t('phone')"
          name="phone"
          :rules="[{'max': 255}, { whitespace: true }]"
      >
        <a-input v-model:value="profile.phone" />
      </a-form-item>

      <a-button type="primary" html-type="submit" block :loading="loading">{{ $t('createAccount') }}</a-button>
    </a-form>
  </div>
</template>

<script>
import {ref} from "vue";
import {useStore} from "vuex";
import {useMutation} from "@vue/apollo-composable";
import {UpdateProfileMutation} from "@/gql/update-profile-mutation";
import {useRouter} from "vue-router";

export default {
  name: "SignupMedicalCompleteProfile",
  setup() {
    const router = useRouter()
    const store = useStore();
    let profile = ref({
      name: null,
      bio: null,
      specialties: [],
    });

    const {mutate, loading} = useMutation(UpdateProfileMutation)

    const onFinish = () => {
      mutate(profile.value).then(() => {
        router.push({
          name: 'pending-approval'
        })
      })
    }

    return {
      profile,
      onFinish,
      store,
      loading
    }
  }
}
</script>

<style scoped>

</style>