import gql from "graphql-tag";

export const ViewPostGql = gql`
    query poss($id:Int!){
        post(id:$id) {
            id
            post
            file {
                file_url
                file_type
            }
            user{
                id
                name
                email
                profile{
                    id
                    title
                    image
                }
            }
            is_liked
            privacy
            created_at
        }
    }
`