import gql from "graphql-tag";

export const GetCommentsGql = gql`
    query comments($page:Int, $limit:Int, $post_id:Int){
        comments(page:$page, limit:$limit, post_id:$post_id){
            data{
                id
                comment
                user {
                    id
                    name
                    email
                    profile{
                        id
                        title
                        image
                    }
                }
                created_at
            }
            last_page
        }
    }
`