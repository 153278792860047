import gql from "graphql-tag";

export const GetSpecialtiesGql = gql`
    query{
        specialties{
            id
            name
            name_ar
        }
    }
`