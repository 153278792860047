<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div class="container-fluid bg-white p-4 rounded-3">
          <a-row :justify="'space-between'">
            <a-col>
              <a-typography-title :level="3">{{ $t('myPostedJobs') }}</a-typography-title>
            </a-col>
            <a-col :lg="{span: 0}">
              <a-button type="link" @click.prevent="openJobModal">
                <template #icon>
                  <PlusCircleOutlined />
                </template>
              </a-button>
            </a-col>
          </a-row>
          <div class="pt-3">
            <HomeJobsListView :query-type="'recent'" />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import HomeJobsListView from "@/views/HomeViews/HomeJobsListView";
import {PlusCircleOutlined} from "@ant-design/icons-vue";
import {inject} from "vue";

export default {
  name: "ClientDashboardView",
  components: {
    HomeJobsListView,
    PlusCircleOutlined
  },
  setup() {
    const emitter = inject('emitter')
    const openJobModal = () => {
      emitter.emit('openJobModal', null)
    }
    return {
      openJobModal
    }
  }
}
</script>

<style scoped>

</style>