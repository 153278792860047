<template>
  <div class="container py-5">
    <a-row :gutter="20">
      <a-col :xs="{span: 24}" :lg="{span: 18}">
        <a-row :gutter="[20, 20]">
          <a-col :span="24">
            <a-card>
              <a-row :gutter="[10]" :align="'middle'">
                <a-col>
                  <a-avatar :size="45" :src="authedUser.user.profile.image" v-if="authedUser.user.profile.image" />
                </a-col>
                <a-col flex="auto">
                  <a-input
                      @click="openPostModal()"
                      size="large"
                      readonly
                      :placeholder="$t('whatIsInYourMind')"
                  />
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="24">
<!--            <a-card>-->
              <PostsList />
<!--            </a-card>-->
          </a-col>
        </a-row>
      </a-col>

      <a-col :xs="{span: 0}" :lg="{span: 6}" :span="6">
        <HomeWhoAmIView />
      </a-col>
    </a-row>
    <PostModalComponent />
  </div>
</template>

<script>
import HomeWhoAmIView from "@/views/HomeViews/HomeWhoAmIView";
import {useStore} from "vuex";
import PostsList from "@/views/PostsListView.vue";
import {inject} from "vue";
import PostModalComponent from "@/components/PostModalComponent.vue";
export default {
  name: "feeds-page",
  components: {
    PostModalComponent,
    PostsList,
    HomeWhoAmIView
  },
  setup() {
    const store = useStore();
    const emitter = inject('emitter')
    const authedUser = store.state.authedUser;
    const openPostModal = () => {
      emitter.emit('openPostModal');
    }
    return {
      authedUser,
      openPostModal
    }
  }
}
</script>

<style scoped>

</style>
