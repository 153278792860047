<template>
  <div class="d-grid gap-2">
    <a-button type="primary" class="fw-bold" size="large" block @click="$router.push({name: 'signup_profile_user'})">{{ $t('registerAsPro') }}</a-button>
    <a-button type="primary" class="fw-bold" size="large" ghost block @click="$router.push({name: 'signup_medical_center'})">{{ $t('registerAsMed') }}</a-button>
    <div>
      <a-divider class="text-muted"><small>OR</small></a-divider>
    </div>
    <a-button type="primary" class="fw-bold" size="large" ghost block @click="$router.push({name: 'login'})">{{ $t('backToLogin') }}</a-button>
  </div>
</template>

<script>
export default {
  name: "SignupProfileType"
}
</script>

<style scoped>

</style>